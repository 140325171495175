import { Container, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TransactionAction } from '../actions/transaction.action'
import TransactionHistoryTable from '../components/dashboard/transaction-history-table.component'
import { TransactionState } from '../reducers/transaction.reducer'
import store, { RootState } from '../store'

const TransactionHistoryScreen = (props: any) => {
    const transaction = useSelector<RootState, TransactionState>(
        (state) => state.transaction
    )
    const [searchTerm, setSearchTerm] = useState('')
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState('created_at')
    const isLoading = props.isLoading || transaction.loading

    useEffect(() => {
        if (transaction.data.list?.meta === null && !transaction.loading) {
            store.dispatch(TransactionAction.index())
        }
    })

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            store.dispatch(TransactionAction.index(page, searchTerm, sortBy))
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [page, searchTerm, sortBy])

    useEffect(() => {
        props.getScreenTitle('Payment history')
    }, []) // eslint-disable-line

    const onPageChange = (page: number) => {
        setPage(page)
    }

    const onSort = (value: string) => {
        setSortBy(value)
    }

    return (
        <Container className="container">
            <Grid item>
                <TransactionHistoryTable
                    list={transaction?.data?.list?.data ?? []}
                    onPageChange={onPageChange}
                    meta={transaction?.data?.list?.meta ?? { last_page: 0 }}
                    onSearch={setSearchTerm}
                    onSort={onSort}
                    isDesktop={props.isDesktop}
                    isLoading={isLoading}
                />
            </Grid>
        </Container>
    )
}

export default TransactionHistoryScreen
