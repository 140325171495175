import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { OrderAction } from '../../../actions/orders.action'
import store from '../../../store'

export default function DownloadIssue(props: any) {
    const downloadIssue = (issue: any) => {
        store.dispatch(OrderAction.downloadIssue(issue))
    }

    return (
        props.issues.length > 0 &&
        props.issues.map((issue: any, i: number) => (
            <p
                className="download issue"
                key={`di_1_${i}`}
                onClick={() => downloadIssue(issue)}
                {...props}
            >
                <DownloadForOfflineIcon /> {issue.original_name}
            </p>
        ))
    )
}
