import {
  SUPPORT_FAIL,
  SUPPORT_REQUEST,
  SUPPORT_RESET,
  SUPPORT_SUCCESS,
} from "../constants/support.constants";

type DataSupport = {
  message?: string | null;
};

export interface SupportState {
  loading?: boolean;
  showNotification: boolean;
  hasError?: boolean;
  data: DataSupport;
  support: any;
}

interface Action {
  type: string;
  payload?: any;
}

export const supportReducer = (
  state: SupportState = {
    loading: false,
    showNotification: false,
    hasError: false,
    support: null,
    data: {
      message: null,
    },
  },
  action: Action
) => {
  switch (action.type) {
    case SUPPORT_REQUEST:
      return {
        loading: true,
        showNotification: false,
        hasError: false,
        support: state.support,
        data: {
          message: null,
        },
      };
    case SUPPORT_SUCCESS:
      return {
        loading: false,
        showNotification: true,
        hasError: false,
        support: action.payload,
        data: {
          message: "Your Inquiry Successfully Sent.",
        },
      };
    case SUPPORT_FAIL:
      return {
        loading: false,
        showNotification: true,
        hasError: true,
        support: state.support,
        data: { ...state.data, message: action.payload?.message },
      };
    case SUPPORT_RESET:
      return {
        loading: false,
        data: state.data,
        showNotification: false,
        support: state.support,
        hasError: false,
      };
    default:
      return state;
  }
};
