import {
    INFO_FAIL,
    INFO_REQUEST,
    INFO_SUCCESS,
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT,
    PP_FAIL,
    PP_REQUEST,
    PP_SUCCESS,
    RESET,
} from '../constants/user.constants'
import { removeAuth, setAuth, setCurrentUser } from '../services/auth.service'

export interface UserState {
    loading?: boolean
    error?: string
    token?: string | null
    info?: any
    isAuth: boolean
    showNotification: boolean
    hasError: boolean
    photo?: any
}

interface Action {
    type: string
    payload?: any
}

export const userLoginReducer = (
    state: UserState = {
        token: null,
        info: null,
        isAuth: false,
        showNotification: false,
        loading: false,
        hasError: false,
        photo: null,
    },
    action: Action
) => {
    switch (action.type) {
        case LOGIN_REQUEST:
        case INFO_REQUEST:
            return { ...state, loading: true }
        case PP_REQUEST:
            return { ...state, loading: true }
        case INFO_SUCCESS:
            setCurrentUser(action.payload)
            return {
                ...state,
                loading: false,
                token: state.token,
                info: action.payload,
                isAuth: true,
                hasError: false,
            }
        case PP_SUCCESS:
            return { ...state, loading: false, photo: action.payload }
        case LOGIN_SUCCESS:
            setAuth(action.payload, true)
            return {
                loading: false,
                token: action.payload?.token ?? null,
                info: action.payload?.user ?? null,
                isAuth: true,
                hasError: false,
            }
        case INFO_FAIL:
            return {
                ...state,
                loading: false,
                error: 'Your session might be expired. You may login again.',
                showNotification: true,
                isAuth: false,
                hasError: true,
            }
        case PP_FAIL:
            return {
                ...state,
                loading: false,
            }
        case LOGIN_FAIL:
            return {
                loading: false,
                error: action.payload,
                showNotification: true,
                isAuth: false,
                hasError: true,
            }
        case LOGOUT:
            removeAuth()
            return { isAuth: false }
        case RESET:
            return {
                loading: false,
                showNotification: false,
            }
        default:
            return state
    }
}
