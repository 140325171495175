import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import store, { RootState } from '../store'
import Logo from '../assets/images/logo.svg'
import '../assets/css/login.css'
import { Box, Grid } from '@mui/material'
import Notification from '../components/notification.components'
import { RESET_RESET } from '../constants/user.constants'
import { IsDesktopScreen, IsSmallestScreen } from '../helpers/utils.helper'
import { reset } from '../actions/auth.action'
import ResetForm from '../components/reset-form.component'
import { ResetState } from '../reducers/reset.reducer'
import { TYPES_CONST } from '../env'

const ResetScreen = () => {
    const auth = useSelector<RootState, ResetState>((state) => state.reset)
    const isDesktop = IsDesktopScreen()
    const isSmallestScreen = IsSmallestScreen()

    const [credential, setCredential] = useState({
        email: '',
        password: '',
        password_confirmation: '',
        token: '',
    })

    const [errors, setErrors] = useState({
        email: false,
        password: false,
        password_confirmation: false,
    })

    const validate = () => {
        let payload = [
            {
                key: 'email',
                isValid: credential.email !== '',
            },
            {
                key: 'password',
                isValid:
                    credential.password !== '' &&
                    credential.password === credential.password_confirmation,
            },
            {
                key: 'password_confirmation',
                isValid:
                    credential.password_confirmation !== '' &&
                    credential.password === credential.password_confirmation,
            },
        ]

        return {
            data: payload,
            isValid: payload.every((data) => data.isValid),
        }
    }

    const onSubmit = async (event: SyntheticEvent) => {
        event.preventDefault()

        const validation = validate()

        if (!validation.isValid) {
            setErrors(
                validation.data.reduce(
                    (prevField, field) => {
                        return { ...prevField, [field.key]: !field.isValid }
                    },
                    {
                        email: false,
                        password: false,
                        password_confirmation: false,
                    }
                )
            )
            return
        }

        store.dispatch(
            reset(
                credential.email,
                credential.password,
                credential.password_confirmation,
                credential.token
            )
        )
    }

    const onChange =
        (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target
            setCredential((prevState) => ({
                ...prevState,
                [name]: value,
            }))
        }

    useEffect(
        function () {
            const queryParams = new URLSearchParams(window.location.search)
            const uriToken = queryParams.get('token') ?? ''
            const email = queryParams.get('email') ?? ''

            setCredential({
                ...credential,
                email: email,
                token: uriToken,
            })
        },
        [credential]
    )

    return (
        <Grid container className="login-body" sx={{ overflow: 'auto' }}>
            <Grid item md={7} sx={{ width: '100%' }}>
                <Grid container className="login-nav">
                    <Grid item md={6} xs={12} sm={12}>
                      <a href={TYPES_CONST.MARKETING_URL}><img src={Logo} alt="Upviews" /></a>
                    </Grid>
                    <Grid item md={6} className="log-action">
                        <p>
                            Don't have an account?{' '}
                            <a href="/register">Sign Up!</a>
                        </p>
                    </Grid>

                    <Grid
                        item
                        md={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                width: isDesktop ? '400px' : '100%',
                                margin: isSmallestScreen ? '5px' : 'unset',
                            }}
                            className="form-container"
                        >
                            <Box
                                mb={
                                    isSmallestScreen ? '60px!important' : '37px'
                                }
                                className="login-header"
                            >
                                <h4>Reset Password</h4>
                                <div className="sub-title">
                                    Please fill your new details to reset
                                    password.
                                </div>
                            </Box>
                            <ResetForm
                                className="login-form"
                                formProps={{ onSubmit: onSubmit }}
                                onChange={onChange}
                                errors={errors}
                                loading={auth.loading}
                                email={credential.email}
                            />
                        </Box>
                        <Box
                            sx={{ position: 'fixed', bottom: '33px' }}
                            className="login-action-bottom"
                        >
                            <p>
                                Don't have an account?{' '}
                                <a href="/register">Sign Up!</a>
                            </p>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={5} className="login-person">
                {/* Background here */}
            </Grid>
            <Notification
                message={auth.message}
                open={auth.showNotification}
                severity={auth.hasError ? 'error' : 'success'}
                onClose={() =>
                    store.dispatch({
                        type: RESET_RESET,
                    })
                }
            />
        </Grid>
    )
}

export default ResetScreen
