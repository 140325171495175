export const CUSTOMER_REQUEST = 'CUSTOMER_REQUEST'
export const CUSTOMER_GET_SUCCESS = 'CUSTOMER_GET_SUCCESS'
export const CUSTOMER_GET_FAIL = 'CUSTOMER_GET_FAIL'
export const CUSTOMER_BAN_IP_SUCCESS = 'CUSTOMER_BAN_IP_SUCCESS'
export const CUSTOMER_BAN_IP_FAIL = 'CUSTOMER_BAN_IP_FAIL'
export const CUSTOMER_RESET = 'CUSTOMER_RESET'
export const CUSTOMER_BAN_ACCOUNT_SUCCESS = 'CUSTOMER_BAN_ACCOUNT_SUCCESS'
export const CUSTOMER_BAN_ACCOUNT_FAIL = 'CUSTOMER_BAN_ACCOUNT_FAIL'
export const CUSTOMER_PAUSE_ACCOUNT_SUCCESS = 'CUSTOMER_PAUSE_ACCOUNT_SUCCESS'
export const CUSTOMER_PAUSE_ACCOUNT_FAIL = 'CUSTOMER_PAUSE_ACCOUNT_FAIL'
export const CUSTOMER_ACTIVATE_ACCOUNT_SUCCESS =
    'CUSTOMER_ACTIVATE_ACCOUNT_SUCCESS'
export const CUSTOMER_ACTIVATE_ACCOUNT_FAIL = 'CUSTOMER_ACTIVATE_ACCOUNT_FAIL'
