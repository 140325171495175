export default function YoutubeEmbed(props: any) {
  const getEmbedId = (youtubeUrl: string) => {
    // eslint-disable-next-line no-useless-escape
    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = youtubeUrl.match(regExp);

    return match && match[2].length === 11 ? match[2] : '';
  };

  return (
    <div className="video-container">
      <iframe
        src={`https://www.youtube.com/embed/${getEmbedId(props.embedUrl)}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      ></iframe>
    </div>
  );
}
