import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { TYPES_CONST } from "../env";

export const BlogService = {
  get: async () => {
    const config: AxiosRequestConfig = {
      baseURL: 'https://api.rss2json.com',
      timeout: 10000
    };

    const API_INSTANCE: AxiosInstance = axios.create(config);
    return API_INSTANCE.get('/v1/api.json?count=1&api_key=ne1nwrnutx4naciciqapavcphw9iltwlciy7ngnf&rss_url='+TYPES_CONST.BLOG_URL+'/feed/');
  },
}