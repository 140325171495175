import React from 'react';
import { Alert, AlertColor, Button, Slide, Snackbar } from '@mui/material';

type Props = {
  message: string | undefined;
  open: boolean;
  severity?: AlertColor;
  onClose: Function;
  withAction?: boolean;
};

export default function Notification(props: Props) {
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    event?.preventDefault();

    if (reason === 'clickaway') {
      return;
    }

    props.onClose(reason);
  };

 
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      autoHideDuration={4000}
      onClose={handleClose}
      open={props.open}
      TransitionComponent={Slide}
    >
      <Alert variant="filled" onClose={handleClose} severity={props.severity || 'success'}>
        {props.message}
        {props.withAction && 
          <Button sx={{color: '#6fbf73', textDecoration: 'underline'}} size="small" onClick={(e) => handleClose(e, 'proceed')}> Proceed </Button>
        }
      </Alert>
    </Snackbar>
  );
}
