import React from 'react'
import { TYPES_CONST } from '../../env'

const CustomTermsOfUseLinkComponent = () => {
    return (
        <a
            href={
                TYPES_CONST.MARKETING_URL +
                'terms-and-condition'
            }
            rel="noreferrer"
            target="_blank"
            style={{
                color: '#27A857',
                fontWeight: 'bold',
                textDecoration: 'underline',
            }}
        >
            Terms of Use
        </a>
    )
}

export default CustomTermsOfUseLinkComponent