import { STATISTICS_FAIL, STATISTICS_REQUEST, STATISTICS_SUCCESS } from '../constants/account.constants';

type StatisticData = {
  message?: string | null;
  data: null;
};

export interface StatisticsState {
  loading?: boolean;
  payload?: StatisticData;
  hasError: boolean;
}

interface Action {
  type: string;
  payload?: any;
}

export const statisticsReducer = (
  state: StatisticsState = {
    loading: false,
    payload: {
      data: null,
    },
    hasError: false,
  },
  action: Action
) => {
  switch (action.type) {
    case STATISTICS_REQUEST:
      return {
        loading: true,
        hasError: false,
      };
    case STATISTICS_SUCCESS:
      return {
        loading: false,
        payload: action.payload,
        hasError: false,
      };
    case STATISTICS_FAIL:
      return {
        loading: false,
        payload: action.payload,
        hasError: true,
      };
    default:
      return state;
  }
};
