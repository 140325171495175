import {
    WALLET_FAIL,
    WALLET_REQUEST,
    WALLET_SUCCESS,
} from '../constants/account.constants'

export interface WalletState {
    loading?: boolean
    message?: string | null
    balance: number | null
    spending: number
    hasError: boolean
}

interface Action {
    type: string
    payload?: any
}

export const walletReducer = (
    state: WalletState = {
        loading: false,
        message: null,
        balance: null,
        spending: 0.0,
        hasError: false,
    },
    action: Action
) => {
    switch (action.type) {
        case WALLET_REQUEST:
            return {
                ...state,
                loading: true,
                hasError: false,
            }
        case WALLET_SUCCESS:
            return {
                loading: false,
                hasError: false,
                ...action.payload,
            }
        case WALLET_FAIL:
            return {
                loading: false,
                message: action.payload,
                hasError: true,
            }
        default:
            return state
    }
}
