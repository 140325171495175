import { useState } from 'react'
import {
    Autocomplete,
    Button,
    Chip,
    FormControl,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import uniqBy from 'lodash/uniqBy'
import CloseIcon from '@mui/icons-material/Close'
import ReplayIcon from '@mui/icons-material/Replay'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'

import { IsSmallestScreen } from '../../helpers/utils.helper'
import { Box } from '@mui/system'

export default function QuickFilters(props: any) {
    const isSmallestScreen = IsSmallestScreen()
    const {
        options: quickFilterOptions = [],
        isDesktop,
        statusColors = {},
        onSearch,
        onSortChange,
        sortBy,
        setStatus,
        hideDownload = false,
    } = props

    const [filteredStatus, setFilteredStatus] = useState([])

    const onStatusChange = (value: any) => {
        setFilteredStatus(value)
        setStatus(value)
    }

    const quickFilters = (
        <Box
            width={'100%'}
            mr={isSmallestScreen ? 'unset' : '15px'}
            mt={isDesktop ? 'unset' : '20px'}
            sx={{
                gap: '12px',
                display: isSmallestScreen ? 'block' : 'flex',
                alignItems: 'center',
                '.reset-btn': {
                    bgcolor: '#FF3E3E',
                    borderRadius: '50%',
                    height: '42px',
                    width: '42px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#fff',
                    cursor: 'pointer',
                },
                'li.selected': {
                    bgcolor: 'red',
                },
            }}
        >
            Quick Filters
            <Autocomplete
                multiple
                options={quickFilterOptions}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label=""
                        placeholder="Status"
                        InputLabelProps={{ shrink: true }}
                    />
                )}
                value={filteredStatus}
                onChange={(event: React.ChangeEvent<{}>, value: any) => {
                    onStatusChange(uniqBy(value, 'value'))
                }}
                placeholder="Status"
                renderTags={(value, getTagProps) =>
                    value.map((option: any, index) => {
                        const { dark, color } = statusColors[option.value] ?? {}

                        return (
                            <Chip
                                label={option.label}
                                {...getTagProps({ index })}
                                onDelete={() =>
                                    onStatusChange(
                                        filteredStatus.filter(
                                            (e: any) => e.value !== option.value
                                        )
                                    )
                                }
                                sx={{
                                    bgcolor: color,
                                }}
                                deleteIcon={
                                    <CloseIcon
                                        className="no-overrides"
                                        sx={{
                                            bgcolor: dark,
                                        }}
                                    />
                                }
                            />
                        )
                    })
                }
                clearIcon={null}
                className="common-styled-select"
                sx={{
                    minWidth: isDesktop ? '300px' : '100%',
                    mt: isDesktop ? 'uhset' : '10px',
                    mb: isDesktop ? 'uhset' : '15px',
                    '.MuiOutlinedInput-root': {
                        borderRadius: '8px!important',
                        padding: '2px 3px',
                        '&.MuiAutocomplete-input': {
                            padding: '7px 4px 7px 6px',
                        },
                        '.MuiChip-root': {
                            borderRadius: '8px',
                            color: '#fff',
                            height: '30px',
                            '.MuiChip-label': {},
                            'svg.MuiChip-deleteIcon': {
                                color: '#fff!important',
                                height: '100%',
                                borderTopRightRadius: '7px',
                                borderBottomRightRadius: '7px',
                                padding: '0px 5px',
                                margin: 'unset',
                            },
                        },
                        'button.MuiButtonBase-root': {
                            bgcolor: 'unset',
                        },
                    },
                }}
            />
            <div className={isDesktop ? 'reset-btn' : ''}>
                {isDesktop ? (
                    <ReplayIcon
                        onClick={(e) => {
                            e.preventDefault()
                            onStatusChange([])
                        }}
                    />
                ) : (
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{
                            textTransform: 'capitalize',
                            bgcolor: '#FF3E3E!important',
                        }}
                        onClick={(e) => {
                            e.preventDefault()
                            onStatusChange([])
                        }}
                    >
                        Reset Filter <ReplayIcon />
                    </Button>
                )}
            </div>
        </Box>
    )
    return (
        <>
            {isDesktop && quickFilters}
            <Box
                sx={{
                    display: isSmallestScreen ? 'block' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    minWidth: { md: '30%' },
                    '.order-sort-download': {
                        width: '100%',
                        display: 'flex',
                        alignItems: {
                            xs: 'unset',
                            sm: 'center',
                        },
                    },
                }}
            >
                <FormControl
                    className="upview-form-control"
                    sx={{
                        width: '100%',
                        mr: '20px',
                    }}
                >
                    <TextField
                        placeholder="Search for..."
                        size="small"
                        id="outlined-basic"
                        variant="outlined"
                        onChange={(e) => {
                            onSearch(e.target.value)
                        }}
                    />
                </FormControl>

                <div className="order-sort-download">
                    <FormControl
                        className="upview-form-control common-styled-select"
                        hiddenLabel
                        size="small"
                        sx={{
                            width: '100%',
                            mt: {
                                xs: '20px',
                                sm: '0px',
                            },
                        }}
                    >
                        <Select
                            IconComponent={KeyboardArrowDown}
                            inputProps={{
                                'aria-label': 'Without label',
                            }}
                            displayEmpty
                            value={sortBy}
                            onChange={(e) => onSortChange(e.target.value)}
                        >
                            <MenuItem value="">Sort By</MenuItem>
                            <MenuItem value="created_at">Date Added</MenuItem>
                            <MenuItem value="status">Status</MenuItem>
                        </Select>
                    </FormControl>
                    {!hideDownload && (
                        <DownloadForOfflineIcon
                            sx={{
                                color: '#27A857',
                                ml: '5px',
                                fontSize: '47px!important',
                                borderRadius: '30px',
                                padding: '5px',
                                mt: {
                                    xs: '10px',
                                    sm: '0px',
                                },
                            }}
                        />
                    )}
                </div>
            </Box>
            {!isDesktop && quickFilters}
        </>
    )
}
