import { Box } from '@mui/system'
import { IsSmallestScreen } from '../../helpers/utils.helper'
import CustomButton from '../customComponents/custom-button.component'
import CustomLabeledFormControl from '../customComponents/custom-labeled-form-control'

export default function UserProfile(props: any) {
    const isSmallestScreen = IsSmallestScreen()
    const { isEdit, setIsEdit } = props

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={props.handleSubmit}
            sx={{
                width: props.isDesktop ? '100%' : 'unset',
                mt: !props.isDesktop ? '-25px' : 'unset',
                backgroundColor: '#FFF',
                borderRadius: '40px',
            }}
        >
            <Box
                sx={{
                    padding: '10px 40px',
                    borderBottom: '1px solid #c4c4c4!important',
                }}
            >
                <h2>Personal Information</h2>
                <CustomLabeledFormControl
                    label="Full Name"
                    customChildren={props.valueFormatter(
                        props.personalInfo.name,
                        'name',
                        'Full Name',
                        props.errors.name,
                        isEdit
                    )}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        '& > div': {
                            width: props.isDesktop ? '49%' : '100%',
                        },
                    }}
                >
                    <CustomLabeledFormControl
                        label="Email Address"
                        customChildren={props.valueFormatter(
                            props.personalInfo.email,
                            'email',
                            'Email Address',
                            props.errors.email,
                            isEdit
                        )}
                    />
                    <CustomLabeledFormControl
                        label="Phone Number"
                        customChildren={props.valueFormatter(
                            props.personalInfo.phone_number,
                            'phone_number',
                            'Phone Number',
                            false,
                            isEdit
                        )}
                    />
                </Box>

                <CustomLabeledFormControl
                    label="Address"
                    customChildren={props.valueFormatter(
                        props.personalInfo.address,
                        'address',
                        'Address',
                        false,
                        isEdit
                    )}
                />
            </Box>
            <Box
                sx={{
                    padding: '20px',
                    textAlign: 'right',
                }}
            >
                {isEdit && props.isDesktop && (
                    <CustomButton
                        label="Cancel"
                        className="upviews-button"
                        sx={{
                            padding: '8px 55px!important',
                            width: isSmallestScreen ? '100%' : 'unset',
                            bgcolor: 'unset!important',
                            color: '#27A857!important',
                        }}
                        onClick={(e: any) => {
                            setIsEdit(false)
                            e.stopPropagation()
                            e.preventDefault()
                        }}
                    />
                )}
                {isEdit ? (
                    <CustomButton
                        isLoading={false}
                        label={'Confirm Changes'}
                        type="submit"
                        sx={{
                            padding: '8px 55px!important',
                            width: isSmallestScreen ? '100%' : 'unset',
                        }}
                        className="upviews-button"
                    />
                ) : (
                    <CustomButton
                        isLoading={false}
                        label={'Update Details'}
                        sx={{
                            padding: '8px 55px!important',
                            width: isSmallestScreen ? '100%' : 'unset',
                        }}
                        onClick={(e: any) => {
                            setIsEdit(true)
                            e.stopPropagation()
                            e.preventDefault()
                        }}
                        className="upviews-button"
                    />
                )}

                {isEdit && !props.isDesktop && (
                    <CustomButton
                        label="Cancel"
                        className="upviews-button"
                        sx={{
                            padding: '8px 55px!important',
                            width: isSmallestScreen ? '100%' : 'unset',
                            bgcolor: 'unset!important',
                            color: '#27A857!important',
                            mt: '10px',
                        }}
                        onClick={(e: any) => {
                            setIsEdit(false)
                            e.stopPropagation()
                            e.preventDefault()
                        }}
                    />
                )}
            </Box>
        </Box>
    )
}
