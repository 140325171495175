import { Box } from '@mui/system'
import { useState } from 'react'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import DownloadIcon from '@mui/icons-material/Download'
import { formatDate } from '../../helpers/utils.helper'
import {
    PAYMENT_STATUS,
    PAYMENT_TYPE,
    TRANSACTION_TYPE,
} from '../../types/transaction_options.types'
import TableWrapper from '../wrappers/table-wrapper.component'
import { Button } from '@mui/material'
import { getReference } from '../../services/deposit.service'

export default function TransactionHistoryTable(props: any) {
    const { onSearch, list, meta, onSort, onPageChange, isLoading, isDesktop } =
        props
    const { current_page, total, last_page = 0, from = 0, to = 0 } = meta || {}

    const [sortBy, setSortBy] = useState('')

    const headers = [
        {
            key: 'created_at',
            label: `Date & Time`,
            customValue: (row: any) => formatDate(row.created_at, 'l LT'),
        },
        { key: 'code', label: 'Transaction ID' },
        {
            key: 'type',
            label: 'Type',
            customValue: (row: any) => TRANSACTION_TYPE[row?.type ?? 0],
        },
        {
            key: 'amount',
            label: 'Amount',
            customValue: (row: any) => (row?.amount ?? 0).toFixed(2),
        },
        {
            key: 'reference',
            label: 'Reference ID',
            customValue: getReference,
        },
        {
            key: 'option',
            label: 'Payment Method',
            customValue: (row: any) => PAYMENT_TYPE[row?.option ?? 0],
        },
        {
            key: 'status',
            label: 'Status',
            customValue: (row: any) => {
                const { label, color } = PAYMENT_STATUS[row.status] || {}
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '14px',
                            '& .status-icon': {
                                height: '8px',
                                width: '8px',
                                border: '3px solid #FF4848',
                                borderRadius: '50%',
                                mr: '5px',
                                mb: '4px',
                            },
                            color,
                            '.status-icon': { borderColor: color },
                        }}
                    >
                        <div className="status-icon" /> {label}
                    </Box>
                )
            },
        },
        // commented out for the moment
        // {
        //     key: 'invoice',
        //     label: 'Invoice',
        //     customValue: (row: any) => (
        //         <Box
        //             sx={{
        //                 display: 'flex',
        //                 alignItems: 'center',
        //                 justifyContent: 'space-evenly',
        //                 svg: { color: '#BABEC8' },
        //             }}
        //         >
        //             Download <DownloadForOfflineIcon />
        //         </Box>
        //     ),
        // },
    ]

    const onSortChange = (value: string) => {
        setSortBy(value)
        onSort(value)
    }

    return (
        <TableWrapper
            entityLabel="payments"
            headers={headers}
            responsiveHeaders={[
                {
                    key: 'created_at',
                    label: `Date & Time`,
                    customValue: (row: any) =>
                        formatDate(row.created_at, 'l LT'),
                },
            ]}
            body={list}
            total={total}
            currentPage={current_page}
            sortBy={sortBy}
            pageCount={last_page}
            pageFrom={from}
            pageTo={to}
            isLoading={isLoading}
            isDesktop={isDesktop}
            excludeHeaders={['invoice']}
            onSortChange={onSortChange}
            onPageChange={onPageChange}
            onSearch={onSearch}
            mobileActionIcon={() => (
                <DownloadForOfflineIcon
                    sx={{ color: '#BABEC8', fontSize: '2rem' }}
                />
            )}
            //commented out for the mean time
            // additionalActions={
            //     <DownloadForOfflineIcon sx={{ color: '#27A857' }} />
            // }
            additionalActionsMobile={
                <Button
                    variant="contained"
                    sx={{ textTransform: 'capitalize' }}
                >
                    Download <DownloadIcon />
                </Button>
            }
        />
    )
}
