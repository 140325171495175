import { useState } from 'react'
import { Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

export default function CutoffText(props: any) {
    const { text = '', maxWidth = '300px', disableCopy = false } = props
    const [copyLabel, setCopyLabel] = useState('Copy Url')
    const handleCopyUrl = () => {
        const textToCopy = text ?? ''
        const textarea = document.createElement('textarea')
        textarea.value = textToCopy
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)
        setCopyLabel('Copied')
    }
    return (
        <Box
            sx={{
                display: 'flex',
                maxWidth,
                overflow: 'hidden',
                '.cutoff-text': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: '100%',
                },
            }}
        >
            <div className="cutoff-text">
                <Tooltip placement="top-start" title={text} arrow>
                    <span>{text}</span>
                </Tooltip>
            </div>
            {!disableCopy && (
                <div>
                    <Tooltip title={copyLabel}>
                        <ContentCopyIcon
                            fontSize="small"
                            sx={{ cursor: 'pointer' }}
                            onClick={handleCopyUrl}
                        />
                    </Tooltip>
                </div>
            )}
        </Box>
    )
}
