import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useNavigate } from "react-router";
import { Box } from "@mui/system";
import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash';
import { STATUS_COMPLETED } from "../../../types/order_status.types";
import CampaignCard from "../campaign-card.component";
import CustomButton from "../../customComponents/custom-button.component";
import { IsSmallestScreen } from "../../../helpers/utils.helper";

export default function CustomerOrders(props: any) {
    const { list, meta, onPageChange, isLoading } = props;
    const { current_page, last_page = 0 } = meta || {};
    const hasMore = last_page > current_page;
    const isSmallestScreen = IsSmallestScreen();
    let navigate = useNavigate();

    const [orders, setOrders] = useState(list);
    const [yCoordinate, setYCoordinate] = useState(window.scrollY);

    const handleNavigation = useCallback(
        (e: any) => {
            const window = e.currentTarget;
            setYCoordinate(window.scrollY);
        }, []
    );

    useEffect(() => {
        setYCoordinate(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    useEffect(() => {
        setOrders(_.uniqBy([...orders, ...list], 'id'));
    }, [current_page]);  // eslint-disable-line

    let finalList = orders;
    if (!isLoading) {
        finalList = orders.filter((e: any) => !e.isSkeleton);
    } else {
        finalList = [...orders, ...Array(10).fill(null).map((e, i) => ({ isSkeleton: true, status: i % 2 ? 'completed' : 'pending' }))];
    }

    const listCompleted = finalList.filter((record: any) => record.status === STATUS_COMPLETED);
    const listNotCompleted = finalList.filter((record: any) => record.status !== STATUS_COMPLETED);
    return (
        <React.Fragment>
            <Box
                onClick={() => navigate('/orders/new')}
                sx={{
                    border: '2px dashed #6B6F7B',
                    width: { xs: '100%', sm: '315px' },
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '160px',
                    cursor: 'pointer',
                    p: {
                        margin: 0,
                        color: '#6B6F7B',
                        fontWeight: 500,
                        fontSize: '16px',
                    },
                    svg: {
                        color: '#6B6F7B',
                        fontSize: ' 30px',
                    },
                }}
            >
                <AddCircleOutlineOutlinedIcon />
                <p style={{ marginTop: '0px' }}>Add new Campaign</p>
            </Box>
            <h2 style={{ marginTop: '50px' }} className="title">Campaigns</h2>
            <Grid container spacing={2} sx={{ mb: '50px' }}>
                {
                    listNotCompleted.length === 0
                        ? <Box sx={{ color: '#6B6F7B', fontSize: '18px', fontStyle: 'italic', pl: '15px' }}>No  active campaign has been added yet.</Box>
                        : listNotCompleted.map((record: any, index: number) => (
                            <CampaignCard
                                isLoading={record.isSkeleton}
                                getRightNav={props.getRightNav}
                                showRightSideBar={props.showRightSideBar}
                                record={record}
                                index={index} key={`card_cont_${index}`}
                            />
                        ))
                }
            </Grid>
            <h2 className="title">Completed Campaigns</h2>
            <Grid container spacing={2}>
                {
                    listCompleted.length === 0
                        ? <Box sx={{ color: '#6B6F7B', fontSize: '18px', fontStyle: 'italic', pl: '15px' }}>No  active campaign has been added yet.</Box>
                        : listCompleted.length === 0
                            ? <Box sx={{ color: '#6B6F7B', fontSize: '18px', fontStyle: 'italic', pl: '15px' }}>No  active campaign has been completed yet.</Box>
                            : listCompleted.map((record: any, index: number) => (
                                <CampaignCard
                                    isLoading={record.isSkeleton}
                                    getRightNav={props.getRightNav}
                                    showRightSideBar={props.showRightSideBar}
                                    record={record}
                                    index={index} key={`card2_cont_${index}`}
                                />
                            ))
                }
            </Grid>
            {
                (hasMore && !isLoading) && <Box sx={{ textAlign: 'center', mt: '50px' }}>
                <CustomButton
                    label='Show More'
                    className='upviews-button'
                    startIcon={<AddIcon />}
                    onClick={() => onPageChange(current_page + 1)}
                    fullWidth={isSmallestScreen}
                />
            </Box>
            }

            {
                (!props.isDesktop && yCoordinate >= 200) && (
                    <Box sx={{
                        bgcolor: '#fff',
                        height: '150px',
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        left: 0,
                        borderTopLeftRadius: '40px',
                        borderTopRightRadius: '40px',
                        boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 10%)',
                        textAlign: 'center'
                    }}>
                        <CustomButton
                            label='Add a New Campaign'
                            type="submit"
                            className="upviews-button"
                            sx={{ float: 'unset!important', mr: 'unset!important', mt: '17px!important', width: '90%' }}
                            onClick={() => {
                                navigate('/orders/new')
                                window.scrollTo(0, 0);
                            }}
                        />
                    </Box>
                )
            }
        </React.Fragment >
    );
}
