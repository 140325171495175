import { Avatar, Button, Grid, Modal } from '@mui/material'
import { Box } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'

import PaymentSuccessCharacter from '../../assets/images/payment-success-character.png'
import PaymentSuccessImage from '../../assets/images/payment-success-img.png'
import { getCurrentUser } from '../../services/auth.service'

type Props = {
    open: boolean
    onClose: any
    transaction: any
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    maxWidth: 858,
    bgcolor: 'background.paper',
    boxShadow: 24,
}

export default function DepositSuccessModal(props: Props) {
    const { transaction } = props
    const currentUser = getCurrentUser()
    const hasError = transaction?.hasError ?? false

    return (
        <Modal open={props.open}>
            <Box sx={style} className="deposit-confirmation-container">
                <Avatar onClick={props.onClose}>
                    <CloseIcon />
                </Avatar>

                <Grid container>
                    <Grid item sm={5}>
                        <div className="left-payment-container">
                            {!hasError && (
                                <img
                                    className="character"
                                    src={PaymentSuccessCharacter}
                                    alt=""
                                />
                            )}
                        </div>
                    </Grid>
                    <Grid item sm={7} className="">
                        <div
                            className="modal-details"
                            style={{ textAlign: 'center' }}
                        >
                            <img src={PaymentSuccessImage} alt="" />
                            <h3>Hey {currentUser?.name},</h3>
                            <h2
                                style={{
                                    color:
                                        transaction?.hasError ?? false
                                            ? '#e54870'
                                            : 'inherit',
                                }}
                            >
                                {!hasError &&
                                    (`Your Payment Is ` +
                                        transaction?.transaction?.status ??
                                        ' Confirmed')}
                                {hasError && `Your Payment has failed`}
                            </h2>
                            <p>
                                {transaction?.transaction?.message ??
                                    `Your balance will be updated and reflect the
                                added funds! This is usually instant but can
                                take 1-3 minutes`}
                            </p>
                            <div className="button-container">
                                <Button
                                    variant="contained"
                                    className="upviews-button"
                                    onClick={props.onClose}
                                >
                                    Go back to Dashboard
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
