import {
    NEW_PROFILE_FAIL,
    NEW_PROFILE_REQUEST,
    NEW_PROFILE_SUCCESS,
    PROFILE_UPDATE_FAIL,
    PROFILE_UPDATE_REQUEST,
    PROFILE_UPDATE_RESET,
    PROFILE_UPDATE_SUCCESS,
} from '../constants/user.constants'

export interface ProfileState {
    loading?: boolean
    message?: string
    showNotification: boolean
    hasError: boolean
}

interface Action {
    type: string
    payload?: any
}

export const profileReducer = (
    state: ProfileState = {
        loading: false,
        showNotification: false,
        hasError: false,
    },
    action: Action
) => {
    switch (action.type) {
        case NEW_PROFILE_SUCCESS:
            return {
                loading: false,
                showNotification: true,
                hasError: false,
                message: 'User successfully added.',
            }
        case NEW_PROFILE_FAIL:
            return {
                loading: false,
                showNotification: true,
                hasError: true,
                message:
                    action.payload?.message ??
                    'Error occured while creating new user',
            }
        case NEW_PROFILE_REQUEST:
            return { loading: true }
        case PROFILE_UPDATE_REQUEST:
            return {
                loading: true,
                showNotification: false,
                hasError: false,
                message: '',
            }
        case PROFILE_UPDATE_SUCCESS:
            return {
                loading: false,
                showNotification: true,
                hasError: false,
                message: 'Profile successfully updated.',
            }

        case PROFILE_UPDATE_FAIL:
            return {
                loading: false,
                showNotification: true,
                hasError: true,
                message:
                    action.payload?.message ?? 'Error occurred when updating.',
            }

        case PROFILE_UPDATE_RESET:
            return {
                loading: false,
                message: '',
                showNotification: false,
                hasError: false,
            }
        default:
            return state
    }
}
