import React, { useCallback, useState } from 'react'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import LockIcon from '@mui/icons-material/Lock'
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled'
import ArrowOutwardIcon from '@mui/icons-material/CallMade'

import TableWrapper from '../../wrappers/table-wrapper.component'
import {
    Avatar,
    Box,
    FormControl,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import { CheckCircleOutline } from '@mui/icons-material'
import { formatDate, IsSmallestScreen } from '../../../helpers/utils.helper'
import CustomButton from '../../customComponents/custom-button.component'
import { KeyboardArrowDown } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import _ from 'lodash'
import TextSkeleton from '../../skeletons/text.skeleton'

const getUserRole = (role: any) => {
    return role.is_admin ? 'Admin' : 'Customer'
}

const SettingsUser = (props: any) => {
    const {
        onSearch,
        list,
        meta,
        onSort,
        onPageChange,
        banUserIp,
        banUserAccount,
        pauseUserAccount,
        activateAccount,
        options,
        isLoading,
        isDesktop,
    } = props
    const { current_page, total, last_page = 0, from = 0, to = 0 } = meta || {}
    const [sortBy, setSortBy] = useState('')

    const isSmallestScreen = IsSmallestScreen()

    const onSortChange = (value: string) => {
        setSortBy(value)
        onSort(value)
    }

    const headers = [
        {
            key: 'id',
            label: '',
            isVisible: true,
            customValue: (row: any) => {
                if (!row.avatar) {
                    return (
                        <Avatar sx={{ bgcolor: '#2ecc71' }}>
                            {row.name.charAt(0)}
                        </Avatar>
                    )
                }
                return (
                    <Avatar
                        sx={{ bgcolor: '#' + row.avatar }}
                        src={require('../../../assets/images/avatars/' +
                            row.avatar +
                            '.png')}
                    />
                )
            },
            customRowStyle: { width: 50, paddingRight: 0, paddingBottom: 5 },
        },
        {
            key: 'name',
            label: 'User Name',
            customValue: (row: any) => {
                return (
                    <div>
                        <div>
                            <strong>{row.name}</strong>
                        </div>
                    </div>
                )
            },
        },
        {
            key: 'role',
            label: 'Role',
            customValue: (row: any) => {
                return (
                    <div>
                        <div>
                            <strong>{getUserRole(row)}</strong>
                        </div>
                    </div>
                )
            },
        },
        {
            key: 'ip_address',
            label: 'IP Address',
            customValue: (row: any) => {
                return (
                    <div>
                        <div>
                            <strong>{row.ip_address}</strong>
                        </div>
                        {props.isDesktop && (
                            <div className="sub-label">Recently Used</div>
                        )}
                    </div>
                )
            },
        },
        { key: 'balance', label: 'Account Balance' },
        { key: 'spending', label: 'Amount Spent' },
        {
            key: 'status',
            label: 'Status',
            customValue: (row: any) => {
                const statusColorClass =
                    row.status === 'banned' ? 'red' : 'orange'
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '14px',
                            '& .status-icon': {
                                height: '8px',
                                width: '8px',
                                border: '3px solid #FF4848',
                                borderRadius: '50%',
                                mr: '5px',
                                mb: '4px',
                            },
                            '&.orange': {
                                color: '#FF9548',
                                '& .status-icon': { borderColor: '#FF9548' },
                            },
                            '&.red': { color: '#FF4848' },
                        }}
                        className={`${statusColorClass}`}
                    >
                        <div className="status-icon" />{' '}
                        {(options?.user_statuses || {})[row.status] ?? ''}
                    </Box>
                )
            },
        },
    ]

    const responsiveHeaders = [
        {
            key: 'name',
            label: '',
            customValue: (row: any) => (
                <Box display="flex" alignItems="center">
                    {!row.avatar ? (
                        <Avatar
                            sx={{ bgcolor: '#2ecc71', marginRight: '16px' }}
                        >
                            {row.name.charAt(0)}
                        </Avatar>
                    ) : (
                        <Avatar
                            sx={{
                                bgcolor: '#' + row.avatar,
                                marginRight: '16px',
                            }}
                            src={require('../../../assets/images/avatars/' +
                                row.avatar +
                                '.png')}
                        />
                    )}
                    <div>
                        <div>
                            <strong>{row.name}</strong>
                        </div>
                    </div>
                </Box>
            ),
        },
    ]

    const contextMenu = [
        {
            callBack: (row: any) => banUserAccount(row?.id),
            label: 'Ban User Account',
            icon: <PersonRemoveIcon />,
        },
        {
            callBack: (row: any) => banUserIp(row?.id),
            label: `Ban User's IP`,
            icon: <LockIcon />,
        },
        {
            callBack: (row: any) => pauseUserAccount(row?.id),
            label: 'Pause Account',
            icon: <PauseCircleFilledIcon />,
        },
        {
            callBack: (row: any) => activateAccount(row?.id),
            label: 'Activate Account',
            icon: <CheckCircleOutline />,
        },
    ]

    const changeSearchTerm = _.debounce((value) => {
        onSearch(value)
    }, 200)
    const handleSearchTermChange = useCallback(changeSearchTerm, []) //eslint-disable-line

    const createUser = () => {
        window.location.href = '/profile/new'
        return
    }

    const onGetCollapsibleComponent = (
        row: any,
        isCollapsed: boolean = false
    ) => {
        const filteredHeaders = headers.filter(
            (header: any) =>
                responsiveHeaders.find((r) => r.key !== header.key) &&
                header.key !== 'id'
        )
        return (
            <Box
                sx={{
                    '& span.info': {
                        color: '#656565',
                        mt: '100px',
                    },
                }}
            >
                {!isDesktop &&
                    filteredHeaders.map((header: any, hIndex: number) => {
                        return (
                            <Box
                                key={`collapsible-mobile-${hIndex}`}
                                display="flex"
                                justifyContent="space-between"
                                pb="10px"
                                sx={{
                                    '& .label': {
                                        color: '#BFBFBF',
                                    },
                                }}
                            >
                                <div className="label">{header.label}</div>
                                <div>
                                    {isLoading ? (
                                        <TextSkeleton width="100px" />
                                    ) : header.customValue ? (
                                        header.customValue(row)
                                    ) : (
                                        row[header.key]
                                    )}
                                </div>
                            </Box>
                        )
                    })}

                <Box
                    sx={{
                        margin: isDesktop ? '5px 60px' : 'unset',
                        '.additional-info': {
                            color: '#656565',
                            fontWeight: 'bold',
                            mt: '10px',
                        },
                        display: isCollapsed ? 'block' : 'none',
                    }}
                    key={row.id}
                >
                    <div className="additional-info">
                        Additional information
                    </div>
                    <Box
                        sx={{
                            display: isDesktop ? 'flex' : 'block',
                            justifyContent: 'space-between',
                            alignItems: 'start',
                            mt: '10px',
                            '&>div': {
                                display: 'flex',
                                flexDirection: isSmallestScreen
                                    ? 'row'
                                    : 'column',
                                justifyContent: 'space-between',
                                alignItems: isSmallestScreen
                                    ? 'center'
                                    : 'unset',
                                mt: '10px',
                                'span:first-of-type': {
                                    color: '#BFBFBF',
                                    lineHeight: isDesktop ? '3' : '1',
                                },
                            },
                        }}
                    >
                        <div>
                            <span>Recent order No.</span>
                            <span>{row?.recent_order?.code ?? '-'}</span>
                        </div>
                        <div>
                            <span>Recent IP Address used.</span>
                            <span>{row?.recent_login?.ip_address ?? '-'}</span>
                        </div>
                        <div>
                            <span>Recent Timestamp(login)</span>
                            <span>
                                {formatDate(
                                    row?.recent_login?.timestamp,
                                    'MMMM D, YYYY | h:mmA Z'
                                ) ?? '-'}
                            </span>
                            <Box
                                onClick={() =>
                                    window.open(`/login-records/${row.id}`)
                                }
                                sx={{
                                    cursor: 'pointer',
                                    color: '#27A857',
                                    textDecoration: 'underline',
                                    display: 'flex',
                                    alignItems: 'end',
                                    '& span': {
                                        mr: '5px',
                                    },
                                }}
                            >
                                <div>Check all Timestamp</div>
                                <ArrowOutwardIcon fontSize="small" />
                            </Box>
                        </div>
                        <div>
                            <span>Deposit History.</span>
                            <Box
                                onClick={() =>
                                    window.open(`/deposits?userId=${row.id}`)
                                }
                                sx={{
                                    cursor: 'pointer',
                                    color: '#27A857',
                                    textDecoration: 'underline',
                                    display: 'flex',
                                    alignItems: 'end',
                                    '& span': {
                                        mr: '5px',
                                    },
                                }}
                            >
                                <div>Check all transactions</div>
                                <ArrowOutwardIcon fontSize="small" />
                            </Box>
                        </div>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <TableWrapper
            entityLabel="users"
            headers={headers}
            body={list}
            responsiveHeaders={responsiveHeaders}
            excludeHeaders={['id']}
            contextMenu={contextMenu}
            total={total}
            currentPage={current_page}
            sortBy={sortBy}
            isLoading={isLoading}
            pageCount={last_page}
            pageFrom={from}
            pageTo={to}
            additionalActions={
                <>
                    <Box
                        width={isSmallestScreen ? '100%' : '300px'}
                        mr={isSmallestScreen ? 'unset' : '15px'}
                    >
                        <CustomButton
                            label="Create New User"
                            className="upviews-button"
                            onClick={createUser}
                            fullWidth={IsSmallestScreen()}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: isSmallestScreen ? 'block' : 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            minWidth: { md: '30%' },
                        }}
                    >
                        <FormControl
                            className="upview-form-control"
                            sx={{
                                width: '100%',
                                mr: '20px',
                            }}
                        >
                            <TextField
                                placeholder="Search for..."
                                size="small"
                                id="outlined-basic"
                                variant="outlined"
                                onChange={(e) => {
                                    handleSearchTermChange(e.target.value)
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <SearchIcon
                                            sx={{
                                                fontSize: '1.5rem!important',
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </FormControl>

                        <FormControl
                            className="upview-form-control common-styled-select"
                            hiddenLabel
                            size="small"
                            sx={{
                                width: '100%',
                                mt: {
                                    xs: '20px',
                                    sm: '0px',
                                },
                            }}
                        >
                            <Select
                                IconComponent={KeyboardArrowDown}
                                inputProps={{
                                    'aria-label': 'Without label',
                                }}
                                displayEmpty
                                value={sortBy}
                                onChange={(e) => onSortChange(e.target.value)}
                            >
                                <MenuItem value="">Sort By</MenuItem>
                                <MenuItem value="created_at">
                                    Date Added
                                </MenuItem>
                                <MenuItem value="status">Status</MenuItem>
                            </Select>
                        </FormControl>
                        <DownloadForOfflineIcon sx={{ color: '#27A857' }} />
                    </Box>
                </>
            }
            additionalActionsMobile={
                <Box
                    width={isSmallestScreen ? '100%' : '300px'}
                    mr={isSmallestScreen ? 'unset' : '15px'}
                >
                    <CustomButton
                        label="Create New User"
                        className="upviews-button"
                        onClick={createUser}
                        fullWidth={IsSmallestScreen()}
                    />
                </Box>
            }
            onPageChange={onPageChange}
            showFiltersFilter={true}
            hideFilters={true}
            onGetCollapsibleComponent={onGetCollapsibleComponent}
        />
    )
}

export default SettingsUser
