import { Avatar, Box, Divider, Grid, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CustomLabeledFormControl from '../../customComponents/custom-labeled-form-control'
import CustomButton from '../../customComponents/custom-button.component'
import CustomStatus from '../../customComponents/custom-status.component'
import { ChangeEvent } from 'react'

type Props = {
    open: boolean
    onClose: any
    data: any
    options: any
    formProps: any
    isLoading: boolean
    onChange: any
}

export default function UpdateOrderModal(props: Props) {
    const { data, onClose, open, options, formProps, isLoading, onChange } =
        props

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '12px',
        fontFamily: 'Nunito, sans-serif',
        padding: 4,
        '& label': {
            lineHeight: 3,
            fontWeight: 'bold',
            color: '#bfbfbf',
        },
        '& span': {
            color: '#bfbfbf',
        },
        '& .field': {
            pb: '10px',
        },
    }

    const handleExternalIdChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        e.preventDefault()
        if (!e.target) {
            return
        }

        onChange('external_order_id', e.target.value)(e)
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={style}
                component="form"
                noValidate
                autoComplete="off"
                {...formProps}
            >
                <Grid item className="modal-close">
                    <Avatar onClick={onClose}>
                        <CloseIcon />
                    </Avatar>
                </Grid>
                <Box sx={{ pb: '20px' }}>
                    <CustomStatus status={data?.status} />
                </Box>
                <Box className="field">
                    <span>Order No:</span> <div>{data?.code}</div>
                </Box>
                <Box className="field">
                    <span> Service:</span>{' '}
                    <div>
                        {' '}
                        {(options?.services ?? [])[data?.service] ?? ''}
                        {' - '}
                        {(options?.service_types ?? [])[data?.service_type] ??
                            ''}
                    </div>
                </Box>

                <CustomLabeledFormControl
                    label="External ID"
                    fullWidth
                    size="small"
                    name="external_order_id"
                    value={data?.external_order_id ?? ''}
                    onChange={handleExternalIdChange}
                />
                <Divider />
                <CustomButton
                    label="Save"
                    type="submit"
                    className="upviews-button"
                    sx={{
                        float: 'right',
                        marginTop: '20px',
                        width: '100%',
                    }}
                    isLoading={isLoading}
                />
            </Box>
        </Modal>
    )
}
