import { REGISTER_FAIL, REGISTER_REQUEST, REGISTER_RESET, REGISTER_SUCCESS } from '../constants/user.constants';

export interface RegisterState {
  loading?: boolean;
  error?: string;
  isRegistered: boolean;
  showNotification: boolean;
  payload?: any;
}

interface Action {
  type: string;
  payload?: string;
}

export const registerReducer = (
  state: RegisterState = {
    isRegistered: false,
    showNotification: false,
    loading: false,
  },
  action: Action
) => {
  switch (action.type) {
    case REGISTER_REQUEST:
      return { loading: true, isRegistered: false };
    case REGISTER_SUCCESS:
      return { loading: false, isRegistered: true };
    case REGISTER_FAIL:
      return { loading: false, isRegistered: false, payload: action.payload, showNotification: true };
    case REGISTER_RESET:
      return { loading: false, isRegistered: false, showNotification: false };
    default:
      return state;
  }
};
