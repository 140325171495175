import { ChangeEvent, SyntheticEvent, useState } from "react";
import { useSelector } from "react-redux";
import { FiltersAction } from "../../../actions/filters.actions";
import { ScreenState } from "../../../reducers/screen.reducer";
import store, { RootState } from "../../../store";
import FilterForm from "./filter.form.component";

type Options = {
  id: string;
  name: string;
};

const formatOptions = (options: Object): Options[] => {
  return (Object.entries(options ?? {}) as [string, string][]).map(
    ([key, value]: [string, string]): Options => {
      return {
        id: key,
        name: value,
      };
    }
  );
};

const validateForm = (payload: any) => {
  let dataFields = [
    {
      key: "keyword",
      isValid: payload.keyword !== "",
    },
    {
      key: "category",
      isValid: payload.category !== "",
    },
    {
      key: "type",
      isValid: payload.type > 0,
    },
  ];

  return {
    data: dataFields,
    isValid: dataFields.every((data) => data.isValid),
  };
};

export default function AddFilterNav(props: any) {
  const { options } = useSelector<RootState, ScreenState>(
    (state) => state.screen
  );

  const [payload, setPayload] = useState({
    keyword: "",
    category: "",
    type: 1,
  });

  const defaultErrors = {
    keyword: false,
    category: false,
    type: false,
  };

  const [errors, setErrors] = useState(defaultErrors);

  const onSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    const validation = validateForm(payload);

    if (!validation.isValid) {
      setErrors(
        validation.data.reduce((prevField, field) => {
          return { ...prevField, [field.key]: !field.isValid };
        }, defaultErrors)
      );
      return;
    }
    store.dispatch(FiltersAction.new(payload));
  };

  const onChange =
    (name: string, customValue?: any) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = customValue ?? event.target.value;
      setPayload((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  return (
    <FilterForm
      options={{
        filterCategories: formatOptions(options?.filter_categories),
        filterTypes: formatOptions(options?.filter_types),
      }}
      payload={payload}
      btnText={'Add Filter'} 
      formProps={{ onSubmit: onSubmit }}
      onChange={onChange}
      errors={errors}
    />
  );
}
