import { SUPPORT_FAIL, SUPPORT_REQUEST, SUPPORT_SUCCESS } from "../constants/support.constants";
import { SupportService } from "../services/support.service";
import { AppThunk } from "../store";

export const SupportAction = {
  new:
    (payload: any): AppThunk =>
    async (dispatch) => {
      dispatch({
        type: SUPPORT_REQUEST,
      });

      await SupportService.new(payload)
        .then((response: any) => {
          let responseData = response?.data;

          if (responseData?.data) {
            return dispatch({
              type: SUPPORT_SUCCESS,
              payload: responseData.data,
            });
          }

          return response?.data;
        })
        .catch(function (error) {
          dispatch({
            type: SUPPORT_FAIL,
            payload: error?.response?.data,
          });
        });
    },
}