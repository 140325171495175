import { format } from 'react-string-format'
import API_INSTANCE from './api'
import endpoints from './endpoints'

export const FiltersService = {
    index: async (
        page: number = 1,
        search: string | null = null,
        category: string | null = null,
        type: string | null = null
    ) => {
        let searchStr = search ? '&search=' + search : ''
        let categoryStr = category ? '&category=' + category : ''
        let typeStr = type ? '&type=' + type : ''

        return API_INSTANCE.get(
            endpoints.GET_FILTERS +
                '?per_page=15&page=' +
                page +
                searchStr +
                categoryStr +
                typeStr
        )
    },
    new: async (fields: any) => {
        return API_INSTANCE.post(endpoints.NEW_FILTER, fields)
    },
    edit: async (fields: any, filterID: number) => {
        return API_INSTANCE.put(format(endpoints.EDIT_FILTER, filterID), fields)
    },
    delete: async (filterId: number) => {
        return API_INSTANCE.delete(format(endpoints.DELETE_FILTER, filterId))
    },
}
