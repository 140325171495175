import { format } from "react-string-format";
import API_INSTANCE from "./api";
import endpoints from "./endpoints";

export const ApiProvidersService = {
  index: async (
    page: number = 1
  ) => {
    return API_INSTANCE.get(endpoints.GET_API_PROVIDERS + '?per_page=15&page=' + page);
  },
  new: async (fields: any) => {
    return API_INSTANCE.post(endpoints.NEW_API_PROVIDER,fields);
  },
  edit: async (fields: any, apiID: number) => {
    return API_INSTANCE.put(format(endpoints.EDIT_API_PROVIDER, apiID), fields);
  },
  
  
}