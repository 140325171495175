import React from 'react'
import {
    Box,
    Button,
    Collapse,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import _ from 'lodash'
import { DataPropsAny } from './table-wrapper.component'
import TextSkeleton from '../skeletons/text.skeleton'
import CustomButton from '../customComponents/custom-button.component'
import { OrderService } from '../../services/orders.service'
import CutoffText from '../customComponents/cutoff-text.component'

const DesktopTable = (props: DataPropsAny) => {
    const {
        headers,
        body,
        contextMenu = [],
        contextMenuIcon,
        onGetCollapsibleComponent = () => {},
        isCollapsible,
        hasContextMenu,
        activeContextRow,
        setCollapsedRow,
        collapsedRow,
        isContextMenuOpen,
        handleOpenContextMenu,
        anchorEl,
        handleCloseContextMenu,
        columnTotal,
        isLoading,
        entityLabel,
        entity,
        onAddRecord,
        contextMenuButtonStyles,
    } = props

    let tableData = body
    if (isLoading) {
        const loadingData = Object.keys(headers).reduce(
            (acc, curr) => ({ ...acc, [curr]: '' }),
            {}
        )
        tableData = Array(5)
            .fill(null)
            .map(() => loadingData)
    }

    const markAsRead = (orderId: number) => {
        let readOrders = OrderService.getReadOrders()

        //put in read array
        if (!readOrders.includes(orderId)) {
            readOrders.push(orderId)
            localStorage.setItem('readOrders', JSON.stringify(readOrders))
        }
        //map table with order id mark as read
        tableData.map((data: any) => {
            if (data.id === orderId) {
                data.unread = false
            }
            return data
        })
    }

    return (
        <TableContainer>
            <Table
                aria-labelledby="tableTitle"
                size={'medium'}
                sx={{
                    borderSpacing: '0px 10px',
                    borderCollapse: 'separate',
                    p: '5px',
                }}
            >
                <TableHead sx={{ bgcolor: '#fff' }} className="thead">
                    <TableRow>
                        {isCollapsible && <TableCell />}
                        {headers.map((header: DataPropsAny, index: number) => {
                            return (
                                <TableCell key={`header-desktop-${index}`}>
                                    {header.label}
                                </TableCell>
                            )
                        })}
                        {hasContextMenu && <TableCell />}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((data: DataPropsAny, index1: number) => {
                        const isContextMenuActive =
                            activeContextRow?.id === data.id
                        return (
                            <React.Fragment key={`fragment-desktop-${index1}`}>
                                <TableRow
                                    sx={{
                                        boxShadow:
                                            '0px 0px 5px rgb(4 4 4 / 15%)',
                                        backgroundColor: data?.unread
                                            ? '#F3FFE8'
                                            : 'none',
                                    }}
                                    key={`body-row-${index1}`}
                                    className="preview-desktop-screen"
                                >
                                    {isCollapsible && (
                                        <TableCell
                                            key={`collapse-icon-${index1}`}
                                            width="40px"
                                            sx={{
                                                borderLeft: data?.unread
                                                    ? '2px solid #27A857 !important'
                                                    : 'none',
                                            }}
                                        >
                                            <IconButton
                                                key={`collapse-button-${index1}`}
                                                aria-label="expand row"
                                                size="small"
                                                disabled={isLoading}
                                                onClick={() => {
                                                    setCollapsedRow({
                                                        ...collapsedRow,
                                                        [data.id]:
                                                            !collapsedRow[
                                                                data.id
                                                            ],
                                                    })
                                                    if (data?.unread) {
                                                        markAsRead(data.id)
                                                    }
                                                }}
                                            >
                                                {collapsedRow[data.id] ? (
                                                    <RemoveIcon className="remove" />
                                                ) : (
                                                    <AddIcon className="add" />
                                                )}
                                            </IconButton>
                                        </TableCell>
                                    )}
                                    {headers.map(
                                        (
                                            header: DataPropsAny,
                                            index2: number
                                        ) => {
                                            const cellvalue = isLoading ? (
                                                <TextSkeleton />
                                            ) : header.customValue ? (
                                                header.customValue(data)
                                            ) : (
                                                data[header.key]
                                            )
                                            return (
                                                <TableCell
                                                    key={`data-desktop-${index1}-${index2}`}
                                                    style={
                                                        header.customRowStyle
                                                    }
                                                >
                                                    {header.customWidth ? (
                                                        <CutoffText
                                                            text={cellvalue}
                                                            disableCopy={true}
                                                            maxWidth={
                                                                header.customWidth
                                                            }
                                                        />
                                                    ) : (
                                                        cellvalue
                                                    )}
                                                </TableCell>
                                            )
                                        }
                                    )}
                                    {hasContextMenu && (
                                        <TableCell
                                            key={`data_context-${index1}`}
                                            sx={{
                                                ...contextMenuButtonStyles,
                                                width: 30,
                                            }}
                                            className={`${
                                                isContextMenuActive
                                                    ? ' active'
                                                    : ''
                                            }`}
                                        >
                                            <Button
                                                key={`context-menu-button-${index1}`}
                                                id={`context-menu-button-${index1}`}
                                                aria-controls={
                                                    isContextMenuOpen
                                                        ? `context-menu-${index1}`
                                                        : undefined
                                                }
                                                aria-haspopup="true"
                                                aria-expanded={
                                                    isContextMenuOpen
                                                        ? 'true'
                                                        : undefined
                                                }
                                                onClick={(e) =>
                                                    handleOpenContextMenu(
                                                        e,
                                                        data
                                                    )
                                                }
                                            >
                                                {contextMenuIcon ? (
                                                    contextMenuIcon
                                                ) : (
                                                    <MoreVertRoundedIcon />
                                                )}
                                            </Button>
                                            {isContextMenuActive && (
                                                <Popover
                                                    id={`context-menu-${index1}`}
                                                    anchorEl={anchorEl}
                                                    open={isContextMenuOpen}
                                                    onClose={
                                                        handleCloseContextMenu
                                                    }
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    sx={{
                                                        '& .MuiPopover-paper': {
                                                            mt: '4px',
                                                            ml: '10px',
                                                            borderRadius:
                                                                '10px',
                                                        },
                                                        '& .MuiList-root': {
                                                            paddingTop: '5px',
                                                            paddingBottom:
                                                                '5px',
                                                            '& li': {
                                                                fontSize:
                                                                    '14px',
                                                                padding:
                                                                    '5px 15px',
                                                                '&:hover': {
                                                                    backgroundColor:
                                                                        '#FFE2E2',
                                                                    color: '#FF4848',
                                                                    cursor: 'pointer',
                                                                    '& svg': {
                                                                        color: '#FF4848',
                                                                    },
                                                                },
                                                                '& svg': {
                                                                    color: '#BABEC8',
                                                                    fontSize:
                                                                        '21px',
                                                                },
                                                                '& .MuiTypography-root':
                                                                    {
                                                                        fontSize:
                                                                            '14px',
                                                                    },
                                                                '& .MuiListItemIcon-root':
                                                                    {
                                                                        minWidth:
                                                                            '30px',
                                                                    },
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <List
                                                        key={`context-list-${index1}`}
                                                    >
                                                        {contextMenu.map(
                                                            (
                                                                context: DataPropsAny,
                                                                index: number
                                                            ) => {
                                                                return (
                                                                    ((context.value &&
                                                                        context.value !==
                                                                            data.type) ||
                                                                        !context.value) && (
                                                                        <ListItem
                                                                            key={`context-list-item-desktop-${index}`}
                                                                            disablePadding
                                                                            onClick={() => {
                                                                                handleCloseContextMenu()
                                                                                context.callBack(
                                                                                    data
                                                                                )
                                                                            }}
                                                                        >
                                                                            <ListItemIcon>
                                                                                {
                                                                                    context.icon
                                                                                }
                                                                            </ListItemIcon>
                                                                            <ListItemText>
                                                                                {
                                                                                    context.label
                                                                                }
                                                                            </ListItemText>
                                                                        </ListItem>
                                                                    )
                                                                )
                                                            }
                                                        )}
                                                    </List>
                                                </Popover>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                                {isCollapsible && (
                                    <TableRow key={`collapse-${index1}`}>
                                        <TableCell
                                            key={`collapse-cell-${index1}`}
                                            style={{
                                                padding: 0,
                                            }}
                                            colSpan={columnTotal}
                                        >
                                            <Collapse
                                                in={collapsedRow[data.id]}
                                                timeout="auto"
                                                unmountOnExit
                                            >
                                                {onGetCollapsibleComponent(
                                                    data,
                                                    collapsedRow[data.id]
                                                )}
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        )
                    })}
                    {body.length === 0 && !isLoading && (
                        <TableRow className="preview">
                            <TableCell colSpan={columnTotal} align="center">
                                <Box textAlign="center" py={10}>
                                    No {_.startCase(entityLabel)} Yet! Get the
                                    marketing started:
                                    {onAddRecord && (
                                        <Box
                                            sx={{
                                                '& button': {
                                                    textTransform: 'unset',
                                                    color: '#fff',
                                                    backgroundColor: '#27A857',
                                                    borderRadius: '30px',
                                                    fontWeight: '700',
                                                    fontSize: '18px',
                                                    padding: '10px 20px',
                                                    marginTop: '20px',
                                                },
                                            }}
                                        >
                                            <CustomButton
                                                label={`Add ${_.startCase(
                                                    entity
                                                )} Now`}
                                                className="upviews-button"
                                                onClick={() => onAddRecord()}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DesktopTable
