import { PP_REQUEST, PP_SUCCESS } from '../constants/user.constants'
import { FileService } from '../services/file.service'
import { AppThunk } from '../store'

export const FileAction = {
    viewByUrl:
        (url: string): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: PP_REQUEST,
            })
            await FileService.viewByUrl(url).then((response: any) => {
                let imgUrl = URL.createObjectURL(response.data)

                return dispatch({
                    type: PP_SUCCESS,
                    payload: imgUrl,
                })
            })
        },
}
