import { CameraAlt } from '@mui/icons-material'
import { Avatar, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import { ChangeEvent, useState } from 'react'
import { getProfilePic } from '../../services/account.service'

export default function ProfileImage(props: any) {
    const { isDesktop, isEdit = false, onChange, user } = props
    
    const [imagePreview, setImagePreview] = useState<string>('')
    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()

        if (!e.target) {
            return
        }

        let files = e.target.files ?? []

        if (files.length === 0) {
            return
        }

        onChange('photo', files[0])(e)

        let reader = new FileReader()
        reader.onloadend = () => {
            let preview = reader.result as string
            setImagePreview(preview)
        }
        reader.readAsDataURL(files[0])
    }

    const photoSource = () => {
        if (imagePreview) {
            return imagePreview
        }

        return getProfilePic(user)
    }

    const getAvatar = () => {
        let photoSrc = photoSource()

        return photoSrc ? (
            <Avatar
                sx={{ bgcolor: '#' + user?.info?.avatar ?? '2ecc71' }}
                src={photoSrc}
            />
        ) : (
            <Avatar sx={{ bgcolor: '#2ecc71' }}>
                {user?.info?.name?.charAt(0)}
            </Avatar>
        )
    }

    return (
        <Box
            sx={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mr: isDesktop ? '24px' : 'unset',
                width: isDesktop ? '40%' : 'unset',
                '& .MuiAvatar-root': {
                    width: 130,
                    height: 130,
                    zIndex: 1,
                },
            }}
        >
            <div style={{ position: 'relative' }}>
                {getAvatar()}
                {isEdit && (
                    <IconButton
                        aria-label="Change profile photo"
                        sx={{
                            backgroundColor: '#27A857',
                            position: 'absolute',
                            zIndex: 9,
                            right: '-1px',
                            bottom: '7px',
                            '&:hover': {
                                backgroundColor: '#27A857',
                            },
                        }}
                        component="label"
                    >
                        <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={handleImageChange}
                        />
                        <CameraAlt sx={{ color: '#FFFFFF' }} />
                    </IconButton>
                )}
            </div>

            <Box
                sx={{
                    '& div.profile-name': {
                        fontSize: '20px',
                        fontWeight: 'bold',
                    },
                    '& div.profile-email': {
                        fontSize: '14px',
                    },
                    backgroundColor: '#FFF',
                    borderRadius: '40px',
                    padding: '90px 0px 40px 0px',
                    position: 'relative',
                    bottom: '60px',
                    width: '100%',
                }}
            >
                <div className="profile-name">{user?.info?.name}</div>
                <div className="profile-email">{user?.info?.email}</div>
            </Box>
        </Box>
    )
}
