export const ORDER_REQUEST = 'ORDER_REQUEST'
export const ORDER_SUCCESS = 'ORDER_SUCCESS'
export const ORDER_GET_SUCCESS = 'ORDER_GET_SUCCESS'
export const ORDER_FAIL = 'ORDER_FAIL'
export const ORDER_RESET = 'ORDER_RESET'
export const ORDER_UPLOAD_FAIL = 'ORDER_UPLOAD_FAIL'
export const ORDER_UPLOAD_REQUEST = 'ORDER_UPLOAD_REQUEST'
export const ORDER_INITIATE_UPLOAD_REQUEST = 'ORDER_INITIATE_UPLOAD_REQUEST'
export const ORDER_RESEND_FAILED = 'ORDER_RESEND_FAILED'
export const ORDER_RESEND_SUCCESS = 'ORDER_RESEND_SUCCESS'
export const ORDER_CANCEL_FAILED = 'ORDER_CANCEL_FAILED'
export const ORDER_CANCEL_SUCCESS = 'ORDER_CANCEL_SUCCESS'
export const ORDER_ACTION_SUCCESS = 'ORDER_ACTION_SUCCESS'
export const ORDER_ACTION_FAILED = 'ORDER_ACTION_FAILED'
export const ORDER_ACTION_UPLOAD_REQUEST = 'ORDER_ACTION_UPLOAD_REQUEST'
export const ORDER_ACTION_UPLOAD_FAIL = 'ORDER_ACTION_UPLOAD_FAILs'
export const ORDER_ISSUE_DOWNLOAD_FAIL = 'ORDER_ISSUE_DOWNLOAD_FAIL'
export const ORDER_UPDATE_SUCCESS = 'ORDER_UPDATE_SUCCESS'
export const ORDER_UPDATE_FAILED = 'ORDER_UPDATE_FAILED'

export const ORDER_THRESHOLD = 15

export const SERVICE_TYPE_TARGETED = 1

export const SERVICE_VIEW = 1
export const SERVICE_LIKE = 2
export const SERVICE_SUBSCRIBE = 3
export const SERVICE_GROWTH = 4
export const SERVICE_COMMENT = 8

export const IG_SERVICE_FOLLOWERS = 5
export const IG_SERVICE_LIKES = 6
export const IG_SERVICE_COMMENTS = 7

export const BUNDLE_BEGINNER = 8
export const BUNDLE_CELEBRITY = 9
export const BUNDLE_INFLUENCER = 10
export const BUNDLE_RISING_STAR = 11

export const RATE_US_UK = 'us_uk'
export const RATE_WO_US_UK = 'wo_us_uk'
export const RATE_WORLDWIDE = 'world_wide'

export const COUNTRY_WORLDWIDE = '0'
export const COUNTRY_US = '1'
export const COUNTRY_CANADA = '2'
export const COUNTRY_FRANCE = '3'
export const COUNTRY_GREECE = '4'
export const COUNTRY_RUSSIA = '5'
export const COUNTRY_UK = '6'

export const ORDER_STATUS_COLORS = {
    pending: { dark: '#FF8832', color: '#FF9548' },
    in_progress: { dark: '#417FDC', color: '#4991FF' },
    cancelled: { dark: '#D43838', color: '#FF3E3E' },
    completed: { dark: '#1AD37C', color: '#17E383' },
    refunded: { dark: '#817974', color: '#8F8A87' },
}
