import {
    INFO_VIP_REQUEST,
    INFO_VIP_SUCCESS,
    INFO_VIP_FAIL,
    LOGIN_SUCCESS,
    LOGIN_REQUEST,
    LOGIN_FAIL,
    LOGOUT,
    REGISTER_REQUEST,
    REGISTER_FAIL,
    REGISTER_SUCCESS,
    INFO_SUCCESS,
    INFO_FAIL,
    INFO_REQUEST,
    RECOVER_REQUEST,
    RECOVER_SUCCESS,
    RECOVER_FAIL,
    RESET_REQUEST,
    RESET_SUCCESS,
    RESET_FAIL,
} from '../constants/user.constants'
import { AppThunk } from '../store'
import {
    VipDetailsService,
    InfoService,
    LoginService,
    RecoveryService,
    RegisterService,
    ResetService,
} from '../services/auth.service'

export const getVipDetails =
    (token: string): AppThunk =>
    async (dispatch) => {
        dispatch({
            type: INFO_VIP_REQUEST,
        })

        await VipDetailsService(`?token=${token}`)
            .then((response) => {
                let responseData = response?.data
                if (responseData.data) {
                    return dispatch({
                        type: INFO_VIP_SUCCESS,
                        payload: responseData.data,
                    })
                }
                return response?.data
            })
            .catch(function (error) {
                dispatch({
                    type: INFO_VIP_FAIL,
                    payload: 'Unable to fetch VIP details.',
                })
            })
    }

export const login =
    (email: string, password: string): AppThunk =>
    async (dispatch) => {
        dispatch({
            type: LOGIN_REQUEST,
        })

        await LoginService({
            email: email,
            password: password,
        })
            .then((response) => {
                let responseData = response?.data
                if (responseData.data) {
                    return dispatch({
                        type: LOGIN_SUCCESS,
                        payload: responseData.data,
                    })
                }
                return response?.data
            })
            .catch(function (error) {
                dispatch({
                    type: LOGIN_FAIL,
                    payload: 'Unable to login using this credential.',
                })
            })
    }

export const logout = (): AppThunk => async (dispatch) => {
    try {
        dispatch({ type: LOGOUT })
    } catch (error) {
        console.log(error)
    }
}

export const register =
    (payload: any): AppThunk =>
    async (dispatch) => {
        dispatch({
            type: REGISTER_REQUEST,
        })

        await RegisterService({
            name: payload.name,
            email: payload.email,
            password: payload.password,
            c_password: payload.confirmPassword,
            gender: payload.gender,
        })
            .then((response: any) => {
                let responseData = response?.data
                if (responseData.data) {
                    return dispatch({
                        type: REGISTER_SUCCESS,
                    })
                }
                return response?.data
            })
            .catch(function (error) {
                dispatch({
                    type: REGISTER_FAIL,
                    payload: error?.response?.data,
                })
            })
    }

export const getUserInfo = (): AppThunk => async (dispatch) => {
    dispatch({
        type: INFO_REQUEST,
    })

    await InfoService()
        .then((response: any) => {
            let responseData = response?.data
            if (responseData.data) {
                return dispatch({
                    type: INFO_SUCCESS,
                    payload: responseData.data,
                })
            }
            return response?.data
        })
        .catch(function (error) {
            dispatch({
                type: INFO_FAIL,
                payload: error?.response?.data,
            })
        })
}

export const recovery =
    (email: string): AppThunk =>
    async (dispatch) => {
        dispatch({
            type: RECOVER_REQUEST,
        })

        await RecoveryService({
            email: email,
        })
            .then((response) => {
                let responseData = response?.data

                if (responseData) {
                    return dispatch({
                        type: RECOVER_SUCCESS,
                        payload: { ...responseData, ...{ email } },
                    })
                }
                return response?.data
            })
            .catch(function (error) {
                dispatch({
                    type: RECOVER_FAIL,
                    payload: error?.response?.data,
                })
            })
    }

export const reset =
    (
        email: string,
        password: string,
        password_confirmation: string,
        token: string
    ): AppThunk =>
    async (dispatch) => {
        dispatch({
            type: RESET_REQUEST,
        })

        await ResetService({
            email: email,
            password: password,
            password_confirmation: password_confirmation,
            token: token,
        })
            .then((response) => {
                let responseData = response?.data
                if (responseData.data) {
                    return dispatch({
                        type: RESET_SUCCESS,
                        payload: responseData,
                    })
                }
                return response?.data
            })
            .catch(function (error) {
                dispatch({
                    type: RESET_FAIL,
                    payload: error?.response?.data,
                })
            })
    }
