import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import PersonIcon from '@mui/icons-material/Person'
import { useNavigate } from 'react-router'

export default function ProfileMenu(props: any) {
    const { onClose = () => {}, className = '' } = props
    let navigate = useNavigate()
    return (
        <List>
            <ListItem
                disablePadding
                className={className}
                onClick={() => {
                    navigate('/profile')
                    onClose()
                }}
            >
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>
                <ListItemText>My Profile</ListItemText>
            </ListItem>
            <ListItem
                disablePadding
                className={className}
                onClick={() => {
                    navigate('/profile/change-password')
                    onClose()
                }}
            >
                <ListItemIcon>
                    <LockIcon />
                </ListItemIcon>
                <ListItemText>Change Password</ListItemText>
            </ListItem>
        </List>
    )
}
