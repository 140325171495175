import { useEffect, useState, ChangeEvent, SyntheticEvent } from 'react'
import { Container, FormControl, OutlinedInput } from '@mui/material'
import { Box } from '@mui/system'
import ProfileImage from '../components/dashboard/profile-image.component'
import { getCurrentUser } from '../services/auth.service'
import store, { RootState } from '../store'
import { ProfileAction } from '../actions/account.action'
import { useSelector } from 'react-redux'
import { ProfileState } from '../reducers/profile.reducer'
import Notification from '../components/notification.components'
import { PROFILE_UPDATE_RESET } from '../constants/user.constants'
import NewUserProfile from '../components/dashboard/new-user-profile.component'
import UserProfile from '../components/dashboard/user-profile.component'
import { UserState } from '../reducers/user.reducer'

const isCreateUserUrl = () => {
    const currentPath = window.location.pathname
    if (!currentPath.includes('new')) return false
    return true
}

type Payload = {
    name: string
    email: string
    phone_number?: string
    address?: string
    gender: number
    is_customer?: boolean
    role?: string
    photo?: any
}

const ProfileScreen = (props: any) => {
    const isDesktop = props.isDesktop
    const isCreateUser = isCreateUserUrl() || false
    const currentUser = getCurrentUser() || {}
    const profile = useSelector<RootState, ProfileState>(
        (state) => state.profile
    )
    const user = useSelector<RootState, UserState>((state) => state.userLogin)

    const [personalInfo, setPersonalInfo] = useState<Payload>({
        name: currentUser.name,
        email: currentUser.email,
        phone_number: currentUser.phone_number,
        address: currentUser.address,
        gender: currentUser.gender,
        photo: null,
    })

    const defaultErrorState = {
        name: false,
        email: false,
        phone_number: false,
        address: false,
        gender: false,
    }
    const [errors, setErrors] = useState(defaultErrorState)
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        props.getScreenTitle(isCreateUser ? 'Create New User' : 'My Profile')
    }, []) // eslint-disable-line

    useEffect(() => {
        if (isCreateUser) {
            setPersonalInfo({
                name: '',
                email: '',
                phone_number: '',
                address: '',
                is_customer: true,
                gender: 0,
            })
        }
    }, [isCreateUser])

    const onChange =
        (key: any, customValue?: any) =>
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = customValue ?? event.target.value
            const data = typeof key === 'object' ? key : { [key]: value }

            setPersonalInfo((prevState) => ({
                ...prevState,
                ...data,
            }))
        }

    const validate = () => {
        let payload = [
            {
                key: 'name',
                isValid: personalInfo.name !== '',
            },
            {
                key: 'email',
                isValid: personalInfo.email !== '',
            },
            {
                key: 'gender',
                isValid: personalInfo.gender > 0,
            },
        ]

        return {
            data: payload,
            isValid: payload.every((data) => data.isValid),
        }
    }

    const newUserValidate = (payload: Payload) => {
        let dataFields = [
            {
                key: 'name',
                isValid: personalInfo.name !== '',
            },
            {
                key: 'email',
                isValid: personalInfo.email !== '',
            },
            {
                key: 'gender',
                isValid: personalInfo.gender > 0,
            },
        ]

        return {
            data: dataFields,
            isValid: dataFields.every((data) => data.isValid),
        }
    }

    const handleSubmit = async (event: SyntheticEvent) => {
        event.preventDefault()

        const validation = isCreateUser
            ? newUserValidate(personalInfo)
            : validate()

        if (!validation.isValid) {
            setErrors(
                validation.data.reduce((prevField, field) => {
                    return { ...prevField, [field.key]: !field.isValid }
                }, defaultErrorState)
            )
            return
        }
        if (isCreateUser) {
            store.dispatch(ProfileAction.new(personalInfo))
        } else {
            store.dispatch(ProfileAction.update(currentUser.id, personalInfo))
        }
    }

    const valueFormatter = (
        value: string,
        key: string,
        placeholder: string,
        error: boolean,
        isEdit: boolean
    ) => {
        return isEdit || isCreateUser ? (
            <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                error={error}
            >
                <OutlinedInput
                    placeholder={placeholder}
                    value={value ?? ''}
                    onChange={onChange(key)}
                />
            </FormControl>
        ) : (
            <Box
                sx={{
                    color: '#212121',
                    fontSize: '17px',
                    fontWeight: 'bolder',
                    mt: '5px',
                }}
            >
                {value}
            </Box>
        )
    }

    if (profile.showNotification && !profile.hasError) {
        setTimeout(() => {
            document.location.reload()
        }, 1000)
    }

    return (
        <Container className="container">
            <Box
                sx={{
                    display: isDesktop ? 'flex' : 'block',
                    justifyContent: 'space-between',
                }}
            >
                {isCreateUser ? (
                    <>
                        <ProfileImage
                            user={{
                                info: {
                                    name: 'Full Name',
                                    email: 'youremail@company.com',
                                },
                            }}
                            isDesktop={isDesktop}
                        />
                        <NewUserProfile
                            isDesktop={isDesktop}
                            handleSubmit={handleSubmit}
                            onChange={onChange}
                            personalInfo={personalInfo}
                            error={errors}
                            loading={profile.loading}
                        />
                    </>
                ) : (
                    <>
                        <ProfileImage
                            user={user}
                            isDesktop={isDesktop}
                            isEdit={isEdit}
                            onChange={onChange}
                        />
                        <UserProfile
                            isDesktop={isDesktop}
                            handleSubmit={handleSubmit}
                            valueFormatter={valueFormatter}
                            personalInfo={personalInfo}
                            errors={errors}
                            isEdit={isEdit}
                            setIsEdit={setIsEdit}
                        />
                    </>
                )}
            </Box>
            <Notification
                message={profile.message}
                open={profile.showNotification}
                severity={profile.hasError ? 'error' : 'success'}
                onClose={() => {
                    store.dispatch({
                        type: PROFILE_UPDATE_RESET,
                    })
                }}
            />
        </Container>
    )
}

export default ProfileScreen
