import React, { lazy, useEffect, useState } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'

import store, { RootState } from './store'
import { UserState } from './reducers/user.reducer'
import DashboardScreen from './screens/dashboard.screen'
import ProtectedRoute, { ProtectedRouteProps } from './routes/protected'
import { useSelector } from 'react-redux'
import { getToken, setAuth } from './services/auth.service'
import RegisterScreen from './screens/register.screen'
import './App.scss'
import NewOrderScreen from './screens/new-order.screen'
import OrdersScreen from './screens/orders.screen'
import SettingsScreen from './screens/settings.screen'
import { getUserInfo, getVipDetails } from './actions/auth.action'
import { ScreenState } from './reducers/screen.reducer'
import Notification from './components/notification.components'
import { OPTIONS_RESET } from './constants/screen.constants'
import TransactionHistoryScreen from './screens/transaction-history.screen'
import Profile from './screens/profile.screen'
import ProfileChangePassword from './screens/profile-change-password.screen'
import FiltersScreen from './screens/filters.screen'
import SupportScreen from './screens/support.screen'
import RecoveryScreen from './screens/recovery.screen'
import ResetScreen from './screens/reset.screen'
import DepositsScreen from './screens/deposits.screen'
import ForgotPasswordConfirmation from './screens/forgot-password-confirmation.screen'
import { TYPE_WORKER } from './types/user.types'
import LoginRecordsScreen from './screens/login-records.screen'

const LoginScreen = lazy(() => import('./screens/login.screen'))

const App = () => {
    const auth = useSelector<RootState, UserState>((state) => state.userLogin)
    const screen = useSelector<RootState, ScreenState>((state) => state.screen)
    const isAuthenticated = getToken() !== null
    const [isWorker,setIsWorker] = useState(false);

    const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'Outlet'> = {
        isAuthenticated,
        authenticationPath: '/login',
    }

    const queryParams = new URLSearchParams(window.location.search)
    const vipToken = queryParams.get('vip')

    useEffect(() => {
        const isReset = window.location.pathname.includes('/reset-password')
        const uriToken = queryParams.get('token')
        if (uriToken && !isReset) {
            setAuth(uriToken)
        }

        if(auth.info) {
          const roles = auth?.info?.roles ?? []
          if (roles.includes(TYPE_WORKER)) setIsWorker(true)
        }

        setTimeout(() => {
            if (
                !auth.loading &&
                !auth.hasError &&
                ((auth.info == null && isAuthenticated) ||
                    (getToken() != null && auth.isAuth === false))
            ) {
                store.dispatch(getUserInfo())
            }
        })
    }, [auth, isAuthenticated]) // eslint-disable-line

    useEffect(() => {
        if (vipToken && !isAuthenticated) {
            store.dispatch(getVipDetails(vipToken ?? ''))
        }
    }, [vipToken, isAuthenticated])

    const routes = [
        {
            protected: true,
            path: '/',
            component: DashboardScreen,
        },
        {
            protected: true,
            path: '/orders',
            component: OrdersScreen,
        },
        {
            protected: true,
            path: '/orders/new',
            component: NewOrderScreen,
        },
        {
            protected: true,
            path: '/filters',
            component: FiltersScreen,
        },
        {
            protected: true,
            path: '/deposits',
            component: DepositsScreen,
        },
        {
            protected: true,
            path: '/settings/:type',
            component: SettingsScreen,
        },
        {
            protected: true,
            path: '/support',
            component: SupportScreen,
        },
        {
            protected: true,
            path: '/transactions',
            component: TransactionHistoryScreen,
        },
        {
            protected: true,
            path: '/login-records/:id',
            component: LoginRecordsScreen,
        },
        {
            protected: true,
            path: '/profile',
            component: Profile,
        },
        {
            protected: true,
            path: '/profile/new',
            component: Profile,
        },
        {
            protected: true,
            path: '/profile/change-password',
            component: ProfileChangePassword,
        },
        {
            path: '/login',
            component: <LoginScreen />,
        },
        {
            path: '/register',
            component: <RegisterScreen />,
        },
        {
            path: '/account-recovery',
            component: <RecoveryScreen />,
        },
        {
            path: '/account-recovery-confirm',
            component: <ForgotPasswordConfirmation />,
        },
        {
            path: '/reset-password',
            component: <ResetScreen />,
        },
    ]


    return (
        <React.Fragment>
            <Router>
                <Routes>
                    {routes.map((route, index) => {
                        return (
                          (isWorker && route.path === '/' ) ? 
                            <Route 
                              key={`route-${index}`}
                              path={route.path}
                              element={
                                <Navigate to="/orders" />
                              }
                            />
                          :
                            <Route
                                key={`route-${index}`}
                                path={route.path}
                                element={
                                    route.protected ? (
                                        <ProtectedRoute
                                            Outlet={route.component}
                                            {...defaultProtectedRouteProps}
                                        />
                                    ) : (
                                        <>{route.component}</>
                                    )
                                }
                            />
                        )
                    })}
                </Routes>
            </Router>
            <Notification
                open={screen.showNotification}
                severity="error"
                message="There is something wrong with your current session. Please logout and log back in."
                onClose={() =>
                    store.dispatch({
                        type: OPTIONS_RESET,
                    })
                }
            />
        </React.Fragment>
    )
}

export default App
