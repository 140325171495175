import {
    IPINFO_FAIL,
    IPINFO_REQUEST,
    IPINFO_SUCCESS,
} from '../constants/ipinfo.constants'
import { IpInfoService } from '../services/ipinfo.service'
import { AppThunk } from '../store'

export const IpInfoAction = {
    getIpInfo: (): AppThunk => async (dispatch) => {
        dispatch({
            type: IPINFO_REQUEST,
        })

        await IpInfoService.getIpInfo()
            .then((response: any) => {
                let responseData = response?.data

                if (responseData) {
                    return dispatch({
                        type: IPINFO_SUCCESS,
                        payload: responseData,
                    })
                }

                return response?.data
            })
            .catch(function (error) {
                dispatch({
                    type: IPINFO_FAIL,
                    payload: error?.response?.data,
                })
            })
    },
    getInfoByIpAddress:
        (ipAddress: string): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: IPINFO_REQUEST,
            })

            await IpInfoService.getInfoByIpAddress(ipAddress)
                .then((response: any) => {
                    let responseData = response?.data

                    if (responseData) {
                        return dispatch({
                            type: IPINFO_SUCCESS,
                            payload: responseData,
                        })
                    }
                    return response?.data
                })
                .catch(function (error) {
                    dispatch({
                        type: IPINFO_FAIL,
                        payload: error?.response?.data,
                    })
                })
        },
}
