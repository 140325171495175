import {
  FILTERS_DELETE_FAIL,
  FILTERS_DELETE_SUCCESS,
  FILTERS_EDIT_FAIL,
  FILTERS_EDIT_SUCCESS,
  FILTERS_FAIL,
  FILTERS_GET_FAIL,
  FILTERS_GET_SUCCESS,
  FILTERS_REQUEST,
  FILTERS_RESET,
  FILTERS_SUCCESS,
} from "../constants/filters.constants";

type DataFilters = {
  message?: string | null;
  list: {
    data: Array<object>;
    meta: object | null;
  };
};

export interface FiltersState {
  loading?: boolean;
  showNotification: boolean;
  hasError?: boolean;
  data: DataFilters;
  filters: any;
}

interface Action {
  type: string;
  payload?: any;
}

export const filtersReducer = (
  state: FiltersState = {
    loading: false,
    showNotification: false,
    hasError: false,
    filters: null,
    data: {
      message: null,
      list: {
        data: [],
        meta: null,
      },
    },
  },
  action: Action
) => {
  switch (action.type) {
    case FILTERS_REQUEST:
      return {
        loading: true,
        showNotification: false,
        hasError: false,
        filters: state.filters,
        data: {
          message: null,
          list: state.data.list,
        },
      };
    case FILTERS_SUCCESS:
      return {
        loading: false,
        showNotification: true,
        hasError: false,
        filters: action.payload,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            data: [...state.data.list.data, action.payload],
          },
          message: "Filter Successfully Added.",
        },
      };
    case FILTERS_FAIL:
      return {
        loading: false,
        showNotification: true,
        hasError: true,
        filters: state.filters,
        data: { ...state.data, message: action.payload?.message },
      };
    case FILTERS_GET_SUCCESS:
      return {
        loading: false,
        showNotification: false,
        hasError: false,
        filters: action.payload,
        data: { list: action.payload },
      };
    case FILTERS_GET_FAIL:
      return {
        loading: false,
        showNotification: true,
        hasError: true,
        filters: state.filters,
        data: { ...state.data, message: action.payload?.message },
      };
    case FILTERS_EDIT_SUCCESS:
      return {
        loading: false,
        showNotification: true,
        hasError: false,
        filters: action.payload,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            data: state.data.list.data.map((filter: any) =>
              filter.id === (action.payload?.id ?? 0) ? action.payload : filter
            ),
          },
          message: "Filter Successfully Updated.",
        },
      };
    case FILTERS_EDIT_FAIL:
      return {
        loading: false,
        showNotification: true,
        hasError: true,
        filters: state.filters,
        data: { ...state.data, message: action.payload?.message },
      };
    case FILTERS_RESET:
      return {
        loading: false,
        data: state.data,
        showNotification: false,
        filters: state.filters,
        hasError: false,
      };
    case FILTERS_DELETE_SUCCESS:
      const filterIndex = state.data.list.data.findIndex((filter: any) => {
        return filter.id === action.payload;
      });
      return {
        loading: false,
        showNotification: true,
        hasError: false,
        filters: state.filters,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            data: state.data.list.data.filter(
              (item, index) => index !== filterIndex
            ),
          },
          message: "Filter Successfully Deleted.",
        },
      };
    case FILTERS_DELETE_FAIL:
      return {
        loading: false,
        showNotification: true,
        hasError: true,
        filters: state.filters,
        data: { ...state.data, message: action.payload?.message },
      };
    default:
      return state;
  }
};
