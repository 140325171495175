import { format } from 'react-string-format'
import API_INSTANCE from './api'
import endpoints from './endpoints'

export const CustomerService = {
    index: async (
        page: number = 1,
        search: string | null = null,
        sort: string | null = null
    ) => {
        let searchStr = search ? '&search=' + search : ''
        let sortStr = sort ? '&sort_by=' + sort : ''

        return API_INSTANCE.get(
            endpoints.GET_USERS +
                '?per_page=15&page=' +
                page +
                searchStr +
                sortStr
        )
    },
    banUserIp: async (userId: number) => {
        return API_INSTANCE.put(format(endpoints.BAN_USER_IP, userId))
    },
    banUserAccount: async (userId: number) => {
        return API_INSTANCE.put(format(endpoints.BAN_USER_ACCOUNT, userId))
    },
    pauseUserAccount: async (userId: number) => {
        return API_INSTANCE.put(format(endpoints.PAUSE_USER_ACCOUNT, userId))
    },
    activateAccount: async (userId: number) => {
        return API_INSTANCE.put(format(endpoints.ACTIVATE_ACCOUNT, userId))
    },
}
