import { Button, CircularProgress } from '@mui/material'

export default function CustomButton(props: any) {
    const { sx, isLoading, label, ...rest } = props
    return (
        <Button
            sx={{
                '&.upviews-button': {
                    bgcolor: '#27A857',
                    borderRadius: '40px',
                    fontWeight: 500,
                    fontSize: '18px',
                    boxShadow: 'none',
                    padding: '10px 20px',
                    color: '#fff',
                    textTransform: 'capitalize',
                },
                '&.upviews-button-outline': {
                    bgcolor: '#fff',
                    borderRadius: '40px',
                    fontWeight: 500,
                    fontSize: '18px',
                    boxShadow: 'none',
                    padding: '10px 20px',
                    color: '#27A857',
                    border: '1px solid #27A857',
                    textTransform: 'capitalize',
                },
                '&.Mui-disabled.upviews-button': {
                    bgcolor: 'rgba(0, 0, 0, 0.12)',
                },
                ...sx,
            }}
            variant="contained"
            disabled={isLoading}
            {...rest}
        >
            {isLoading ? (
                <CircularProgress
                    sx={{
                        width: '15px!important',
                        height: '15px!important',
                        mr: '5px!important',
                    }}
                />
            ) : null}
            {label || ''}
        </Button>
    )
}
