import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router'
import { Modal } from '@mui/material'
import { useTour } from '@reactour/tour'
import NorthWestIcon from '@mui/icons-material/NorthWest'

import FundsComponent from '../components/dashboard/customer/funds.component'
import LeftSideBar from '../components/dashboard/left-sidebar.component'
import NavBar from '../components/dashboard/navbar.component'
import RightSideBar from '../components/dashboard/right-sidebar.component'
import { UserState } from '../reducers/user.reducer'
import { WalletState } from '../reducers/wallet.reducer'
import store, { RootState } from '../store'
import { IsDesktopScreen, IsSmallestScreen } from '../helpers/utils.helper'
import { TransactionState } from '../reducers/transaction.reducer'
import DepositFund from '../components/dashboard/deposit-fund.component'
import DepositSuccessModal from '../components/dashboard/deposit-success-modal.component'
import { TRANSACTION_RESET } from '../constants/transaction.constants'
import { WalletAction } from '../actions/account.action'
import { getOptions } from '../actions/screen.action'
import { ScreenState } from '../reducers/screen.reducer'
import { FileAction } from '../actions/file.action'
import CustomButton from '../components/customComponents/custom-button.component'
import { OrderState } from '../reducers/orders.reducer'
import TourGuide from '../assets/images/tour-guide.png'
import TourGuide1 from '../assets/images/tour-guide-1.png'
import TourGuide2 from '../assets/images/tour-guide-2.png'
import TourGuide3 from '../assets/images/tour-guide-3.png'
import TourGuide4 from '../assets/images/tour-guide-4.png'
import TourGuide1Girl from '../assets/images/tour-guide-1-girl.png'
import TourGuide2Girl from '../assets/images/tour-guide-2-girl.png'

export type ProtectedRouteProps = {
    isAuthenticated: boolean
    authenticationPath: string
    Outlet: Function
}

export type RightNavProps = {
    title?: string
    content?: JSX.Element
    parentCustomStyle?: object
}

export default function ProtectedRoute({
    isAuthenticated,
    authenticationPath,
    Outlet,
}: ProtectedRouteProps) {
    const wallet = useSelector<RootState, WalletState>((state) => state.wallet)
    const orders = useSelector<RootState, OrderState>((state) => state.order)
    const transaction = useSelector<RootState, TransactionState>(
        (state) => state.transaction
    )
    const screen = useSelector<RootState, ScreenState>((state) => state.screen)
    const user = useSelector<RootState, UserState>((state) => state.userLogin)
    const [isRightSideBarOpen, setIsRightSideBarOpen] = useState(false)
    const [rightNav, setRightNav] = useState<null | RightNavProps>(null)
    const [screenTitle, setScreenTitle] = useState('')
    const [openTrxModal, setOpenTrxModal] = useState(false)
    const [isOpenTourGuide, setIsOpenTourGuide] = useState(false)
    const [isTourGuideDone, setIsTourGuideDone] = useState(false)

    const {
        isOpen: isTourOpen,
        setIsOpen,
        setCurrentStep,
        setSteps,
    } = useTour()

    const navigate = useNavigate()

    const roles = user?.info?.roles ?? []
    const isCustomer = user?.info?.roles && roles.length === 0
    const isDesktop = IsDesktopScreen()
    const isLoading = user?.loading
    const isSmallestScreen = IsSmallestScreen()

    useEffect(() => {
        if (isCustomer) {
            setDefaultRightNav()
        }
        getProtectedResources(isCustomer, screen, wallet, user)
    }, [isCustomer, wallet, user, screen]) // eslint-disable-line

    if (!isDesktop && !window.location.pathname.includes('/support')) {
        ;(window as any).tidioChatApi?.hide()
    } else {
        ;(window as any).tidioChatApi?.show()
    }

    useEffect(() => {
        const isTourDone = !!localStorage.getItem('tour')
        if (
            !isTourDone &&
            orders?.data?.list?.data.length === 0 &&
            !isTourOpen &&
            isCustomer
        ) {
            setIsOpenTourGuide(true)
        }
    }, [orders, isTourOpen, isCustomer])

    const getProtectedResources = (
        isCustomer: boolean,
        screen: ScreenState,
        wallet: WalletState,
        user: UserState
    ) => {
        if (user.loading || user.info === null) {
            return
        }

        !screen.hasError &&
            !screen.loading &&
            screen.options === null &&
            store.dispatch(getOptions())

        isCustomer &&
            wallet.balance == null &&
            !wallet.loading &&
            store.dispatch(WalletAction.get())

        user.info?.profile_photo &&
            !user.photo &&
            store.dispatch(FileAction.viewByUrl(user.info.profile_photo))
    }

    const setDefaultRightNav = () => {
        handleSetRightNav({
            title: 'Your Funds',
            content: (
                <FundsComponent
                    showRightSideBar={showRightSideBar}
                    getRightNav={handleSetRightNav}
                    isRightSideBarOpen={isRightSideBarOpen}
                    isDesktop={isDesktop}
                    wallet={wallet}
                    transaction={transaction}
                    openTrxModal={setOpenTrxModal}
                />
            ),
        })
    }

    const handleSetRightNav = (rightNav: RightNavProps) => {
        setRightNav(rightNav)
    }

    const showRightSideBar = () => {
        setIsRightSideBarOpen(!isRightSideBarOpen)
        if (isRightSideBarOpen) {
            setDefaultRightNav()
        }
    }

    const handleTour = (isDone = false) => {
        if (isDone) {
            localStorage.setItem('tour', 'done')
            setOpenTrxModal(false)
            setIsOpen(false)
            setIsOpenTourGuide(false)
            return
        }

        setIsOpen(true)
        setIsOpenTourGuide(false)
        setCurrentStep(0)

        if (setSteps) {
            setSteps([
                {
                    selector: '.tour-campaign-0',
                    content: (
                        <TourCreator
                            guide={
                                isSmallestScreen ? TourGuide1Girl : TourGuide1
                            }
                            guidStyle={{
                                left: isSmallestScreen ? '-20px' : '-14px',
                                top: isSmallestScreen ? '-32px' : '-50px',
                            }}
                            guidParentWidth={isSmallestScreen ? '60%' : '40%'}
                            instructions={
                                isSmallestScreen
                                    ? 'First visit the order page to start your campaign!'
                                    : 'First, Go to your side panel menu and hit Start Campaign.'
                            }
                            instructionsParentWidth={
                                isSmallestScreen ? '40%' : '60%'
                            }
                            textAlignment="right"
                            onSkipTour={() => {
                                handleTour(true)
                                setIsTourGuideDone(true)
                            }}
                        />
                    ),
                    actionAfter: () => {
                        const firstTour = document.querySelector(
                            '.tour-campaign-0'
                        ) as HTMLElement

                        if (firstTour) {
                            firstTour.click()
                            window.scrollTo(0, 0)
                        }
                    },
                },
                {
                    selector: '.tour-campaign-1',
                    content: (
                        <TourCreator
                            guide={
                                isSmallestScreen ? TourGuide2Girl : TourGuide2
                            }
                            guidStyle={{
                                left: isSmallestScreen ? '-12px' : '-43px',
                                top: isSmallestScreen ? '5px' : '-22px',
                            }}
                            textAlignment="right"
                            guidParentWidth={isSmallestScreen ? '50%' : '30%'}
                            instructions="Now, you can select the type of service you need! Views, Likes, Subscribers & More!"
                            instructionsParentWidth={
                                isSmallestScreen ? '50%' : '70%'
                            }
                            onSkipTour={() => {
                                handleTour(true)
                                setIsTourGuideDone(true)
                            }}
                        />
                    ),
                },
                {
                    selector: '.tour-campaign-2',
                    content: (
                        <TourCreator
                            guide={
                                isSmallestScreen ? TourGuide2Girl : TourGuide3
                            }
                            guidStyle={{
                                top: isSmallestScreen ? '5px' : '-88px',
                                left: isSmallestScreen ? '-0px' : 'unset',
                            }}
                            guidParentWidth="50%"
                            instructions={
                                <>
                                    {!isSmallestScreen && (
                                        <NorthWestIcon className="arrow-left" />
                                    )}
                                    Learn more about the differences between all
                                    of our exclusive services here at UpViews.
                                </>
                            }
                            instructionsParentWidth="50%"
                            isReverse={true}
                            onSkipTour={() => {
                                handleTour(true)
                                setIsTourGuideDone(true)
                            }}
                        />
                    ),
                },
                {
                    selector: '.tour-campaign-3',
                    content: (
                        <TourCreator
                            guide={
                                isSmallestScreen ? TourGuide1Girl : TourGuide2
                            }
                            guidStyle={{
                                left: isSmallestScreen ? '-20px' : '-43px',
                                top: isSmallestScreen ? '57px' : '-22px',
                            }}
                            guidParentWidth="30%"
                            instructions={
                                <>
                                    Select the service type from High-quality,
                                    Premium and Targeted Services, and then
                                    enter the amount you need, <br /> it will
                                    show an estimated <br />
                                    price automatically based <br />
                                    on the quantity <br />
                                    you key in.
                                </>
                            }
                            instructionsParentWidth="70%"
                            textAlignment="right"
                            onSkipTour={() => {
                                handleTour(true)
                                setIsTourGuideDone(true)
                            }}
                        />
                    ),
                    actionAfter: () => {
                        showRightSideBar()
                        handleSetRightNav({
                            title: 'Deposit Funds',
                            content: (
                                <DepositFund
                                    onClose={showRightSideBar}
                                    isDesktop={isDesktop}
                                    transaction={transaction}
                                />
                            ),
                        })
                    },
                },
                {
                    selector: '.tour-campaign-4',
                    content: (
                        <TourCreator
                            guide={
                                isSmallestScreen ? TourGuide2Girl : TourGuide4
                            }
                            guidStyle={{
                                top: isSmallestScreen ? '27px' : '-24px',
                                left: isSmallestScreen ? '-40px' : 'unset',
                            }}
                            guidParentWidth={isSmallestScreen ? '40%' : '30%'}
                            instructions={
                                <>
                                    In this section you can see your current
                                    funds and also you can add a deposit to your
                                    wallet just <br />
                                    hit the button
                                    <br /> to get started!
                                </>
                            }
                            instructionsParentWidth="70%"
                            isReverse={true}
                            onSkipTour={() => {
                                handleTour(true)
                                setIsTourGuideDone(true)
                            }}
                        />
                    ),
                    actionAfter: () => {
                        const depositModalComponent = document.querySelector(
                            '.deposit-modal'
                        ) as HTMLElement
                        if (depositModalComponent) {
                            depositModalComponent.style.visibility = 'visible'
                        }
                    },
                },
                {
                    selector: '.tour-campaign-5',
                    content: (
                        <TourCreator
                            guide={
                                isSmallestScreen ? TourGuide2Girl : TourGuide1
                            }
                            guidStyle={{
                                left: '-14px',
                                top: isSmallestScreen ? '-39px' : '-50px',
                            }}
                            guidParentWidth={isSmallestScreen ? '50%' : '40%'}
                            instructions="You can securely deposit your funds via Credit/Debit."
                            instructionsParentWidth={
                                isSmallestScreen ? '50%' : '60%'
                            }
                            onSkipTour={() => {
                                handleTour(true)
                                setIsTourGuideDone(true)
                            }}
                        />
                    ),
                    actionAfter: () => {
                        handleTour(true)
                        setIsTourGuideDone(true)
                    },
                },
            ])
        }
    }

    if (!isAuthenticated) {
        return <Navigate to={{ pathname: authenticationPath }} />
    }

    return (
        <Box display="flex">
            <LeftSideBar
                isLoading={isLoading}
                isDesktop={isDesktop}
                isRightSideBarOpen={isRightSideBarOpen}
                showRightSideBar={showRightSideBar}
                getRightNav={handleSetRightNav}
                transaction={transaction}
                wallet={wallet}
            />
            <Box
                className={`container-wrapper`}
                pb={{ xs: '100px', md: '50px' }}
                sx={{
                    '.spacer': {
                        mt: '10px!important',
                    },
                }}
            >
                <main className="content">
                    <NavBar
                        isLoading={isLoading}
                        isDesktop={isDesktop}
                        showRightSideBar={showRightSideBar}
                        customClassName="dashboard-nav"
                        title={screenTitle}
                        wallet={wallet}
                        transaction={transaction}
                        user={user}
                    />
                    <div className="spacer" />
                    <Outlet
                        isDesktop={isDesktop}
                        isLoading={isLoading}
                        wallet={wallet}
                        getRightNav={handleSetRightNav}
                        showRightSideBar={showRightSideBar}
                        getScreenTitle={setScreenTitle}
                        openTrxModal={setOpenTrxModal}
                    />
                </main>
            </Box>
            <RightSideBar
                isDesktop={isDesktop}
                isOpen={isRightSideBarOpen}
                rightNav={rightNav}
                showRightSideBar={showRightSideBar}
                isRightSideBarOpen={isRightSideBarOpen}
            />
            {isCustomer && (
                <Modal open={isOpenTourGuide || isTourGuideDone}>
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: isSmallestScreen ? '0px 20px' : 'unset',
                            '.tour-guide': {
                                bgcolor: '#fff',
                                borderTopLeftRadius: '15px',
                                borderTopRightRadius: '15px',
                                width: '450px',
                                height: isSmallestScreen ? '260px' : '370px',
                                position: 'relative',
                                img: {
                                    position: 'absolute',
                                    bottom: '0',
                                    left: isSmallestScreen ? '40px' : '25px',
                                    width: isSmallestScreen ? '80%' : '90%',
                                },
                                '.description': {
                                    zIndex: 1,
                                    position: 'absolute',
                                    bottom: 0,
                                    borderTopLeftRadius: '130px',
                                    borderTopRightRadius: '130px',
                                    textAlign: 'center',
                                    color: '#fff',
                                    width: '100%',
                                    padding: '18px 0px 30px 0px',
                                    background:
                                        'linear-gradient(331deg, rgba(255,18,18,1) 26%, rgba(253,187,45,1) 100%)',
                                    h3: {
                                        fontSize: isTourGuideDone
                                            ? '40px'
                                            : isSmallestScreen
                                            ? '18px'
                                            : '22px',
                                        margin: isTourGuideDone
                                            ? '0px'
                                            : isSmallestScreen
                                            ? 'unset'
                                            : '1em',
                                        padding: isSmallestScreen
                                            ? '10px 60px'
                                            : 'unset',
                                    },
                                    h5: {
                                        fontSize: '18px',
                                        margin: '0px',
                                        fontWeight: 'normal',
                                        mb: '16px',
                                    },
                                    div: {
                                        fontSize: '14px',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        mt: '15px',
                                    },
                                },
                            },
                        }}
                    >
                        <div className="tour-guide">
                            <div className="description">
                                <h3>
                                    {isTourGuideDone ? (
                                        `That's it!`
                                    ) : (
                                        <>
                                            Welcome to UpViews, <br />
                                            Get started in under 60 seconds!
                                        </>
                                    )}
                                </h3>
                                {isTourGuideDone && (
                                    <h5>
                                        Now the power is in your hands, <br />{' '}
                                        get the marketing started!
                                    </h5>
                                )}
                                <CustomButton
                                    label="Get Started"
                                    sx={{
                                        padding: '5px 55px!important',
                                    }}
                                    className="upviews-button"
                                    onClick={() => {
                                        if (isTourGuideDone) {
                                            if (!wallet.balance) {
                                                setOpenTrxModal(true)
                                            } else {
                                                navigate('/orders/new')
                                                setIsRightSideBarOpen(false)
                                            }
                                            setIsTourGuideDone(false)
                                            return
                                        }
                                        handleTour()
                                    }}
                                />
                                {!isTourGuideDone && (
                                    <div onClick={() => handleTour(true)}>
                                        No, Thanks I already know what to do
                                    </div>
                                )}
                            </div>
                            <img src={TourGuide} alt="" />
                        </div>
                    </Box>
                </Modal>
            )}
            {isDesktop && (
                <>
                    <Modal
                        open={
                            (openTrxModal && !transaction.showModal) ||
                            !!isTourOpen
                        }
                        className="deposit-modal"
                        sx={{
                            visibility: !!isTourOpen ? 'hidden' : 'visible',
                        }}
                    >
                        <div>
                            <DepositFund
                                onClose={() => setOpenTrxModal(false)}
                                isDesktop={isDesktop}
                                transaction={transaction}
                            />
                        </div>
                    </Modal>

                    <DepositSuccessModal
                        open={transaction.showModal}
                        transaction={transaction}
                        onClose={() => {
                            setOpenTrxModal(false)
                            store.dispatch({
                                type: TRANSACTION_RESET,
                            })
                        }}
                    />
                </>
            )}
        </Box>
    )
}

type TourProps = {
    guide: string
    guidStyle: object
    guidParentWidth: string
    instructions: any
    instructionsParentWidth: string
    isReverse?: boolean
    onSkipTour: Function
    textAlignment?: string
}

const TourCreator = (props: TourProps) => {
    const { currentStep, setCurrentStep } = useTour()
    const isReverse = !!props.isReverse
    const isSmallestScreen = IsSmallestScreen()
    const textAlignment = props.textAlignment ?? 'unset'
    return (
        <Box
            sx={{
                width: isSmallestScreen ? '340px' : 'unset',
                display: 'flex',
                justifyContent: 'space-between',
                position: 'relative',
                '.tour-image': {
                    position: 'relative',
                    width: props.guidParentWidth,
                    img: {
                        ...props.guidStyle,
                        position: 'absolute',
                    },
                },
                '.tour-instructions': {
                    textAlign: textAlignment,
                    padding: isReverse
                        ? '20px 0px 0px 20px'
                        : '20px 20px 0px 0px',
                    width: props.instructionsParentWidth,
                    '.arrow-left': {
                        position: 'absolute',
                        left: '-25px',
                        top: '30px',
                        color: '#27A857',
                    },
                    '> span': {
                        fontWeight: '600',
                    },
                    '.tour-buttons': {
                        display: 'flex',
                        justifyContent: isReverse ? 'left' : 'right',
                        mt: '20px',
                        alignItems: 'center',
                        '&>div': {
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            mr: '20px',
                        },
                    },
                },
            }}
        >
            {!isReverse && (
                <div className="tour-image">
                    <img src={props.guide} alt="" />
                </div>
            )}
            <div className="tour-instructions">
                <span>{props.instructions}</span>
                <div className="tour-buttons">
                    <div onClick={() => props.onSkipTour()}>Skip</div>
                    <CustomButton
                        label="Next"
                        sx={{ padding: '5px 25px!important' }}
                        className="upviews-button"
                        onClick={() => setCurrentStep(currentStep + 1)}
                    />
                </div>
            </div>
            {isReverse && (
                <div className="tour-image">
                    <img src={props.guide} alt="" />
                </div>
            )}
        </Box>
    )
}
