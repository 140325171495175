import { Box, FormControl, Link, OutlinedInput } from '@mui/material';

import CustomButton from './customComponents/custom-button.component';
import { IsSmallestScreen } from '../helpers/utils.helper';

export default function RecoveryForm(props: any) {
  const isSmallestScreen = IsSmallestScreen();

  return (
    <Box component="form" className="login-form" noValidate autoComplete="off" {...props.formProps}
      sx={{
        mt: isSmallestScreen ? '16px!important' : '35px',
        '& input': { fontSize: '14px', padding: '20px 14px 19px 14px' },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'white',
          },
          '&:hover fieldset': {
            borderColor: 'transparent',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'transparent',
          },
        },
        '& .MuiFormControl-root': {
          mt: isSmallestScreen ? '3px!important' : '16px'
        }
      }}
    >
      <FormControl margin="normal" variant="outlined" fullWidth error={props.errors.email}>
        <OutlinedInput fullWidth placeholder='Email' id="email" type="email" onChange={props.onChange('email')} />
      </FormControl>

      <Box mt={isSmallestScreen ? '16px!important' : '33px'} className="login-action">
        <Link href="/login" sx={{ color: "#D93F21", textDecoration: "none", lineHeight: 2.5, paddingRight: "20px" }}>Login</Link>
        <CustomButton
          isLoading={props.loading}
          label='Send Link'
          type="submit"
          sx={{ padding: '8px 55px!important', width: isSmallestScreen ? '100%' : 'unset' }}
          className="upviews-button"
        />
      </Box>
    </Box>
  );
}
