import {
  FILTERS_DELETE_FAIL,
  FILTERS_DELETE_SUCCESS,
  FILTERS_EDIT_FAIL,
  FILTERS_EDIT_SUCCESS,
  FILTERS_FAIL,
  FILTERS_GET_FAIL,
  FILTERS_GET_SUCCESS,
  FILTERS_REQUEST,
  FILTERS_SUCCESS,
} from "../constants/filters.constants";
import { FiltersService } from "../services/filters.service";
import { AppThunk } from "../store";

export const FiltersAction = {
    index:
      (page: number = 1, search: string | null = null, category: string | null = null, type: string | null = null): AppThunk => 
        async(dispatch) => {
          dispatch({
            type: FILTERS_REQUEST
          });

        await  FiltersService.index(page,search, category, type)
          .then((response: any) => {
            let responseData = response?.data;
            if (responseData?.data) {
              return dispatch({
                type: FILTERS_GET_SUCCESS,
                payload: responseData,
              });
            }
            return response?.data;
        })
        .catch(function (error) {
          dispatch({
            type: FILTERS_GET_FAIL,
            payload: error?.response?.data,
          });
        });
    },
  new:
    (payload: any): AppThunk =>
    async (dispatch) => {
      dispatch({
        type: FILTERS_REQUEST,
      });

      await FiltersService.new(payload)
        .then((response: any) => {
          let responseData = response?.data;

          if (responseData?.data) {
            return dispatch({
              type: FILTERS_SUCCESS,
              payload: responseData.data,
            });
          }

          return response?.data;
        })
        .catch(function (error) {
          dispatch({
            type: FILTERS_FAIL,
            payload: error?.response?.data,
          });
        });
    },
  edit:
    (payload: any, filterId: number): AppThunk =>
    async (dispatch) => {
      dispatch({
        type: FILTERS_REQUEST,
      });

      await FiltersService.edit(payload, filterId)
        .then((response: any) => {
          let responseData = response?.data;

          if (responseData?.data) {
            return dispatch({
              type: FILTERS_EDIT_SUCCESS,
              payload: responseData.data,
            });
          }
          return response?.data;
        })
        .catch(function (error) {
          dispatch({
            type: FILTERS_EDIT_FAIL,
            payload: error?.response?.data,
          });
        });
    },
  updateType:
    (filterId: number, fields: any): AppThunk => 
      async (dispatch)=> {
        dispatch({
          type: FILTERS_REQUEST,
      });

      await FiltersService.edit(fields, filterId)
        .then((response: any) => {
          let responseData = response?.data;

          if (responseData.data) {
            return dispatch({
              type: FILTERS_EDIT_SUCCESS,
              payload: responseData.data,
            });
          }
          return response?.data;
        })
        .catch(function (error) {
          dispatch({
            type: FILTERS_EDIT_FAIL,
            payload: error?.response?.data,
          });
        });
    },
  delete:
    (filterId: number): AppThunk => 
      async (dispatch)=> {
        dispatch({
          type: FILTERS_REQUEST,
      });

      await FiltersService.delete(filterId)
        .then((response: any) => {
          let responseData = response?.data;

          if (responseData.data) {
            return dispatch({
              type: FILTERS_DELETE_SUCCESS,
              payload: filterId,
            });
          }
          return response?.data;
        })
        .catch(function (error) {
          dispatch({
            type: FILTERS_DELETE_FAIL,
            payload: error?.response?.data,
          });
        });
    },
};
