export const CREDIT_DEBIT = 1
export const BANK_TRANSFER = 2
export const CRYPTO = 3

export const MANUAL_REVIEW = 'manual_review'

export const PAYMENT_TYPE: any = {
    1: 'Credit/Debit',
    2: 'Bank Transfer',
    3: 'Crypto',
}

export const TRANSACTION_TYPE: any = {
    1: 'Deposit',
    2: 'Withdraw',
}

export const PAYMENT_STATUS: any = {
    confirmed: { label: 'Payment Accepted', color: '#34E692', dark: '#1FBE73' },
    pending: { label: 'Payment Pending', color: '#FF9548', dark: '#EE8B43' },
    invalid: { label: 'Payment Rejected', color: '#FF4848', dark: '#EF4545' },
    cancelled: {
        label: 'Payment Cancelled',
        color: '#FF4848',
        dark: '#EF4545',
    },
    manual_review: {
        label: 'Manual Review',
        color: '#F7684a',
        dark: '#EB6447',
    },
}
