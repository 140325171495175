export const STATUS_PENDING = 'pending'
export const STATUS_INPROGRESS = 'in_progress'
export const STATUS_CANCELLED = 'cancelled'
export const STATUS_COMPLETED = 'completed'
export const STATUS_REFUNDED = 'refunded'

export const VIEW_TYPE_TARGETED = 1
export const VIEW_TYPE_HIGH = 2
export const VIEW_TYPE_PREMIUM = 3
export const LIKE_HIGH_QUALITY = 4
export const LIKE_PREMIUM = 5
export const SUBSCRIBER_HIGH_QUALITY = 6
export const SUBSCRIBER_PREMIUM = 7

export const FOLLOWERS_IG_HIGH = 12
export const FOLLOWERS_IG_PREMIUM = 13
export const LIKES_IG_HIGH = 14
export const LIKES_IG_PREMIUM = 15
export const COMMENTS_IG = 16

export const COMMENTS_HIGH_QUALITY = 17
export const COMMENTS_PREMIUM = 18


export const SERVICE_TYPES = {
    [VIEW_TYPE_TARGETED]: 'Targeted',
    [VIEW_TYPE_HIGH]: 'High Quality',
    [VIEW_TYPE_PREMIUM]: 'Premium',
    [LIKE_HIGH_QUALITY]: 'High Quality Likes',
    [LIKE_PREMIUM]: 'Premium Likes',
    [SUBSCRIBER_HIGH_QUALITY]: 'High Quality Subscribers',
    [SUBSCRIBER_PREMIUM]: 'Premium Subscriber',
    [FOLLOWERS_IG_HIGH]: 'High Quality IG Followers',
    [FOLLOWERS_IG_PREMIUM]: 'Premium IG Followers',
    [LIKES_IG_HIGH]: 'High-Quality IG Likes',
    [LIKES_IG_PREMIUM]: 'Premium IG Likes',
    [COMMENTS_IG]: 'Instagram Comments',
    [COMMENTS_HIGH_QUALITY]: 'High-Quality Comments',
    [COMMENTS_PREMIUM]: 'Premium Comments',
}
