import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Radio,
    RadioGroup,
} from '@mui/material'
import { useState } from 'react'
import { IsSmallestScreen } from '../helpers/utils.helper'
import { GENDERS } from '../types/user.types'
import CustomButton from './customComponents/custom-button.component'

export default function RegisterForm(props: any) {
    const isSmallestScreen = IsSmallestScreen()
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            {...props.formProps}
            sx={{
                mt: isSmallestScreen ? '16px!important' : '35px',
                '& input': { fontSize: '14px', padding: '20px 14px 19px 14px' },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: 'white',
                    },
                    '&:hover fieldset': {
                        borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                    },
                },
                '& .MuiFormControl-root': {
                    mt: isSmallestScreen ? '3px!important' : '16px',
                },
            }}
        >
            <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                error={props.errors.name}
            >
                <OutlinedInput
                    fullWidth
                    placeholder="Full Name"
                    id="name"
                    type="name"
                    onChange={props.onChange('name')}
                    autoComplete="fname"
                    required
                />
            </FormControl>
            <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                error={props.errors.email}
            >
                <OutlinedInput
                    fullWidth
                    placeholder="Email Address"
                    id="email"
                    type="email"
                    onChange={props.onChange('email')}
                    autoComplete="email"
                    required
                />
            </FormControl>

            <FormControl
              margin="normal"
              variant="outlined"
              sx={{ 
                color: '#7E7E7E',
                border: '1px solid #D9D9D9 !important',
                borderRadius: '15px !important',
                background: '#FFFFFF',
                padding: '10px 15px',
                display: 'flex',
                flexDirection: isSmallestScreen ? 'column' : 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                '& label': {
                  marginRight: '10px',
                  '&.Mui-focused': {
                    color: 'rgba(0, 0, 0, 0.6)'
                  }
                },
                
              }}
            >
                <FormLabel id="demo-row-radio-buttons-group-label">
                    Gender
                </FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={props.onChange('gender')}
                >
                    {GENDERS.map((gender, i) => (
                        <FormControlLabel
                            key={`gender` + i}
                            value={gender.name}
                            control={
                                <Radio
                                    value={gender.key}
                                    sx={{ 
                                      color: '#D9D9D9',
                                      '&.Mui-checked': {
                                        color: '#27A857',
                                      },
                                     }}
                                />
                            }
                            label={gender.name}
                        />
                    ))}
                </RadioGroup>
            </FormControl>

            <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                error={props.errors.password}
            >
                <OutlinedInput
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    placeholder="Password"
                    onChange={props.onChange('password')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                disableRipple
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                sx={{ color: '#B8B8B8' }}
                                edge="end"
                            >
                                {showPassword ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>

            <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                error={props.errors.confirmPassword}
            >
                <OutlinedInput
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    id="confirm-password"
                    onChange={props.onChange('confirmPassword')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                disableRipple
                                aria-label="toggle password visibility"
                                onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                }
                                sx={{ color: '#B8B8B8' }}
                                edge="end"
                            >
                                {showConfirmPassword ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>


            <div className="btn-container">
                <CustomButton
                    isLoading={props.loading}
                    label="Sign Up!"
                    type="submit"
                    sx={{
                        padding: '8px 55px!important',
                        width: isSmallestScreen ? '100%' : 'unset',
                    }}
                    className="upviews-button"
                />
            </div>
        </Box>
    )
}
