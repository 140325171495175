import {
    CUSTOMER_ACTIVATE_ACCOUNT_FAIL,
    CUSTOMER_ACTIVATE_ACCOUNT_SUCCESS,
    CUSTOMER_BAN_ACCOUNT_FAIL,
    CUSTOMER_BAN_ACCOUNT_SUCCESS,
    CUSTOMER_BAN_IP_FAIL,
    CUSTOMER_BAN_IP_SUCCESS,
    CUSTOMER_GET_FAIL,
    CUSTOMER_GET_SUCCESS,
    CUSTOMER_PAUSE_ACCOUNT_FAIL,
    CUSTOMER_PAUSE_ACCOUNT_SUCCESS,
    CUSTOMER_REQUEST,
} from '../constants/customer.constants'
import { CustomerService } from '../services/customer.service'
import { AppThunk } from '../store'

export const CustomerAction = {
    index:
        (
            page: number = 1,
            search: string | null = null,
            sort: string | null = null
        ): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: CUSTOMER_REQUEST,
            })

            await CustomerService.index(page, search, sort)
                .then((response: any) => {
                    let responseData = response?.data
                    if (responseData?.data) {
                        return dispatch({
                            type: CUSTOMER_GET_SUCCESS,
                            payload: responseData,
                        })
                    }
                    return response?.data
                })
                .catch(function (error) {
                    dispatch({
                        type: CUSTOMER_GET_FAIL,
                        payload: error?.response?.data,
                    })
                })
        },
    banUserIp:
        (userId: number): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: CUSTOMER_REQUEST,
            })

            await CustomerService.banUserIp(userId)
                .then((response: any) => {
                    let responseData = response?.data

                    if (responseData.data) {
                        return dispatch({
                            type: CUSTOMER_BAN_IP_SUCCESS,
                            payload: responseData,
                        })
                    }
                    return response?.data
                })
                .catch(function (error) {
                    dispatch({
                        type: CUSTOMER_BAN_IP_FAIL,
                        payload: error?.response?.data,
                    })
                })
        },
    banUserAccount:
        (userId: number): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: CUSTOMER_REQUEST,
            })

            await CustomerService.banUserAccount(userId)
                .then((response: any) => {
                    let responseData = response?.data

                    if (responseData.data) {
                        return dispatch({
                            type: CUSTOMER_BAN_ACCOUNT_SUCCESS,
                            payload: responseData,
                        })
                    }
                    return response?.data
                })
                .catch(function (error) {
                    dispatch({
                        type: CUSTOMER_BAN_ACCOUNT_FAIL,
                        payload: error?.response?.data,
                    })
                })
        },
    pauseUserAccount:
        (userId: number): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: CUSTOMER_REQUEST,
            })

            await CustomerService.pauseUserAccount(userId)
                .then((response: any) => {
                    let responseData = response?.data

                    if (responseData.data) {
                        return dispatch({
                            type: CUSTOMER_PAUSE_ACCOUNT_SUCCESS,
                            payload: responseData,
                        })
                    }
                    return response?.data
                })
                .catch(function (error) {
                    dispatch({
                        type: CUSTOMER_PAUSE_ACCOUNT_FAIL,
                        payload: error?.response?.data,
                    })
                })
        },
    activateAccount:
        (userId: number): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: CUSTOMER_REQUEST,
            })

            await CustomerService.activateAccount(userId)
                .then((response: any) => {
                    let responseData = response?.data

                    if (responseData.data) {
                        return dispatch({
                            type: CUSTOMER_ACTIVATE_ACCOUNT_SUCCESS,
                            payload: responseData,
                        })
                    }
                    return response?.data
                })
                .catch(function (error) {
                    dispatch({
                        type: CUSTOMER_ACTIVATE_ACCOUNT_FAIL,
                        payload: error?.response?.data,
                    })
                })
        },
}
