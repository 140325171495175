import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    Title,
    CategoryScale,
    LinearScale,
    Filler,
    LineElement,
    PointElement,
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { SERVICE_TYPES } from '../../types/order_status.types'
import RectangularSkeleton from '../skeletons/rectangular.skeleton'

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    Title,
    Filler,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement
)

export default function TypeOfViewsGraph(props: any) {
    const labels = Object.values(SERVICE_TYPES)
    const labelKeys = Object.keys(SERVICE_TYPES)
    let propsData = props.data?.data ?? null

    let dataSet = labelKeys.map(function (key) {
        let serviceType = (propsData?.filter(
            // eslint-disable-next-line eqeqeq
            (item: any) => item.service_type == key
        ) ?? [{ total: 0 }])[0]
        return serviceType?.total ?? 0
    })

    let data = {
        labels: labels,
        datasets: [
            {
                label: ' ',
                data: dataSet,
                backgroundColor: ['#FFBE0A', '#FF3E3E', '#27A857'],
                borderColor: ['#FFBE0A', '#FF3E3E', '#27A857'],
                borderWidth: 1,
            },
        ],
    }

    return (
        <div className="typeof-views-container">
            <div className="header">
                <h2>Sales by Service Type</h2>
            </div>
            {props.isLoading ? (
                <RectangularSkeleton width="100%" height={320} />
            ) : (
                <Doughnut
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'bottom',
                                labels: {
                                    usePointStyle: true,
                                    boxWidth: 6,
                                },
                            },
                        },
                    }}
                    data={data}
                />
            )}
        </div>
    )
}
