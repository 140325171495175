import { Container } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TransactionAction } from '../actions/transaction.action'
import Deposits from '../components/dashboard/admin/deposits.component'
import Notification from '../components/notification.components'
import { TRANSACTION_RESET } from '../constants/transaction.constants'
import { TransactionState } from '../reducers/transaction.reducer'
import store, { RootState } from '../store'
import {
    TRANSACTION_STATUS_CANCELLED,
    TRANSACTION_STATUS_CONFIRMED,
} from '../types/transaction_status.types'

const DepositsScreen = (props: any) => {
    const transaction = useSelector<RootState, TransactionState>(
        (state) => state.transaction
    )

    const queryParams = new URLSearchParams(window.location.search)
    const userId = queryParams.get('userId')

    const [searchTerm, setSearchTerm] = useState(null)
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState('created_at')
    const [filteredStatus, setFilteredStatus] = useState<string | string[]>('')

    const isLoading = props.isLoading || transaction.loading

    useEffect(() => {
        if (transaction.data.list?.meta === null && !transaction.loading) {
            searchTerm !== null &&
                store.dispatch(
                    TransactionAction.index(
                        page,
                        searchTerm,
                        sortBy,
                        userId,
                        filteredStatus
                    )
                )
        }
    })

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            store.dispatch(
                //always enforce to page 1 when searching
                TransactionAction.index(
                    page,
                    searchTerm,
                    sortBy,
                    userId,
                    filteredStatus
                )
            )
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [page, searchTerm, sortBy, userId, filteredStatus])

    useEffect(() => {
        props.getScreenTitle('Transactions')
    }, []) // eslint-disable-line

    const onPageChange = (page: number) => {
        setPage(page)
    }

    const onSort = (value: string) => {
        setSortBy(value)
    }

    const onChangeStatus = (value: string | string[]) => {
        setFilteredStatus(value)
    }

    const approvePayment = (paymentId: any) => {
        store.dispatch(
            TransactionAction.approvePayment(paymentId, {
                status: TRANSACTION_STATUS_CONFIRMED,
            })
        )
    }
    const cancelPayment = (paymentId: any) => {
        store.dispatch(
            TransactionAction.cancelPayment(paymentId, {
                status: TRANSACTION_STATUS_CANCELLED,
            })
        )
    }

    return (
        <>
            <Container className="container">
                <Deposits
                    list={transaction?.data?.list?.data ?? []}
                    onPageChange={onPageChange}
                    meta={transaction?.data?.list?.meta ?? { last_page: 0 }}
                    onSearch={setSearchTerm}
                    page={page}
                    onSort={onSort}
                    approvePayment={approvePayment}
                    cancelPayment={cancelPayment}
                    isLoading={isLoading}
                    isDesktop={props.isDesktop}
                    onChangeStatus={onChangeStatus}
                />
            </Container>
            <Notification
                message={transaction.data?.message ?? 'An error has occurred.'}
                open={transaction.showNotification}
                severity={transaction.hasError ? 'error' : 'success'}
                onClose={() =>
                    store.dispatch({
                        type: TRANSACTION_RESET,
                    })
                }
            />
        </>
    )
}

export default DepositsScreen
