import React from 'react'
import TableWrapper from '../../wrappers/table-wrapper.component'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import AddApiNav from './add-api-nav.component'
import EditApiNav from './edit-api-nav.component'
import CustomButton from '../../customComponents/custom-button.component'
import { IsSmallestScreen } from '../../../helpers/utils.helper'

const SettingsApi = (props: any) => {
    const { onSearch, list, meta, onPageChange, isLoading, isDesktop } = props
    const { current_page, total, last_page = 0, from = 0, to = 0 } = meta || {}

    const headers = [
        {
            key: 'id',
            label: 'ID',
            isVisible: true,
        },
        { key: 'name', label: 'API Name' },
        { key: 'api_key', label: 'API Key' },
        {
            key: 'edit',
            label: '',
            customValue: (row: any) => (
                <Tooltip title="Edit API" placement="top" arrow>
                    <EditRoundedIcon
                        onClick={() => editApi(row)}
                        sx={{
                            borderRadius: '50%',
                            minWidth: 'unset',
                            width: '23px',
                            height: '23px',
                            padding: '4px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#BABEC8',
                            color: 'white',
                            cursor: 'pointer',
                            '&:hover': {
                                bgcolor: '#F7684A',
                            },
                        }}
                    />
                </Tooltip>
            ),
            customRowStyle: { width: 50 },
        },
    ]

    const editApi = (apiDetails: any) => {
        props.getRightNav({
            title: 'Edit Api',
            content: <EditApiNav apiDetails={apiDetails} />,
        })
        props.showRightSideBar()
    }

    const addApi = () => {
        props.getRightNav({
            title: 'Add Api',
            content: <AddApiNav />,
        })
        props.showRightSideBar()
    }

    return (
        <TableWrapper
            headers={headers}
            body={list}
            responsiveHeaders={[{ key: 'name', label: 'API Name' }]}
            total={total}
            currentPage={current_page}
            pageCount={last_page}
            pageFrom={from}
            pageTo={to}
            hideFilters={true}
            excludeHeaders={['edit']}
            mobileActionIcon={(row: any) => (
                <EditRoundedIcon
                    onClick={() => editApi(row)}
                    sx={{
                        borderRadius: '50%',
                        minWidth: 'unset',
                        width: '23px',
                        height: '23px',
                        padding: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#BABEC8',
                        color: 'white',
                        cursor: 'pointer',
                        '&:hover': {
                            bgcolor: '#F7684A',
                        },
                    }}
                />
            )}
            additionalActions={
                <>
                    <div />
                    <CustomButton
                        label="Add Api"
                        className="upviews-button"
                        startIcon={<AddIcon />}
                        onClick={addApi}
                        fullWidth={IsSmallestScreen()}
                    />
                </>
            }
            isLoading={isLoading}
            isDesktop={isDesktop}
            hideToolbars={false}
            onSearch={onSearch}
            onPageChange={onPageChange}
        />
    )
}

export default SettingsApi
