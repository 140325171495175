import {
    Box,
    Divider,
    FormControl,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    TextField,
} from '@mui/material'

import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { TransactionState } from '../../reducers/transaction.reducer'

import CustomButton from '../customComponents/custom-button.component'
import CustomTermsOfUseLinkComponent from '../customComponents/custom-terms-of-use-link-component'

export default function BankTransferForm(props: any) {
    const transaction = useSelector<RootState, TransactionState>(
        (state) => state.transaction
    )

    return (
        <div
            className={`bank-transfer-container${
                props.isDesktop ? ' desktop' : ' mobile'
            }`}
        >
            <h2>Bank Transfer</h2>
            <p className="desc">
                To complete the transaction, make NEFT/ RTGS / IMPS transfer to
            </p>
            <Box className="account-details">
                <List>
                    <ListItem alignItems="flex-start">
                        <ListItemText primary="Account" />
                        <ListItemText secondary="22223333009455678" />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemText primary="IFSC" />
                        <ListItemText secondary="UPVIEWSMSNOIC" />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemText primary="Beneficiary Name" />
                        <ListItemText secondary="John Mac 21" />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemText primary="Amount Expected" />
                        <ListItemText secondary="$20,000" />
                    </ListItem>
                </List>
            </Box>
            <Divider sx={{ marginTop: '27px', marginBottom: '27px' }} />
            <p className="desc">
                Fill out information below to confirm transaction.
            </p>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                {...props.formProps}
            >
                <FormControl fullWidth>
                    <label>Account Name</label>
                    <TextField
                        size="small"
                        hiddenLabel
                        placeholder="Name"
                        name="bank_account_name"
                        error={props?.errors?.data?.bank_account_name ?? ''}
                        onChange={props.onChange('bank_account_name')}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <label>Last 4 Digits of Your Account</label>
                    <TextField
                        size="small"
                        hiddenLabel
                        placeholder="xxxx"
                        inputProps={{ maxLength: 4 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    xxxx xxxx xxxx
                                </InputAdornment>
                            ),
                        }}
                        name="bank_account_id"
                        error={props?.errors?.data?.bank_account_id ?? ''}
                        onChange={props.onChange('bank_account_id')}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <label>Payment Reference ID</label>
                    <TextField
                        size="small"
                        hiddenLabel
                        placeholder="xxxxxxxxxxxxxxxxx"
                        name="reference_id"
                        error={props?.errors?.data?.reference_id ?? ''}
                        onChange={props.onChange('reference_id')}
                    />
                </FormControl>

                {props.isDesktop ? (
                    <div className="button-container">
                        <CustomButton
                            isLoading={transaction.loading ?? false}
                            type="submit"
                            label="Confirm Transfer"
                            className="upviews-button"
                        />
                        <p className="policy">
                            By clicking Deposit you agree to our{' '}
                            <CustomTermsOfUseLinkComponent />, Privacy Policy
                            and Refund Policy.
                        </p>
                    </div>
                ) : (
                    <Box
                        sx={{
                            bgcolor: '#fff',
                            height: '200px',
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            borderTopLeftRadius: '40px',
                            borderTopRightRadius: '40px',
                            boxShadow: '0px 0px 5px 0px  rgb(0 0 0 / 10%)',
                            textAlign: 'center',
                            zIndex: '999',
                            padding: '10px 20px',
                            '& p': {
                                fontWeight: 500,
                                fontSize: '14px',
                            },
                        }}
                    >
                        <p>
                            By clicking Deposit you agree to our{' '}
                            <CustomTermsOfUseLinkComponent />, Privacy Policy
                            and Refund Policy.
                        </p>
                        <CustomButton
                            isLoading={transaction.loading ?? false}
                            label="Confirm Transfer"
                            className="upviews-button"
                            sx={{ width: '100%' }}
                            type="submit"
                        />
                    </Box>
                )}
            </Box>
        </div>
    )
}
