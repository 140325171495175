import { useEffect, useState, ChangeEvent, SyntheticEvent } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
    Container,
    FormControl,
    IconButton,
    InputAdornment,
    OutlinedInput,
} from '@mui/material'
import { Box } from '@mui/system'
import CustomButton from '../components/customComponents/custom-button.component'
import CustomLabeledFormControl from '../components/customComponents/custom-labeled-form-control'
import ProfileImage from '../components/dashboard/profile-image.component'
import { IsSmallestScreen } from '../helpers/utils.helper'
import { getCurrentUser } from '../services/auth.service'
import Notification from '../components/notification.components'
import { PASSWORD_RESET } from '../constants/user.constants'
import store, { RootState } from '../store'
import { useSelector } from 'react-redux'
import { PasswordState } from '../reducers/password.reducer'
import { ProfileAction } from '../actions/account.action'

const ProfileChangePassword = (props: any) => {
    const password = useSelector<RootState, PasswordState>(
        (state) => state.password
    )
    const user = getCurrentUser()

    const isDesktop = props.isDesktop
    const isSmallestScreen = IsSmallestScreen()
    const currentUser = getCurrentUser() || {}

    const [showCurrentPassword, setShowCurrentPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [passwordInfo, setPasswordInfo] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    })
    const defaultErrorState = {
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
    }
    const [errors, setErrors] = useState(defaultErrorState)

    const validate = () => {
        let payload = [
            {
                key: 'currentPassword',
                isValid: passwordInfo.currentPassword !== '',
            },
            {
                key: 'newPassword',
                isValid: passwordInfo.newPassword !== '',
            },
            {
                key: 'confirmPassword',
                isValid:
                    passwordInfo.confirmPassword !== '' &&
                    passwordInfo.confirmPassword === passwordInfo.newPassword,
            },
        ]

        return {
            data: payload,
            isValid: payload.every((data) => data.isValid),
        }
    }

    useEffect(() => {
        props.getScreenTitle('My Profile')
    }, []) // eslint-disable-line

    const onChange =
        (key: string) => (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target
            setPasswordInfo((prevState) => ({
                ...prevState,
                [key]: value,
            }))
        }

    const handleSubmit = async (event: SyntheticEvent) => {
        event.preventDefault()

        const validation = validate()

        if (!validation.isValid) {
            setErrors(
                validation.data.reduce((prevField, field) => {
                    return { ...prevField, [field.key]: !field.isValid }
                }, defaultErrorState)
            )
            return
        }

        store.dispatch(
            ProfileAction.changePassword(user.id, {
                password: passwordInfo.newPassword,
                current_password: passwordInfo.currentPassword,
            })
        )
    }
    return (
        <Container className="container">
            <Box
                sx={{
                    display: isDesktop ? 'flex' : 'block',
                    justifyContent: 'space-between',
                }}
            >
                <ProfileImage currentUser={currentUser} isDesktop={isDesktop} />
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    sx={{
                        width: isDesktop ? '100%' : 'unset',
                        mt: !isDesktop ? '-25px' : 'unset',
                        backgroundColor: '#FFF',
                        borderRadius: '40px',
                    }}
                >
                    <Box
                        sx={{
                            padding: '10px 40px',
                            borderBottom: '1px solid #c4c4c4!important',
                        }}
                    >
                        <h2>Change Password</h2>
                        <CustomLabeledFormControl
                            label="Current Password"
                            customChildren={
                                <FormControl
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    error={errors.currentPassword}
                                >
                                    <OutlinedInput
                                        type={
                                            showCurrentPassword
                                                ? 'text'
                                                : 'password'
                                        }
                                        placeholder="Current Password"
                                        value={passwordInfo.currentPassword}
                                        onChange={onChange('currentPassword')}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disableRipple
                                                    aria-label="toggle password visibility"
                                                    onClick={() =>
                                                        setShowCurrentPassword(
                                                            !showCurrentPassword
                                                        )
                                                    }
                                                    edge="end"
                                                >
                                                    {showCurrentPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            }
                        />
                        <CustomLabeledFormControl
                            label="New Password"
                            customChildren={
                                <FormControl
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    error={errors.newPassword}
                                >
                                    <OutlinedInput
                                        type={
                                            showNewPassword
                                                ? 'text'
                                                : 'password'
                                        }
                                        placeholder="New Password"
                                        value={passwordInfo.newPassword}
                                        onChange={onChange('newPassword')}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disableRipple
                                                    aria-label="toggle password visibility"
                                                    onClick={() =>
                                                        setShowNewPassword(
                                                            !showNewPassword
                                                        )
                                                    }
                                                    edge="end"
                                                >
                                                    {showNewPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            }
                        />
                        <CustomLabeledFormControl
                            label="Confirm New Password"
                            customChildren={
                                <FormControl
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    error={errors.confirmPassword}
                                >
                                    <OutlinedInput
                                        type={
                                            showConfirmPassword
                                                ? 'text'
                                                : 'password'
                                        }
                                        placeholder="Confirm New Password"
                                        value={passwordInfo.confirmPassword}
                                        onChange={onChange('confirmPassword')}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disableRipple
                                                    aria-label="toggle password visibility"
                                                    onClick={() =>
                                                        setShowConfirmPassword(
                                                            !showConfirmPassword
                                                        )
                                                    }
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            }
                        />
                    </Box>
                    <Box
                        sx={{
                            padding: '20px',
                            textAlign: 'right',
                        }}
                    >
                        {isDesktop && (
                            <CustomButton
                                label="Cancel"
                                className="upviews-button"
                                sx={{
                                    padding: '8px 55px!important',
                                    width: isSmallestScreen ? '100%' : 'unset',
                                    bgcolor: 'unset!important',
                                    color: '#27A857!important',
                                }}
                            />
                        )}
                        <CustomButton
                            isLoading={false}
                            label="Confirm Changes"
                            type="submit"
                            sx={{
                                padding: '8px 55px!important',
                                width: isSmallestScreen ? '100%' : 'unset',
                            }}
                            className="upviews-button"
                        />
                        {!isDesktop && (
                            <CustomButton
                                label="Cancel"
                                className="upviews-button"
                                sx={{
                                    padding: '8px 55px!important',
                                    width: isSmallestScreen ? '100%' : 'unset',
                                    bgcolor: 'unset!important',
                                    color: '#27A857!important',
                                    mt: '10px',
                                }}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
            <Notification
                message={password.message}
                open={password.showNotification}
                severity={password.hasError ? 'error' : 'success'}
                onClose={() =>
                    store.dispatch({
                        type: PASSWORD_RESET,
                    })
                }
            />
        </Container>
    )
}

export default ProfileChangePassword
