import {
  API_PROVIDER_EDIT_FAIL,
  API_PROVIDER_EDIT_SUCCESS,
  API_PROVIDER_FAIL,
  API_PROVIDER_GET_FAIL,
  API_PROVIDER_GET_SUCCESS,
  API_PROVIDER_REQUEST,
  API_PROVIDER_RESET,
  API_PROVIDER_SUCCESS,
} from "../constants/api-providers.constants";

type DataApiProviders = {
  message?: string | null;
  list: {
    data: Array<object>;
    meta: object | null;
  };
};

export interface ApiProviderState {
  loading?: boolean;
  showNotification: boolean;
  hasError?: boolean;
  data: DataApiProviders;
  apiProviders: any;
}

interface Action {
  type: string;
  payload?: any;
}

export const apiProvidersReducer = (
  state: ApiProviderState = {
    loading: false,
    showNotification: false,
    hasError: false,
    apiProviders: null,
    data: {
      message: null,
      list: {
        data: [],
        meta: null,
      },
    },
  },
  action: Action
) => {
  switch (action.type) {
    case API_PROVIDER_REQUEST:
      return {
        loading: true,
        showNotification: false,
        hasError: false,
        apiProviders: state.apiProviders,
        data: {
          message: null,
          list: state.data.list,
        },
      };
    case API_PROVIDER_SUCCESS:
        return {
          loading: false,
          showNotification: true,
          hasError: false,
          apiProviders: action.payload,
          data: {
            ...state.data,
            list: {
              ...state.data.list,
              data: [
                ...state.data.list.data,
                action.payload,
              ]
            },
            message: 'Api Successfully Added.',
          },
        };
    case API_PROVIDER_FAIL:
      return {
        loading: false,
        showNotification: true,
        hasError: true,
        apiProviders: state.apiProviders,
        data: { ...state.data, message: action.payload?.message },
      };
    case API_PROVIDER_GET_SUCCESS:
      return {
        loading: false,
        showNotification: false,
        hasError: false,
        apiProviders: action.payload,
        data: { list: action.payload },
      };
    case API_PROVIDER_GET_FAIL:
      return {
        loading: false,
        showNotification: true,
        hasError: true,
        apiProviders: state.apiProviders,
        data: { ...state.data, message: action.payload?.message },
      };
    case API_PROVIDER_RESET:
      return {
        loading: false,
        data: state.data,
        showNotification: false,
        apiProviders: state.apiProviders,
        hasError: false,
      };
    case API_PROVIDER_EDIT_SUCCESS:
      return {
        loading: false,
        showNotification: true,
        hasError: false,
        apiProviders: action.payload,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            data: state.data.list.data.map((api: any) =>
              api.id === action.payload?.id ?? 0
                ?  action.payload
                : api
            ),
          },
          message: 'Api Successfully Updated.',
        },
      };
    case API_PROVIDER_EDIT_FAIL:
      return {
        loading: false,
        showNotification: true,
        hasError: true,
        apiProviders: state.apiProviders,
        data: { ...state.data, message: action.payload?.message },
      };
    default:
      return state;
  }
};
