import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import BalanceIcon from '../../assets/images/balance-icon.png'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Popover,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { collectDataSet, collectLabels } from '../../helpers/statistic.helper'
import TextSkeleton from '../skeletons/text.skeleton'
import RectangularSkeleton from '../skeletons/rectangular.skeleton'
import { Box } from '@mui/system'
import { DAILY, MONTHLY, WEEKLY, YEARLY } from '../../types/statistics.types'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        y: {
            display: false,
        },
        x: {
            display: false,
        },
    },
    barPercentage: 0.7,
    barThickness: 'flex',
    categoryPercentage: 0.5,
}

export const defaultDataSet = {
    label: '',
    data: [0],
    backgroundColor: 'rgba(255, 255, 255, 0.64)',
    borderWidth: 0,
    borderRadius: 4,
    borderColor: 'rgba(255, 255, 255, 0.64)',
}

export default function BalanceGraph(props: any) {
    const isLoading = props.isLoading
    const [anchorEl, setAnchorEl] = useState(null)
    const [filter, setFilter] = useState('Monthly')
    const [isLoaded, setIsLoaded] = useState(false)
    const [total, setTotal]:any = useState(0)

    const [data, setData] = useState({
        labels: [''],
        datasets: [defaultDataSet],
    })

    useEffect(() => {
        if ((isLoaded === false && props.data?.data) ?? null) {
            let dataSet = collectDataSet(props.data.data)
            let currentLabels = collectLabels(dataSet)
            let dataSetTotal = dataSet.reduce(
                (a: any, b: any) => parseFloat(a) + parseFloat(b),
                0
            )
            setData({
                labels: currentLabels,
                datasets: [{ ...defaultDataSet, data: dataSet }],
            })
            setTotal(dataSetTotal.toFixed(2))
            setIsLoaded(true)
        }
    }, [isLoaded, props, data])

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleFilter = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        filter: string
    ) => {
        setFilter(filter)
        let dataSet = collectDataSet(props.data.data, filter)
        let dataSetTotal = dataSet.reduce(
            (a: any, b: any) => parseFloat(a) + parseFloat(b),
            0
        )
        let currentLabels = collectLabels(dataSet)
        setData({
            labels: currentLabels,
            datasets: [{ ...defaultDataSet, data: dataSet }],
        })
        if(filter === DAILY)
          setTotal(dataSet[0].toFixed(2))
        else
          setTotal(dataSetTotal.toFixed(2))
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    return (
        <div className="balance-container">
            <div className="header">
                <img src={BalanceIcon} alt="" />
                <p aria-describedby={id} onClick={handleClick}>
                    <span>{filter}</span> <KeyboardArrowDownIcon />
                </p>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className="popover"
            >
                <List>
                  <ListItem disablePadding>
                        <ListItemButton
                            onClick={(event) => handleFilter(event, DAILY)}
                        >
                            <ListItemText primary={DAILY} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={(event) => handleFilter(event, WEEKLY)}
                        >
                            <ListItemText primary={WEEKLY} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={(event) => handleFilter(event, MONTHLY)}
                        >
                            <ListItemText primary={MONTHLY} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={(event) => handleFilter(event, YEARLY)}
                        >
                            <ListItemText primary={YEARLY} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Popover>
            <Box sx={{
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'space-between',
              '& .balance': {
                width: '40%',  
              },
              '& .graph': {
                width: '60%',  
              },
              '& h1': {
                margin: '0'
              }
            }}>
                <div className='balance'>
                    <p className="amount">Balance</p>
                    <h1>
                        {isLoading ? (
                            <TextSkeleton width="100px" />
                        ) : (
                            `$${total}`
                        )}
                    </h1>
                </div>
                <div className='graph'>
                    {isLoading ? (
                        <RectangularSkeleton width="100%" height={100} />
                    ) : (
                        <Bar options={options} data={data} />
                    )}
                </div>
            </Box>
        </div>
    )
}
