import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Box } from '@mui/material'
import { Bar } from 'react-chartjs-2'
import RectangularSkeleton from '../skeletons/rectangular.skeleton'
import TextSkeleton from '../skeletons/text.skeleton'
import { useEffect, useState } from 'react'
import { collectDataSet } from '../../helpers/statistic.helper'
import { collectLabels } from './revenue-statistics-graph.component'
import { YEARLY } from '../../types/statistics.types'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        y: {
            display: false,
        },
        x: {
            display: false,
        },
    },
    barPercentage: 0.4,
    barThickness: 'flex',
    categoryPercentage: 0.3,
}

export const defaultDataSet = {
    label: '',
    data: [0],
    backgroundColor: 'rgba(255, 255, 255, 0.64)',
    borderWidth: 0,
    borderRadius: 4,
    borderColor: 'rgba(255, 255, 255, 0.64)',
}

export default function TotalBalanceGraph(props: any) {
    const isLoading = props.isLoading
    const [total, setTotal]:any = useState(0)
    const [isLoaded, setIsLoaded] = useState(false)

    const [data, setData] = useState({
        labels: [''],
        datasets: [defaultDataSet],
    })

    useEffect(() => {
        if ((isLoaded === false && props.data?.data) ?? null) {
            let dataSet = collectDataSet(props.data.data, YEARLY)
            let currentLabels = collectLabels(dataSet)
            setData({
                labels: currentLabels,
                datasets: [{ ...defaultDataSet, data: dataSet }],
            })
            setIsLoaded(true)
            setTotal(props?.data?.total.toFixed(2) ?? 0)
        }
    }, [isLoaded, props, data])

    return (
        <Box
            className="balance-container"
            sx={{
                marginTop: '20px',
                '& .amount': {
                    fontSize: '14px',
                    margin: '0',
                    padding: '5px 0',
                },
            }}
        >
            <p className="amount">Overall Balance</p>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'space-between',
                    '& .balance': {
                        width: '50%',
                    },
                    '& .graph': {
                        width: '40%',
                    },
                    '& h1': {
                        margin: '0',
                        
                        fontSize: '30px',
                    },
                }}
            >
                <div className="balance">
                    <h1>
                        {isLoading ? (
                            <TextSkeleton width="100px" />
                        ) : (
                            `$${total.toLocaleString()}`
                        )}
                    </h1>
                </div>
                <div className="graph">
                    {isLoading ? (
                        <RectangularSkeleton width="100%" height={100} />
                    ) : (
                        <Bar options={options} data={data} height={100} />
                    )}
                </div>
            </Box>
        </Box>
    )
}
