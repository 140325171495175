import { useState } from 'react'
import { formatDate } from '../../../helpers/utils.helper'
import TableWrapper from '../../wrappers/table-wrapper.component'

const LoginRecords = (props: any) => {
    const {
        list,
        meta,
        onPageChange,
        onSearch,
        onSort,
        isLoading,
        isDesktop,
    } = props
    const { current_page, total, last_page = 0, from = 0, to = 0 } = meta || {}
    const [sortBy, setSortBy] = useState('')
    const headers = [
        {
            key: 'timestamp',
            label: 'Date Logged',
            customValue: (row: any) => formatDate(row.timestamp, 'l LT'),
        },
        {
            key: 'ip_address',
            label: 'IP Address',
        },
    ]

    const onSortChange = (value: string) => {
      setSortBy(value)
      onSort(value)
  }


    return (
        <TableWrapper
            entityLabel="records"
            headers={headers}
            sortBy={sortBy}
            body={list}
            responsiveHeaders={[{ key: 'ip_address', label: 'IP Address' }]}
            isLoading={isLoading}
            isDesktop={isDesktop}
            total={total}
            currentPage={current_page}
            onPageChange={onPageChange}
            pageCount={last_page}
            pageFrom={from}
            hideFilters={true}
            showFiltersFilter={true}
            pageTo={to}
            onSortChange={onSortChange}
            onSearch={onSearch}
        />
    )
}

export default LoginRecords
