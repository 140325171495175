import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Box, FormControl, MenuItem, Select, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

import TableWrapper from '../../wrappers/table-wrapper.component'
import AddFilterNav from './add-filter-nav.component'
import EditFilterNav from './edit-filter-nav.component'
import { TYPE_BLACKLIST, TYPE_WHITELIST } from '../../../types/filter.types'
import { IsSmallestScreen } from '../../../helpers/utils.helper'
import CustomButton from '../../customComponents/custom-button.component'
import { KeyboardArrowDown } from '@mui/icons-material'

type Options = {
    id: string
    name: string
}

const formatOptions = (options: Object): Options[] => {
    return (Object.entries(options ?? {}) as [string, string][]).map(
        ([key, value]: [string, string]): Options => {
            return {
                id: key,
                name: value,
            }
        }
    )
}

const Filters = (props: any) => {
    const {
        onSearch,
        list,
        meta,
        onPageChange,
        options,
        onFilterCategoryChange,
        filterCategoryBy,
        onFilterTypeChange,
        filterTypeBy,
        deleteFilter,
        setBlacklist,
        setWhitelist,
        isLoading,
        isDesktop,
    } = props
    const { current_page, total, last_page = 0, from = 0, to = 0 } = meta || {}
    const isSmallestScreen = IsSmallestScreen()
    const headers = [
        {
            key: 'id',
            label: 'ID',
            isVisible: true,
        },
        {
            key: 'keyword',
            label: 'Keyword',
            customValue: (row: any) => {
                return (
                    <div>
                        <div>
                            <strong>{row.keyword}</strong>
                        </div>
                    </div>
                )
            },
        },
        {
            key: 'category',
            label: 'Category Type',
            customValue: (row: any) => {
                return (
                    <div>
                        {(options?.filter_categories ?? {})[row.category] ?? ''}
                    </div>
                )
            },
        },
        {
            key: 'type',
            label: 'Type',
            customValue: (row: any) => {
                return (
                    <div>{(options?.filter_types ?? {})[row.type] ?? ''}</div>
                )
            },
        },
    ]

    const addFilter = () => {
        props.getRightNav({
            title: 'Add Filter',
            content: <AddFilterNav />,
        })
        props.showRightSideBar()
    }

    const editFilter = (filterDetails: any) => {
        props.getRightNav({
            entity: 'edit-filter-' + filterDetails.id,
            title: 'Edit Filter',
            content: (
                <EditFilterNav
                    key={`edit_nav_${filterDetails.id}`}
                    filterDetails={filterDetails}
                />
            ),
        })
        props.showRightSideBar()
    }

    const styles = {
        height: '8px',
        width: '8px',
        border: '3px solid #FF4848',
        borderRadius: '50%',
        ml: '4px',
        '&.whitelist': { borderColor: '#EAEAEA' },
        '&.blacklist': { borderColor: '#000000' },
    }

    const contextMenu = [
        {
            callBack: (row: any) => setWhitelist(row),
            label: 'Set Whitelist',
            value: TYPE_WHITELIST,
            icon: <Box sx={styles} className="whitelist" />,
        },
        {
            callBack: (row: any) => setBlacklist(row),
            label: 'Set Blacklist',
            value: TYPE_BLACKLIST,
            icon: <Box sx={styles} className="blacklist" />,
        },
        {
            callBack: (row: any) => deleteFilter(row),
            label: 'Delete',
            icon: <DeleteIcon />,
        },
        {
            callBack: (row: any) => editFilter(row),
            label: 'Edit',
            icon: <EditIcon />,
        },
    ]

    const categoryFilters = formatOptions(options?.filter_categories)
    const typeFilters = formatOptions(options?.filter_types)

    return (
        <TableWrapper
            entityLabel="filters"
            headers={headers}
            body={list}
            responsiveHeaders={[{ key: 'id', label: 'ID' }]}
            isLoading={isLoading}
            isDesktop={isDesktop}
            additionalActions={
                <>
                    <Box
                        width={isSmallestScreen ? '100%' : '300px'}
                        mr={isSmallestScreen ? 'unset' : '15px'}
                    >
                        <FormControl
                            className="upview-form-control"
                            sx={{
                                width: '100%',
                                mr: '20px',
                                '& svg': { fontSize: '1.5rem' },
                            }}
                        >
                            <TextField
                                fullWidth
                                placeholder="Search for..."
                                size="small"
                                id="outlined-basic"
                                variant="outlined"
                                onChange={(e) => onSearch(e.target.value)}
                                InputProps={{
                                    startAdornment: <SearchIcon />,
                                }}
                            />
                        </FormControl>
                    </Box>
                    <Box
                        sx={{
                            display: isSmallestScreen ? 'block' : 'flex',
                            justifyContent: 'space-between',
                            minWidth: { md: '30%' },
                        }}
                    >
                        <FormControl
                            className="upview-form-control common-styled-select"
                            hiddenLabel
                            size="small"
                            sx={{
                                width: '100%',
                                mr: '20px',
                                mt: { xs: '20px', sm: '0px' },
                            }}
                        >
                            <Select
                                IconComponent={KeyboardArrowDown}
                                inputProps={{ 'aria-label': 'Without label' }}
                                displayEmpty
                                value={filterCategoryBy}
                                onChange={(e) =>
                                    onFilterCategoryChange(e.target.value)
                                }
                            >
                                <MenuItem value="">All Categories</MenuItem>
                                {categoryFilters &&
                                    categoryFilters.map((option: any) => (
                                        <MenuItem
                                            key={`opt_` + option.id}
                                            value={option.id}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>

                        <FormControl
                            className="upview-form-control common-styled-select"
                            hiddenLabel
                            size="small"
                            sx={{
                                width: '100%',
                                mt: { xs: '20px', sm: '0px' },
                            }}
                        >
                            <Select
                                IconComponent={KeyboardArrowDown}
                                inputProps={{ 'aria-label': 'Without label' }}
                                displayEmpty
                                value={filterTypeBy}
                                onChange={(e) =>
                                    onFilterTypeChange(e.target.value)
                                }
                            >
                                <MenuItem value="">All Types</MenuItem>
                                {typeFilters &&
                                    typeFilters.map((type: any) => (
                                        <MenuItem
                                            key={`type_` + type.id}
                                            value={type.id}
                                        >
                                            {type.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        {isSmallestScreen ? (
                            <CustomButton
                                label="Add Filter"
                                className="upviews-button"
                                startIcon={<AddIcon />}
                                onClick={addFilter}
                                fullWidth
                                sx={{ mt: '20px' }}
                            />
                        ) : (
                            <AddIcon
                                onClick={addFilter}
                                sx={{
                                    ml: '12px',
                                    fontSize: '27px!important',
                                    color: '#fff',
                                    bgcolor: '#27A857',
                                    borderRadius: '30px',
                                    padding: '5px',
                                    '&.inactive': {
                                        bgcolor: '#BABEC8',
                                    },
                                }}
                            />
                        )}
                    </Box>
                </>
            }
            contextMenu={contextMenu}
            total={total}
            currentPage={current_page}
            onSearch={onSearch}
            onPageChange={onPageChange}
            onFilterCategoryChange={onFilterCategoryChange}
            filterCategoryBy={filterCategoryBy}
            onFilterTypeChange={onFilterTypeChange}
            filterTypeBy={filterTypeBy}
            pageCount={last_page}
            pageFrom={from}
            hideFilters={true}
            showFiltersFilter={true}
            pageTo={to}
        />
    )
}

export default Filters
