import {
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
} from '@mui/material'
import { Box } from '@mui/system'
import { IsSmallestScreen } from '../../helpers/utils.helper'
import CustomButton from '../customComponents/custom-button.component'
import CustomLabeledFormControl from '../customComponents/custom-labeled-form-control'
import { KeyboardArrowDown } from '@mui/icons-material'
import { GENDERS, TYPE_CUSTOMER, USER_TYPES } from '../../types/user.types'

export default function NewUserProfile(props: any) {
    const isSmallestScreen = IsSmallestScreen()

    const handleRoleChange = (event: any) => {
        if (!event.target) {
            return
        }

        const role = event.target.value

        if (role === TYPE_CUSTOMER) {
            props.onChange({
                is_customer: true,
                role: '',
            })(event)
        } else {
            props.onChange({
                is_customer: false,
                role: role,
            })(event)
        }
    }

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={props.handleSubmit}
            sx={{
                width: props.isDesktop ? '100%' : 'unset',
                mt: !props.isDesktop ? '-25px' : 'unset',
                backgroundColor: '#FFF',
                borderRadius: '40px',
            }}
        >
            <Box
                sx={{
                    padding: '10px 40px',
                    borderBottom: '1px solid #c4c4c4!important',
                }}
            >
                <h2>Personal Information</h2>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        '& > div': {
                            width: props.isDesktop ? '49%' : '100%',
                        },
                    }}
                >
                    <CustomLabeledFormControl
                        label="Full Name"
                        customChildren={
                            <FormControl
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                error={props.error.name}
                            >
                                <OutlinedInput
                                    placeholder="Full Name"
                                    value={props.personalInfo.name}
                                    onChange={props.onChange('name')}
                                />
                            </FormControl>
                        }
                    />

                    <CustomLabeledFormControl
                        label="Email Address"
                        customChildren={
                            <FormControl
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                error={props.error.email}
                            >
                                <OutlinedInput
                                    placeholder="yourcompany@email.com"
                                    value={props.personalInfo.email}
                                    onChange={props.onChange('email')}
                                />
                            </FormControl>
                        }
                    />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        mb: '30px',
                        '& > div': {
                            width: props.isDesktop ? '49%' : '100%',
                        },
                    }}
                >
                    <FormControl
                        className="common-styled-select"
                        sx={{
                            borderRadius: '15px',
                            width: '100%',
                            mt: {
                                xs: '20px',
                                sm: '0px',
                            },
                            '& label': {
                                mb: '10px',
                            },
                        }}
                    >
                        <label>Select Role</label>
                        <Select
                            IconComponent={KeyboardArrowDown}
                            inputProps={{
                                'aria-label': 'Without label',
                            }}
                            defaultValue={'customer'}
                            value={props.personalInfo.roles}
                            onChange={handleRoleChange}
                        >
                            {USER_TYPES.map((utype, i) => (
                                <MenuItem key={`utype_` + i} value={utype.key}>
                                    {utype.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl
                        variant="outlined"
                        sx={{
                            color: '#7E7E7E',
                            borderRadius: '15px !important',
                            background: '#FFFFFF',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'space-around',
                            '& label': {
                                marginRight: '10px',
                                '&.Mui-focused': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                },
                            },
                        }}
                    >
                        <FormLabel
                            sx={{ mb: '10px' }}
                            id="demo-row-radio-buttons-group-label"
                        >
                            Gender
                            <span
                                style={{
                                    color: props.error.gender ? 'red' : 'unset',
                                    marginLeft: '5px',
                                }}
                            >
                                *
                            </span>
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={props.onChange('gender')}
                        >
                            {GENDERS.map((gender, i) => (
                                <FormControlLabel
                                    key={`gender` + i}
                                    value={gender.name}
                                    control={
                                        <Radio
                                            value={gender.key}
                                            sx={{
                                                color: '#D9D9D9',
                                                '&.Mui-checked': {
                                                    color: '#27A857',
                                                },
                                            }}
                                        />
                                    }
                                    label={gender.name}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Box>
            <Box
                sx={{
                    padding: '20px',
                    textAlign: 'right',
                }}
            >
                <CustomButton
                    isLoading={props.loading}
                    label={'Create New User'}
                    type="submit"
                    sx={{
                        padding: '8px 55px!important',
                        width: isSmallestScreen ? '100%' : 'unset',
                    }}
                    className="upviews-button"
                />
            </Box>
        </Box>
    )
}
