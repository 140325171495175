import { OPTIONS_FAIL, OPTIONS_REQUEST, OPTIONS_RESET, OPTIONS_SUCCESS } from '../constants/screen.constants';

export interface ScreenState {
  loading?: boolean;
  options?: any;
  showNotification: boolean;
  hasError?: boolean;
}

interface Action {
  type: string;
  payload?: any;
}

export const screenReducer = (
  state: ScreenState = {
    loading: false,
    hasError: false,
    showNotification: false,
    options: null,
  },
  action: Action
) => {
  switch (action.type) {
    case OPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        showNotification: false,
        options: null,
      };
    case OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        options: action.payload,
        hasError: false,
        showNotification: false,
      };
    case OPTIONS_FAIL:
      return {
        ...state,
        loading: false,
        options: action.payload,
        hasError: true,
        showNotification: true,
      };
    case OPTIONS_RESET:
      return {
        ...state,
        loading: false,
        options: null,
        hasError: false,
        showNotification: false,
      };
    default:
      return state;
  }
};
