import React, { useState, useCallback } from 'react'
import {
    Box,
    FormControl,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Stack,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import _ from 'lodash'

import SearchIcon from '@mui/icons-material/Search'
import { IsDesktopScreen, IsSmallestScreen } from '../../helpers/utils.helper'
import DesktopTable from './desktop-table.component'
import MobileTable from './mobile-table.component'

export type DataPropsAny = { [key: string]: any }

const contextMenuButtonStyles = {
    '&.active': {
        '& .MuiButton-root': {
            bgcolor: '#F7684A',
        },
    },
    '& .MuiButton-root': {
        borderRadius: '50%',
        minWidth: 'unset',
        width: '25px',
        height: '25px',
        padding: '2px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: '#BABEC8',
        color: 'white',
        cursor: 'pointer',
        '&:hover': {
            bgcolor: '#F7684A',
        },
        svg: {
            fontSize: '20px',
        },
    },
}

const TableWrapper = (props: DataPropsAny) => {
    const {
        headers,
        body,
        hideToolbars,
        hideFilters = false,
        additionalFilters,
        additionalActions,
        additionalActionsMobile,
        contextMenu = [],
        entityLabel = 'records',
        title = '',
        total,
        currentPage = 0,
        pageFrom = 0,
        pageTo = 0,
        sortBy = '',
        pageCount = 0,
        isLoading = false,
        mobileActionIcon,
        onGetCollapsibleComponent,
        onPageChange = () => {},
        onSortChange = () => {},
        onSearch = () => {},
    } = props
    const hasContextMenu =
        contextMenu.length > 0 &&
        body.length > 0 &&
        !mobileActionIcon &&
        !isLoading
    const isDesktop = IsDesktopScreen()
    const isCollapsible = onGetCollapsibleComponent
    const columnTotal =
        headers.length +
        (hasContextMenu && body.length ? 1 : 0) +
        (isCollapsible ? 1 : 0)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [activeContextRow, setActiveContextRow] =
        useState<null | DataPropsAny>(null)
    const [collapsedRow, setCollapsedRow] = useState<{
        [key: string]: boolean
    }>({})
    const isContextMenuOpen = Boolean(anchorEl)
    const isSmallestScreen = IsSmallestScreen()

    const changeSearchTerm = _.debounce((value) => {
        onSearch(value)
    }, 200)
    const handleSearchTermChange = useCallback(changeSearchTerm, []) //eslint-disable-line

    const handleOpenContextMenu = (
        event: React.MouseEvent<HTMLButtonElement>,
        row: DataPropsAny
    ) => {
        setAnchorEl(event.currentTarget)
        setActiveContextRow(row)
    }

    const handleCloseContextMenu = () => {
        setAnchorEl(null)
        setActiveContextRow(null)
    }

    return (
        <Box className="table-wrapper">
            <Box sx={{ width: '100%' }}>
                <Paper
                    sx={{
                        width: '100%',
                        mb: 2,
                        bgcolor: 'transparent',
                        boxShadow: 'none',
                    }}
                >
                    {!hideToolbars && (
                        <Toolbar
                            sx={{
                                bgcolor: 'transparent',
                                display: { xs: 'block', md: 'flex' },
                                justifyContent: 'end',
                                pr: '8px!important',
                                pl: '8px!important',
                            }}
                        >
                            {!hideFilters && (
                                <>
                                    <Box width="100%">
                                        {title && (
                                            <Typography
                                                variant="h6"
                                                component="h3"
                                                sx={{
                                                    fontWeight: 700,
                                                    fontSize: 24,
                                                    mb: {
                                                        xs: '20px',
                                                        md: 'unset',
                                                    },
                                                }}
                                            >
                                                {title}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: {
                                                xs: 'block',
                                                sm: 'flex',
                                            },
                                            minWidth: { md: '30%' },
                                            alignItems: 'center',
                                            justifyContent: 'end',
                                        }}
                                    >
                                        <>
                                            <FormControl
                                                className="upview-form-control"
                                                sx={{
                                                    width: '100%',
                                                    mr: '20px',
                                                }}
                                            >
                                                <TextField
                                                    placeholder="Search for..."
                                                    size="small"
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    onChange={(e) => {
                                                        handleSearchTermChange(
                                                            e.target.value
                                                        )
                                                    }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <SearchIcon
                                                                sx={{
                                                                    fontSize:
                                                                        '1.5rem!important',
                                                                }}
                                                            />
                                                        ),
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl
                                                className="upview-form-control common-styled-select"
                                                hiddenLabel
                                                size="small"
                                                sx={{
                                                    width: '100%',
                                                    mt: {
                                                        xs: '20px',
                                                        sm: '0px',
                                                    },
                                                }}
                                            >
                                                <Select
                                                    IconComponent={
                                                        KeyboardArrowDown
                                                    }
                                                    inputProps={{
                                                        'aria-label':
                                                            'Without label',
                                                    }}
                                                    displayEmpty
                                                    value={sortBy}
                                                    onChange={(e) =>
                                                        onSortChange(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <MenuItem value="">
                                                        Sort By
                                                    </MenuItem>
                                                    <MenuItem value="created_at">
                                                        Date Added
                                                    </MenuItem>
                                                    <MenuItem value="status">
                                                        Status
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                            {additionalFilters &&
                                                additionalFilters}
                                        </>

                                        {additionalActions && (
                                            <Box
                                                sx={{
                                                    mt: isSmallestScreen
                                                        ? '20px'
                                                        : 'unset',
                                                    pl: isSmallestScreen
                                                        ? 'unset'
                                                        : '12px',
                                                    '& button': {
                                                        fontSize: '18px',
                                                        bgcolor: '#27A857',
                                                        borderRadius: '30px',
                                                        width: '100%',
                                                    },
                                                    '& svg': isSmallestScreen
                                                        ? { ml: '5px' }
                                                        : { fontSize: '43px' },
                                                }}
                                            >
                                                {isSmallestScreen
                                                    ? additionalActionsMobile ??
                                                      additionalActions
                                                    : additionalActions}
                                            </Box>
                                        )}
                                    </Box>
                                </>
                            )}
                            {hideFilters && additionalActions && (
                                <Box
                                    sx={{
                                        display: { xs: 'block', sm: 'flex' },
                                        width: '100%',
                                        mt: isSmallestScreen ? '20px' : 'unset',
                                        justifyContent: 'space-between',
                                        '& button': {
                                            fontSize: '18px',
                                            bgcolor: '#27A857',
                                            borderRadius: '30px',
                                        },
                                        '& svg': isSmallestScreen
                                            ? { ml: '5px' }
                                            : { fontSize: '1.5rem' },
                                    }}
                                >
                                    {isSmallestScreen
                                        ? additionalActionsMobile ??
                                          additionalActions
                                        : additionalActions}
                                </Box>
                            )}
                        </Toolbar>
                    )}
                    <div className="spacer"></div>
                    {isDesktop ? (
                        <>
                            <DesktopTable
                                {...props}
                                isCollapsible={isCollapsible}
                                setCollapsedRow={setCollapsedRow}
                                collapsedRow={collapsedRow}
                                hasContextMenu={hasContextMenu}
                                activeContextRow={activeContextRow}
                                isContextMenuOpen={isContextMenuOpen}
                                anchorEl={anchorEl}
                                columnTotal={columnTotal}
                                isLoading={isLoading}
                                contextMenuButtonStyles={
                                    contextMenuButtonStyles
                                }
                                handleOpenContextMenu={handleOpenContextMenu}
                                handleCloseContextMenu={handleCloseContextMenu}
                            />
                            <Stack
                                className="pagination"
                                direction="row"
                                justifyContent="space-between"
                                alignItems="baseline"
                            >
                                <span>
                                    Showing {pageFrom || 0} - {pageTo || 0} of{' '}
                                    {total || 0} {entityLabel}
                                </span>
                                <Pagination
                                    count={pageCount}
                                    size="medium"
                                    page={currentPage}
                                    onChange={(e, page) => {
                                        onPageChange(page)
                                    }}
                                />
                            </Stack>
                        </>
                    ) : (
                        <MobileTable
                            {...props}
                            hasContextMenu={hasContextMenu}
                            activeContextRow={activeContextRow}
                            isContextMenuOpen={isContextMenuOpen}
                            contextMenuButtonStyles={contextMenuButtonStyles}
                            anchorEl={anchorEl}
                            isLoading={isLoading}
                            isCollapsible={isCollapsible}
                            setCollapsedRow={setCollapsedRow}
                            collapsedRow={collapsedRow}
                            columnTotal={columnTotal}
                            handleOpenContextMenu={handleOpenContextMenu}
                            handleCloseContextMenu={handleCloseContextMenu}
                        />
                    )}
                </Paper>
            </Box>
        </Box>
    )
}

export default TableWrapper
