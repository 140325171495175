import { INSTAGRAM_FAIL, INSTAGRAM_REQUEST, INSTAGRAM_SUCCESS } from "../constants/instagram.constants"

type DataInstagram = {
  message?: string | null,
  list: any
}

export interface InstagramState {
  loading?: boolean
  hasError?: boolean
  data: DataInstagram
}

interface Action {
  type: string
  payload?: any
}


export const instagramReducer = (
  state: InstagramState = {
      loading: false,
      hasError: false,
      data: {
          message: null,
          list: []
      },
  },
  action: Action
) => {
  switch (action.type) {
      case INSTAGRAM_REQUEST:
          return {
              loading: true,
              hasError: false,
              data: {
                  message: null,
                  list: null
              },
          }
      case INSTAGRAM_SUCCESS:
          return {
              loading: false,
              hasError: false,
              data: {
                  list: action.payload
              },
          }
      case INSTAGRAM_FAIL:
          return {
              loading: false,
              hasError: true,
              data: { list: null , message: action.payload?.message },
          }
      default:
          return state
  }
}
