import {
  Divider,
    FormControl,
    InputAdornment,
    MenuItem,
    Select,
    SelectChangeEvent,
    Skeleton,
} from '@mui/material'
import { Box } from '@mui/system'
import CustomLabeledFormControl from '../customComponents/custom-labeled-form-control'
import { KeyboardArrowDown } from '@mui/icons-material'
import { ChangeEvent, useEffect, useState } from 'react'
import { enforceInt } from '../../helpers/utils.helper'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { InstagramAction } from '../../actions/instagram.action'
import store, { RootState } from '../../store'
import { useSelector } from 'react-redux'
import { InstagramState } from '../../reducers/instagram.reducer'
import VerifiedIcon from '@mui/icons-material/Verified';
import CustomButton from '../customComponents/custom-button.component'

export default function IgOrderForm(props: any) {
    const isDesktop = props.isDesktop
    const [validTargetCount, setValidTargetCount] = useState(false)
    const [iGUsername, setiGUsername] = useState('')
    const instagram = useSelector<RootState, InstagramState>(
        (state) => state.instagram
    )

    
    useEffect(() => {
      if(iGUsername) {
        const delayDebounceFn = setTimeout(() => {
          store.dispatch(InstagramAction.get(iGUsername))
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
      }
        
    }, [iGUsername])


    const handleServiceChange = (event: SelectChangeEvent) => {
        if (!event.target) {
            return
        }

        let service = parseInt(event.target.value)
        props.onChange('service', service)(event)
    }

    const handleUrlChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        e.preventDefault()
        if (!e.target) {
            return
        }

        props.onChange('youtube_url', 'https://www.instagram.com/'+e.target.value)(e)

        setiGUsername(e.target.value)
    }

    const handleOptionChange = (event: SelectChangeEvent) => {
        if (!event.target) {
            return
        }

        let serviceType = parseInt(event.target.value)
        props.onChange('service_type', serviceType)(event)
    }

    const handleQuantityChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        e.preventDefault()

        let targetCount = e.target.value ? parseInt(e.target.value) : ''

        let isValidQuantity = props.validQuantity(targetCount, props.minMax)

        setValidTargetCount(isValidQuantity)

        props.onChange('target_count', targetCount)(e)
    }

    return (
        <Box className="order-form">
            <Box
                component="form"
                noValidate
                autoComplete="off"
                {...props.formProps}
            >
                <div className="form-inputs-container">
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            marginTop: '20px',
                            '& > div': { width: '100%' },
                        }}
                    >
                        <div>
                            <CustomLabeledFormControl
                                label="Select Your Service"
                                customChildren={
                                    <FormControl
                                        fullWidth
                                        hiddenLabel
                                        variant="outlined"
                                        size="small"
                                    >
                                        <Select
                                            IconComponent={KeyboardArrowDown}
                                            name="service"
                                            defaultValue="5"
                                            onChange={(e) =>
                                                handleServiceChange(e)
                                            }
                                            error={props.errors.service}
                                            value={props.payload.service}
                                        >
                                            {props.options.services &&
                                                props.options.services.map(
                                                    (viewType: any) => (
                                                        <MenuItem
                                                            key={
                                                                `service` +
                                                                viewType.id
                                                            }
                                                            value={viewType.id}
                                                        >
                                                            {viewType.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>
                                    </FormControl>
                                }
                            />
                        </div>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            marginTop: '20px',
                            justifyContent: 'space-between',
                            '& > div': {
                                width: '100%',
                            },
                        }}
                    >
                        <div>
                            <CustomLabeledFormControl
                                label="Service Type"
                                customChildren={
                                    <FormControl
                                        fullWidth
                                        hiddenLabel
                                        variant="outlined"
                                        size="small"
                                    >
                                        <Select
                                            IconComponent={KeyboardArrowDown}
                                            name="service_type"
                                            defaultValue="0"
                                            onChange={(e) =>
                                                handleOptionChange(e)
                                            }
                                            error={props.errors.service_type}
                                            value={props.payload.service_type}
                                        >
                                            <MenuItem
                                                key={`service_type_0`}
                                                value={0}
                                                disabled
                                            ></MenuItem>
                                            {props.options.serviceTypes &&
                                                props.options.serviceTypes.map(
                                                    (viewType: any) => (
                                                        <MenuItem
                                                            key={
                                                                `service_type_` +
                                                                viewType.id
                                                            }
                                                            value={viewType.id}
                                                        >
                                                            {viewType.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>
                                    </FormControl>
                                }
                            />
                        </div>
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    marginBottom: '20px',
                                    '& > div': { width: '100%' },
                                }}
                            >
                                <CustomLabeledFormControl
                                    label="Quantity"
                                    size="small"
                                    onKeyDown={enforceInt}
                                    onChange={(e: any) =>
                                        handleQuantityChange(e)
                                    }
                                    error={
                                        props.errors.target_count ||
                                        !validTargetCount
                                    }
                                    name="target_count"
                                    placeholder={
                                        props?.minMax?.min
                                            ? `Mininum: ${props?.minMax?.min}`
                                            : '0.00'
                                    }
                                    value={props.payload.target_count}
                                    endAdornment={
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                color: '#27A857',
                                                fontSize: '12px',
                                            }}
                                        >
                                            <span
                                                className="p-label"
                                                style={{
                                                    marginRight: '5px',
                                                }}
                                            >
                                                Price:
                                            </span>
                                            <span
                                                className="p-value"
                                                style={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                ${props.totalPrice}
                                            </span>
                                        </InputAdornment>
                                    }
                                />
                                <p
                                    className="desc"
                                    style={{
                                        marginTop: '-12px',
                                        color: validTargetCount
                                            ? 'rgba(0, 0, 0, 0.5)'
                                            : 'red',
                                    }}
                                >
                                    Min: {props.minMax.min} | Max:{' '}
                                    {props.minMax.max} | Increment:{' '}
                                    {props.minMax.min}
                                </p>
                            </Box>
                        </div>

                        <CustomLabeledFormControl
                            label="Enter your Instagram username"
                            fullWidth
                            size="small"
                            error={props.errors.youtube_url}
                            name="youtube_url"
                            onChange={handleUrlChange}
                            placeholder="johndoe"
                            endAdornment={ (iGUsername && !instagram?.loading) && 
                                <InputAdornment
                                    position="end"
                                    sx={{
                                        fontSize: '12px',
                                        '& svg': {
                                            marginLeft: '5px',
                                            height: '23px',
                                        },
                                    }}
                                >
                                  {!instagram?.hasError &&
                                    <span
                                        className="p-label"
                                        style={{
                                            marginRight: '5px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            color: '#00B67A'
                                        }}
                                    >
                                        Account Found
                                        <CheckCircleIcon />
                                    </span>
                                  }
                                  {instagram?.hasError &&
                                    <span
                                        className="p-label"
                                        style={{
                                            marginRight: '5px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            color: 'red'
                                        }}
                                    >
                                        Account Not Found
                                        <CancelOutlinedIcon />
                                    </span>
                                  }
                                </InputAdornment>
                            }
                        />

                        {(instagram.loading && iGUsername) &&
                          <Box
                          sx={{
                              display: 'flex',
                              flexDirection: isDesktop ? 'row' : 'column',
                              marginBottom: '20px',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                          }}
                          >
                              <Skeleton variant="circular" width={80} height={80} />
                              <Skeleton variant="rectangular"  sx={{width: '80%', height: '100px'}}/>
                          </Box>
                         
                        }
                        { instagram?.data?.list  && 
                            <Box
                                className="container-ig"
                                  sx={{
                                      display: 'flex',
                                      flexDirection: isDesktop ? 'row' : 'column',
                                      marginBottom: '20px',
                                  }}
                            >
                              <Box  
                                className='profile-container'
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'space-around',
                                  width: isDesktop ? '50%' : '100%',
                                  '& .profile': {
                                    padding: '5px',
                                    borderRadius: '50%',
                                    background: 'linear-gradient(to right, #E715CC, #FFCD00)',
                                    display: 'inline-block',
                                    '& img' : {
                                      borderRadius: '50%',
                                      height: '80px',
                                      width: '80px',
                                      margin: '0 auto',
                                      display: 'block',
                                      border: '8px solid #fff'
                                    },
                                  },
                                  '& h3': {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    margin: '0',
                                    textAlign: 'right',
                                    fontSize: '22px',
                                    '& svg': {
                                      marginLeft: '10px',
                                      color: '#3896EF'
                                    }
                                  }
                                }}
                              >
                                <div className='profile'>
                                  <img src={'https://www.followerpackages.com/services/image_orig.php?url='+ (instagram?.data?.list?.user?.profile_pic_url ?? '' )} alt={instagram?.data?.list?.user?.full_name ?? '' }  />
                                </div>
                                <h3>{instagram?.data?.list?.user?.full_name ?? '' } <VerifiedIcon /></h3>
                              </Box>
                              
                              <Box 
                                className='info'
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  width: isDesktop ? '50%' : '100%',
                                  marginLeft: '10%',
                                  textAlign: 'center',
                                  '& h3': {
                                    fontSize: '20px',
                                    marginBottom: '0px'
                                  },
                                  '& span': {
                                    fontSize: '14px'
                                  }
                                }}
                              >
                                <div><h3>{instagram?.data?.list?.user?.edge_owner_to_timeline_media?.count ?? 0 }</h3>  <span>Posts</span></div>
                                <div><h3>{instagram?.data?.list?.user?.edge_followed_by?.count ?? 0 }</h3>  <span>Followers</span></div>
                                <div><h3>{instagram?.data?.list?.user?.edge_follow?.count ?? 0 }</h3>  <span>Following</span></div>
                              </Box>
                            </Box>
                       } 
                    </Box>
                </div>

                {isDesktop && (
                    <div>
                        <Divider />
                        <CustomButton
                            isLoading={props.loading}
                            disabled={instagram?.hasError || instagram.loading}
                            sx={{
                                float: 'right',
                                margin: '20px 20px 20px 0px',
                            }}
                            label="Start Campaign"
                            type="submit"
                            className="upviews-button"
                        />
                    </div>
                )}

            </Box>
        </Box>
    )
}
