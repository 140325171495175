import {
    Box,
    FormControl,
    IconButton,
    InputAdornment,
    Link,
    OutlinedInput,
} from '@mui/material'
import { useState } from 'react'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import CustomButton from './customComponents/custom-button.component'
import { IsSmallestScreen } from '../helpers/utils.helper'

export default function ResetForm(props: any) {
    const isSmallestScreen = IsSmallestScreen()
    const { email } = props

    const [showPassword, setShowPassword] = useState(false)
    const [showCPassword, setShowCPassword] = useState(false)

    return (
        <Box
            component="form"
            className="login-form"
            noValidate
            autoComplete="off"
            {...props.formProps}
            sx={{
                mt: isSmallestScreen ? '16px!important' : '35px',
                '& input': { fontSize: '14px', padding: '20px 14px 19px 14px' },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: 'white',
                    },
                    '&:hover fieldset': {
                        borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                    },
                },
                '& .MuiFormControl-root': {
                    mt: isSmallestScreen ? '3px!important' : '16px',
                },
            }}
        >
            <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                error={props.errors.email}
            >
                <OutlinedInput
                    readOnly
                    fullWidth
                    placeholder="Email"
                    id="email"
                    type="email"
                    onChange={props.onChange('email')}
                    value={email}
                />
            </FormControl>
            <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                error={props.errors.password}
            >
                <OutlinedInput
                    type={showPassword ? 'text' : 'password'}
                    placeholder="New Password"
                    id="password"
                    onChange={props.onChange('password')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                disableRipple
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                            >
                                {showPassword ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                error={props.errors.password}
            >
                <OutlinedInput
                    type={showCPassword ? 'text' : 'password'}
                    placeholder="Confirm New Password"
                    id="password_confirmation"
                    onChange={props.onChange('password_confirmation')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                disableRipple
                                aria-label="toggle password visibility"
                                onClick={() => setShowCPassword(!showCPassword)}
                                edge="end"
                            >
                                {showCPassword ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <Box
                mt={isSmallestScreen ? '16px!important' : '33px'}
                className="login-action"
            >
                <Link
                    href="/login"
                    sx={{
                        color: '#D93F21',
                        textDecoration: 'none',
                        lineHeight: 2.5,
                        paddingRight: '20px',
                    }}
                >
                    Login
                </Link>

                <CustomButton
                    isLoading={props.loading}
                    label="Reset"
                    type="submit"
                    sx={{
                        padding: '8px 55px!important',
                        width: isSmallestScreen ? '100%' : 'unset',
                    }}
                    className="upviews-button"
                />
            </Box>
        </Box>
    )
}
