import { Grid } from '@mui/material';
import * as React from 'react';
import AverageRevenueGraph from '../../admin/average-revenue-graph.component';
import AverageSalesGraph from '../../admin/average-sales-graph.component';
import BalanceGraph from '../../admin/balance-graph.component';
import RevenueStatisticsGraph from '../../admin/revenue-statistics-graph.component';
import TotalBalanceGraph from '../../admin/total-balance-graph.component';
import TypeOfViewsGraph from '../../admin/type-of-views-graph.component';

export default function AdminDashboard(props: any) {
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item md={4} sm={12} xs={12}>
          <BalanceGraph
            isLoading={props.isLoading}
            data={
              props.statistics?.balance ?? {
                total: 0,
              }
            }
          />
          <TotalBalanceGraph 
            isLoading={props.isLoading}
            data={
              props.statistics?.balance ?? {
                total: 0,
              }
            }
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <AverageSalesGraph
            isLoading={props.isLoading}
            data={
              props.statistics?.average_sales ?? {
                total: 0,
              }
            }
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <AverageRevenueGraph
            isLoading={props.isLoading}
            data={
              props.statistics?.revenue ?? {
                total: 0,
              }
            }
          />
        </Grid>
        <Grid item md={8} sm={12} xs={12}>
          <RevenueStatisticsGraph
            isLoading={props.isLoading}
            data={
              props.statistics?.revenue ?? {
                total: 0,
              }
            }
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <TypeOfViewsGraph isLoading={props.isLoading} data={props.statistics?.sales_by_type_of_view} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
