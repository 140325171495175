import {
  LOGIN_RECORD_REQUEST,
  LOGIN_RECORD_FAIL,
  LOGIN_RECORD_GET_SUCCESS,
} from '../constants/login-records.constants'
import { LoginRecordsService } from '../services/login-records.service'
import { AppThunk } from '../store'

export const LoginRecordAction = {
    index:
        (
            page: number = 1,
            search: string | null = null,
            sort: string | null = null,
            userId: string| null = null,
        ): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: LOGIN_RECORD_REQUEST,
            })

            await LoginRecordsService.index(page, search, sort, userId)
                .then((response: any) => {
                    let responseData = response?.data
                    if (responseData.data) {
                        return dispatch({
                            type: LOGIN_RECORD_GET_SUCCESS,
                            payload: responseData,
                        })
                    }
                    return response?.data
                })
                .catch(function (error) {
                    dispatch({
                        type: LOGIN_RECORD_FAIL,
                        payload: error?.response?.data,
                    })
                })
        },
}
