import { Avatar, Grid, Paper } from '@mui/material'
import { Box } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
// import Crypto from '../../assets/images/crypto.png'
// import BankTransfer from '../../assets/images/bank-transfer.png'
import MasterCard from '../../assets/images/mastercard.png'
import CharacterImage1 from '../../assets/images/deposit-funds-character-1.png'
import CharacterImage2 from '../../assets/images/deposit-funds-character-2.png'
import CharacterImage3 from '../../assets/images/deposit-funds-character-3.png'
import CharacterImage4 from '../../assets/images/deposit-funds-character-4.png'
import CharacterImage5 from '../../assets/images/deposit-funds-character-5.png'
import CreditCardForm from './credit-card-form.component'
import BankTransferForm from './bank-transfer-form.component'
import CryptoPaymentForm from './crypto-payment-form.component'
import {
    BANK_TRANSFER,
    CREDIT_DEBIT,
    CRYPTO,
} from '../../types/transaction_options.types'
import store from '../../store'
import { useState, SyntheticEvent, ChangeEvent, useEffect } from 'react'
import { TransactionAction } from '../../actions/transaction.action'
import { getCurrentUser } from '../../services/auth.service'

type Props = {
    onClose: any
    isDesktop?: boolean
    transaction: any
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    maxWidth: 858,
    bgcolor: 'background.paper',
    boxShadow: 24,
}

const defaultPaymentOption = {
    cc: true,
    bt: false,
    cp: false,
}

const validateCryptoForm = (payload: any) => {
    let dataFields = [
        {
            key: 'amount',
            isValid: payload.amount > 9,
        },
    ]

    return {
        data: dataFields,
        isValid: dataFields.every((data) => data.isValid),
    }
}

const validateBankTransferForm = (payload: any) => {
    let dataFields = [
        {
            key: 'bank_account_name',
            isValid: payload.data.bank_account_name !== '',
        },
        {
            key: 'bank_account_id',
            isValid:
                !isNaN(parseInt(payload.data.bank_account_id)) &&
                payload.data.bank_account_id.length === 4,
        },
        {
            key: 'reference_id',
            isValid: payload.data.reference_id !== '',
        },
    ]

    return {
        data: dataFields,
        isValid: dataFields.every((data) => data.isValid),
    }
}

const validateCreditForm = (payload: any) => {
    let dataFields = [
        {
            key: 'amount',
            isValid: payload.amount > 9,
        },
    ]

    return {
        data: dataFields,
        isValid: dataFields.every((data) => data.isValid),
    }
}

export default function DepositFundModal(props: Props) {
    const { isDesktop } = props

    const [payment, setPaymentOption] = useState(defaultPaymentOption)

    const cacheUser = getCurrentUser()
    const isVIP = cacheUser?.is_vip ?? false
    const vipId = cacheUser?.vip?.settings?.id ?? 0

    if (isVIP) {
        require(`../../assets/css/vip/${vipId}.css`)
    }

    const setPayment = (paymentOptions: any) => {
        resetErrors()
        setPaymentOption(paymentOptions)
    }

    const [ccPayload, setCCPayload] = useState({
        data: {
            amount: 10,
        },
        amount: 10,
        option: CREDIT_DEBIT,
    })

    const defaultCreditErrors = {
        data: {
            amount: false,
        },
        amount: false,
        option: false,
    }

    const [transferPayload, setTransferPayload] = useState({
        data: {
            bank_account_name: '',
            bank_account_id: '',
            reference_id: '',
        },
        amount: 20000,
        option: BANK_TRANSFER,
    })

    const defaultTransferErrors = {
        data: {
            bank_account_name: false,
            bank_account_id: false,
            reference_id: false,
        },
        amount: false,
        option: false,
    }

    const [transferErrors, setTransferErrors] = useState(defaultTransferErrors)

    const [cryptoPayload, setCryptoPayload] = useState({
        data: {},
        amount: 10,
        option: CRYPTO,
    })
    const defaultCryptoErrors = {
        data: {},
        amount: false,
        option: false,
    }

    const resetErrors = () => {
        setCryptoErrors(defaultCryptoErrors)
        setTransferErrors(defaultTransferErrors)
        setCCErrors(defaultCreditErrors)
    }

    const [cryptoErrors, setCryptoErrors] = useState(defaultCryptoErrors)
    const onSubmitCrypto = async (event: SyntheticEvent) => {
        event.preventDefault()

        const validation = validateCryptoForm(cryptoPayload)

        if (!validation.isValid) {
            setCryptoErrors(
                validation.data.reduce((prevField, field) => {
                    return { ...prevField, [field.key]: !field.isValid }
                }, defaultCryptoErrors)
            )
            return
        }

        store.dispatch(TransactionAction.new(cryptoPayload))
    }

    const onSubmitTransfer = async (event: SyntheticEvent) => {
        event.preventDefault()

        const validation = validateBankTransferForm(transferPayload)

        if (!validation.isValid) {
            setTransferErrors({
                ...transferErrors,
                data: validation.data.reduce((prevField, field) => {
                    return { ...prevField, [field.key]: !field.isValid }
                }, defaultTransferErrors.data),
            })
            return
        }

        store.dispatch(TransactionAction.new(transferPayload))
    }

    const [ccErrors, setCCErrors] = useState(defaultCreditErrors)
    const onSubmitCC = async (event: SyntheticEvent) => {
        event.preventDefault()

        const validation = validateCreditForm(ccPayload)

        if (!validation.isValid) {
            setCCErrors(
                validation.data.reduce((prevField, field) => {
                    return { ...prevField, [field.key]: !field.isValid }
                }, defaultCreditErrors)
            )
            return
        }

        store.dispatch(TransactionAction.new(ccPayload))
    }

    const onChangeCrypto =
        (name: string, customValue?: any) =>
        (event: ChangeEvent<HTMLInputElement>) => {
            let value = customValue ?? event.target.value
            setCryptoPayload((prevState: any) => ({
                ...prevState,
                [name]: value,
            }))
        }

    const onChangeTransfer =
        (name: string, customValue?: any) =>
        (event: ChangeEvent<HTMLInputElement>) => {
            let value = customValue ?? event.target.value
            setTransferPayload((prevState: any) => {
                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        [name]: value,
                    },
                }
            })
        }

    const onChangeCreditDebit =
        (name: string, customValue?: any) =>
        (event: ChangeEvent<HTMLInputElement>) => {
            let value = customValue ?? event.target.value
            setCCPayload((prevState: any) => {
                let keys = name.split('.')

                if (keys.length > 1) {
                    return {
                        ...prevState,
                        data: {
                            ...prevState.data,
                            [keys[1]]: value,
                        },
                    }
                } else {
                    return {
                        ...prevState,
                        [name]: value,
                    }
                }
            })
        }
    const images = [
        {
            image: CharacterImage1,
            right: '-60px',
        },
        {
            image: CharacterImage2,
            right: '-40px',
        },
        {
            image: CharacterImage3,
            right: '-20px',
        },
        {
            image: CharacterImage4,
            right: '-75px',
        },
        {
            image: CharacterImage5,
            right: '-20px',
        },
    ]

    const [imageIndex, setImageIndex] = useState<number>(0)
    useEffect(() => {
        setImageIndex(Math.floor(Math.random() * images.length))
    }, []) //eslint-disable-line

    const selectedImage = images[imageIndex]

    const paymentOptions = (
        <>
            <p className="desc">Payment Options</p>
            <div className="cards">
                <div
                    className={`tour-campaign-5 ${payment.cc ? ' active' : ''}`}
                    onClick={() =>
                        setPayment({ cc: true, bt: false, cp: false })
                    }
                >
                    <img src={MasterCard} alt="" />
                    <p>Credit / Debit</p>
                </div>
                {/* 
                 -- Hide Payment for the Moment ----------
                <div
                    className={payment.bt ? 'active' : ''}
                    onClick={() => {
                        setPayment({ cc: false, bt: true, cp: false })
                    }}
                >
                    <img src={BankTransfer} alt="" />
                    <p>Bank Transfer</p>
                </div> */}
                {/*                 
                <div
                    className={payment.cp ? 'active' : ''}
                    onClick={() =>
                        setPayment({ cc: false, bt: false, cp: true })
                    }
                >
                    <img src={Crypto} alt="" />
                    <p>Crypto</p>
                </div>
                 */}
            </div>
        </>
    )

    return (
        <Box sx={isDesktop ? style : {}} className="deposit-modal-container">
            {isDesktop && (
                <Avatar
                    onClick={props.onClose}
                    sx={{ ...(payment.cp && { top: '220px!important' }) }}
                >
                    <CloseIcon />
                </Avatar>
            )}
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{
                        '& .payment-options-container.desktop-bg': {
                            backgroundPosition: 'bottom left',
                            backgroundRepeat: 'no-repeat',
                        },
                    }}
                >
                    <div
                        className={`payment-options-container${
                            isDesktop ? ' desktop' : ' mobile'
                        }${isVIP ? ' vip ' : ''}`}
                    >
                        {isDesktop ? (
                            <>
                                <h2>Deposit Funds</h2>
                                {paymentOptions}

                                <Paper
                                    sx={{
                                        borderRadius: '40px',
                                        backgroundColor: 'transparent',
                                        boxShadow: 'none',
                                        height: '500px',
                                        position: 'relative',
                                        '& img': {
                                            backgroundColor: 'transparent',
                                            position: 'absolute',
                                            bottom: '0px',
                                            right:
                                                selectedImage?.right ?? '-40px',
                                            height: '90%',
                                            zIndex: '999',
                                        },
                                    }}
                                >
                                    {!isVIP && (
                                        <img src={selectedImage.image} alt="" />
                                    )}
                                </Paper>
                            </>
                        ) : (
                            paymentOptions
                        )}
                    </div>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={7}
                    sx={
                        isDesktop
                            ? { display: 'flex', alignItems: 'center' }
                            : {}
                    }
                >
                    {payment.cc && (
                        <CreditCardForm
                            formProps={{ onSubmit: onSubmitCC }}
                            onChange={onChangeCreditDebit}
                            payload={ccPayload}
                            errors={ccErrors}
                            isDesktop={isDesktop}
                        />
                    )}
                    {payment.bt && (
                        <BankTransferForm
                            formProps={{ onSubmit: onSubmitTransfer }}
                            onChange={onChangeTransfer}
                            errors={transferErrors}
                            isDesktop={isDesktop}
                        />
                    )}
                    {payment.cp && (
                        <CryptoPaymentForm
                            formProps={{ onSubmit: onSubmitCrypto }}
                            onChange={onChangeCrypto}
                            payload={cryptoPayload}
                            errors={cryptoErrors}
                            isDesktop={isDesktop}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}
