import { Box, FormControl, MenuItem, Select } from '@mui/material'
import { IsDesktopScreen } from '../../../helpers/utils.helper'
import CustomButton from '../../customComponents/custom-button.component'
import CustomLabeledFormControl from '../../customComponents/custom-labeled-form-control'
import { KeyboardArrowDown } from '@mui/icons-material'

export default function FilterForm(props: any) {
    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            {...props.formProps}
            sx={{
                '& label': {
                    fontStyle: 'normal',
                    fontSize: 14,
                    fontWeight: 500,
                    color: '#000',
                    '&.Mui-focused': {
                        color: '#000',
                    },
                },
                '& .MuiFormControl-root': {
                    marginTop: '15px',
                },
            }}
        >
            <div className="form-inputs-container">
                <Box
                    sx={{
                        marginTop: '20px',
                        width: '100%',
                        '& .MuiInputBase-root': {
                          marginTop: '10px',
                        }
                    }}
                >
                    <CustomLabeledFormControl
                        label="Keyword"
                        size="small"
                        name="keyword"
                        placeholder="Keyword"
                        value={props.payload.keyword}
                        error={props.errors.keyword}
                        onChange={props.onChange('keyword')}
                    />
                </Box>

                <Box
                    sx={{
                        marginTop: '20px',
                        width: '100%',
                    }}
                >
                    <CustomLabeledFormControl
                        label="Select Category "
                        customChildren={
                            <FormControl
                                fullWidth
                                hiddenLabel
                                variant="outlined"
                                size="small"
                                className="common-styled-select"
                            >
                                <Select
                                  IconComponent={KeyboardArrowDown}
                                  defaultValue={1}
                                  value={props.payload.category}
                                  name="category"
                                  onChange={props.onChange('category')}
                                  error={props.errors.category}
                                >
                                    {props.options.filterCategories &&
                                        props.options.filterCategories.map(
                                            (option: any) => (
                                                <MenuItem
                                                    key={`opt_` + option.id}
                                                    value={option.id}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            )
                                        )}
                                </Select>
                            </FormControl>
                        }
                    />
                </Box>

                <Box
                    sx={{
                        marginTop: '20px',
                        width: '100%',
                    }}
                >
                    <CustomLabeledFormControl
                        label="Type"
                        customChildren={
                            <FormControl
                                fullWidth
                                hiddenLabel
                                variant="outlined"
                                size="small"
                                className="common-styled-select"
                            >
                                <Select
                                    IconComponent={KeyboardArrowDown}
                                    name="type"
                                    value={props.payload.type}
                                    onChange={props.onChange('type')}
                                    error={props.errors.type}
                                >
                                    {props.options.filterTypes &&
                                        props.options.filterTypes.map(
                                            (type: any) => (
                                                <MenuItem
                                                    key={`type_` + type.id}
                                                    value={type.id}
                                                >
                                                    {type.name}
                                                </MenuItem>
                                            )
                                        )}
                                </Select>
                            </FormControl>
                        }
                    />
                </Box>
            </div>
            <div>
                <CustomButton
                    label={props.btnText}
                    type="submit"
                    variant="contained"
                    className="upviews-button"
                    sx={{
                        width: !IsDesktopScreen() ? '100%' : '50%',
                        backgroundColor: '#27A857',
                        borderRadius: '40px',
                        fontWeight: 500,
                        fontSize: '14px',
                        boxShadow: 'none',
                        padding: '10px 20px',
                        color: '#FFFFFF',
                        display: 'block',
                        float: 'right',
                        marginTop: '20px',
                    }}
                />
            </div>
        </Box>
    )
}
