import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { List, ListItem, ListItemButton, ListItemText, Popover } from '@mui/material';
import moment from 'moment';
import RectangularSkeleton from '../skeletons/rectangular.skeleton';
import { DAILY, MONTHLY, WEEKLY, YEARLY } from '../../types/statistics.types';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom' as const,
      align: 'end' as const,
      color: '#3E4259',
      labels: {
        usePointStyle: true,
        boxWidth: 6,
      },
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      min: 0,
      ticks: {
        stepSize: 50,
      },
    },
  },
  pointRadius: 0,
  filler: {
    propagate: false,
  },
  interaction: {
    intersect: false,
  },
};

const currentDate = new Date();
const previousIndicator = currentDate.getMonth() === 0 ? 11 : currentDate.getMonth() - 1;

export function collectCurrentDataSet(data: any, filterBy: string = MONTHLY) {
  return data
    .filter(function (data: any) {
      let currentData = new Date(data.date);
      if (filterBy === DAILY) {
        return moment(currentDate).day() === moment(data.date).day();
      }
      if (filterBy === WEEKLY) {
        return moment(currentDate).week() === moment(data.date).week();
      }
      if (filterBy === YEARLY) {
        return moment(currentDate).year() === moment(data.date).year();
      }
      return currentData.getMonth() === currentDate.getMonth();
    })
    .map((item: any) => item.total);
}

export function collectPreviousDataSet(data: any, filterBy: string = MONTHLY) {
  return data
    .filter(function (data: any) {
      let currentData = new Date(data.date);
      if (filterBy === DAILY) {
        return moment(currentDate).subtract(1, 'days').day() === moment(data.date).day();
      }
      if (filterBy === WEEKLY) {
        return moment(currentDate).subtract(1, 'weeks').week() === moment(data.date).week();
      }
      if (filterBy === YEARLY) {
        return moment(currentDate).subtract(1, 'years').year() === moment(data.date).year();
      }
      return currentData.getMonth() === previousIndicator;
    })
    .map((item: any) => item.total);
}

export function collectLabels(maxDataSet: any) {
  return maxDataSet.map((data: any) => '');
}

export function getLabel(filter: string) {
  if (filter === DAILY) {
    return ['This Day', 'Last Day'];
  }
  if (filter === YEARLY) {
    return ['This Year', 'Last Year'];
  }
  if (filter === WEEKLY) {
    return ['This Week', 'Last Week'];
  }
  return ['This Month', 'Last Month'];
}

export const defaultCurrentDataSet = {
  fillColor: 'yellow',
  label: 'This Month',
  data: [],
  borderColor: '#4169E1',
  backgroundColor: 'rgba(65, 105, 225, 0.12)',
  lineTension: 0.5,
  radius: 7,
  borderWidth: 4,
  fill: true,
};

export const defaultPreviousDataSet = {
  label: 'Last Month',
  data: [],
  borderColor: '#FF4F79',
  backgroundColor: '#FF4F79',
  borderDash: [5, 5],
  lineTension: 0.5,
  radius: 7,
  fill: false,
  borderWidth: 2,
};

export default function RevenueStatisticsGraph(props: any) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState('Monthly');
  const [isLoaded, setIsLoaded] = useState(false);

  const [data, setData] = useState({
    labels: [''],
    datasets: [defaultCurrentDataSet, defaultPreviousDataSet],
  });

  useEffect(() => {
    if ((isLoaded === false && props.data?.data) ?? null) {
      let currentDataSet = collectCurrentDataSet(props.data.data);
      let previousDaSet = collectPreviousDataSet(props.data.data);
      let currentLabels = collectLabels(currentDataSet.length > previousDaSet.length ? currentDataSet : previousDaSet);

      setData({
        labels: currentLabels,
        datasets: [
          { ...defaultCurrentDataSet, data: currentDataSet },
          { ...defaultPreviousDataSet, data: previousDaSet },
        ],
      });

      setIsLoaded(true);
    }
  }, [isLoaded, props, data]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, filter: string) => {
    setFilter(filter);
    let label = getLabel(filter);
    let currentDataSet = collectCurrentDataSet(props.data.data, filter);
    let previousDataSet = collectPreviousDataSet(props.data.data, filter);
    
    setData({
      ...data,
      datasets: [
        { ...data.datasets[0], label: label[0], data: currentDataSet },
        { ...data.datasets[1], label: label[1], data: previousDataSet },
      ],
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'graphs-popover' : undefined;

  return (
    <div className="revenue-statistics-container">
      <div className="header">
        <h2>Revenue Statistics</h2>
        <p aria-describedby={id} onClick={handleClick}>
          <span>{filter}</span> <KeyboardArrowDownIcon />
        </p>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className="popover"
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={(event) => handleFilter(event, DAILY)}>
              <ListItemText primary={DAILY} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={(event) => handleFilter(event, WEEKLY)}>
              <ListItemText primary={WEEKLY} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={(event) => handleFilter(event, MONTHLY)}>
              <ListItemText primary={MONTHLY} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={(event) => handleFilter(event, YEARLY)}>
              <ListItemText primary={YEARLY} />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      {
        props.isLoading
          ? <RectangularSkeleton width='100%' height={320} />
          : <Line options={options} data={data} />
      }
    </div>
  );
}
