import { BLOG_FAIL, BLOG_REQUEST, BLOG_SUCCESS } from "../constants/blog.constants"

type DataBlog = {
    message?: string | null,
    list: any
}

export interface BlogState {
    loading?: boolean
    hasError?: boolean
    data: DataBlog
    blog: any
}

interface Action {
    type: string
    payload?: any
}

export const blogReducer = (
    state: BlogState = {
        loading: false,
        hasError: false,
        blog: null,
        data: {
            message: null,
            list: []
        },
    },
    action: Action
) => {
    switch (action.type) {
        case BLOG_REQUEST:
            return {
                loading: true,
                hasError: false,
                blog: state.blog,
                data: {
                    message: null,
                    list: state.data.list
                },
            }
        case BLOG_SUCCESS:
            return {
                loading: false,
                hasError: false,
                blog: action.payload,
                data: {
                    list: action.payload
                },
            }
        case BLOG_FAIL:
            return {
                loading: false,
                hasError: true,
                blog: state.blog,
                data: { ...state.data, message: action.payload?.message },
            }
        default:
            return state
    }
}
