import API_INSTANCE, { objectToQueryParams } from "./api"
import endpoints from "./endpoints"

export const LoginRecordsService = {
  index: async (
    page: number = 1,
    search: string | null = null,
    sort: string | null = null,
    userId: string | null = null
  ) => {
    let params = {
      per_page: 15,
      page: page,
      search: search,
      sort_by: sort,
      user: userId,
  }

      return API_INSTANCE.get(
          endpoints.GET_LOGIN_RECORDS + '?' + objectToQueryParams(params)
      )
  }
}