import API_INSTANCE from './api'
import endpoints from './endpoints'
import { format } from 'react-string-format'
import axios from 'axios'
import { UserState } from '../reducers/user.reducer'

export const StatisticsService = {
  index: async () => {
    return API_INSTANCE.get(endpoints.GET_STATISTICS)
  },
}

export const WalletService = {
  get: async () => {
    return API_INSTANCE.get(endpoints.GET_WALLET)
  },
}

export const UserService = {
    customerPayload: ['name', 'email', 'gender', 'address', 'phone', 'is_customer'],
    adminPayload: ['name', 'email', 'gender', 'address', 'phone', 'is_customer', 'role'],
    updateUserPayload: ['name', 'email', 'address', 'phone','photo'],
    filterPayload: (isCustomer: boolean, payload: any): any => {
      const validFields = isCustomer
        ? UserService.customerPayload
        : UserService.adminPayload

      return Object.fromEntries(
        Object.entries(payload).filter(([key]) => validFields.includes(key))
      )
    },
    filterUpdateUserPayload: (payload: any): any => {
      const validFields = UserService.updateUserPayload

      return Object.fromEntries(
        Object.entries(payload).filter(([key]) => validFields.includes(key))
      )
    },
    new: async (payload: any) => {
      return API_INSTANCE.post(
        format(endpoints.NEW_PROFILE),
        payload
      )
    },
    password: async (userId: any, payload: any) => {
        return API_INSTANCE.put(
            format(endpoints.UPDATE_PROFILE, userId),
            payload
        )
    },
    update: async (userId: any, payload: any) => {
        return API_INSTANCE.put(
            format(endpoints.UPDATE_PROFILE, userId),
            payload
        )
    },
    uploadPhoto:  async (userId: any, photo: any) => {
        let formData = new FormData();
        formData.append('photo', photo)

        return API_INSTANCE.post(
            format(endpoints.UPDATE_PHOTO, userId),
            formData
        )
    },
    batchUpdate: async (userId: any, payload: any) => {
        const requests = [];
        if(payload.photo){
            requests.push(UserService.uploadPhoto(userId, payload.photo));
        }
        requests.push(UserService.update(userId, payload))
        return axios.all(requests);
    },
}

export const getProfilePic = (user:UserState) => {
    if(!user?.info){
        return ''
    }

    if(user.photo){
        return user.photo
    }

    if(!user.info.avatar){
      return ''
    }

    return require('../assets/images/avatars/' +
    user.info?.avatar +
    '.png')
}
