import { Box } from '@mui/material'
import { IsSmallestScreen } from '../../helpers/utils.helper'
import TextSkeleton from '../skeletons/text.skeleton'

export default function Statistics(props: any) {
    const isSmallestScreen = IsSmallestScreen()
    const isLoading = props.isLoading
    return (
        <Box className="statistics">
            <h2>Your Orders</h2>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: isSmallestScreen
                        ? 'space-between'
                        : 'unset',
                    '& .box': {
                        width: isSmallestScreen ? '49%' : 'unset',
                        minHeight: isSmallestScreen ? '80px' : 'unset',
                        display: isSmallestScreen ? 'flex' : 'unset',
                        flexDirection: isSmallestScreen ? 'column' : 'unset',
                        justifyContent: isSmallestScreen ? 'center' : 'unset',
                    },
                }}
            >
                <Box
                    className="box"
                    sx={{ mr: isSmallestScreen ? '20px!important' : '10px' }}
                >
                    <h4>
                        {isLoading ? (
                            <TextSkeleton />
                        ) : (
                            props.statistics?.running ?? 0
                        )}
                    </h4>
                    <p>Running</p>
                </Box>
                <Box className="box">
                    <h4>
                        {isLoading ? (
                            <TextSkeleton />
                        ) : (
                            props.statistics?.completed ?? 0
                        )}
                    </h4>
                    <p>Completed</p>
                </Box>
            </Box>
        </Box>
    )
}
