import {
  RECOVER_FAIL,
  RECOVER_REQUEST,
  RECOVER_RESET,
  RECOVER_SUCCESS,
} from '../constants/user.constants';

export interface RecoveryState {
  loading?: boolean;
  message?: string;
  showNotification: boolean;
  recoveryRedirection: boolean;
  email?: string;
  hasError: boolean;
}

interface Action {
  type: string;
  payload?: any;
}

export const recoveryReducer = (
  state: RecoveryState = {
    showNotification: false,
    recoveryRedirection: false,
    loading: false,
    hasError: false,
  },
  action: Action
) => {
  switch (action.type) {
    case RECOVER_REQUEST:
      return { loading: true};
    case RECOVER_SUCCESS:
      return {
        loading: false,
        hasError: false,
        message: action.payload?.message ?? 'Email sent.',
        showNotification: true,
        email: action.payload?.email ?? '',
        recoveryRedirection: true,
      };
    case RECOVER_FAIL:
      return {
        loading: false,
        message: action.payload?.message ?? 'Unable to send recovery email.',
        showNotification: true,
        recoveryRedirection: false,
        hasError: true,
      };
    case RECOVER_RESET:
      return {
        loading: false,
        showNotification: false,
      };
    default:
      return state;
  }
};
