import {
    ORDER_ACTION_FAILED,
    ORDER_ACTION_SUCCESS,
    ORDER_ACTION_UPLOAD_FAIL,
    ORDER_ACTION_UPLOAD_REQUEST,
    ORDER_CANCEL_FAILED,
    ORDER_CANCEL_SUCCESS,
    ORDER_FAIL,
    ORDER_GET_SUCCESS,
    ORDER_ISSUE_DOWNLOAD_FAIL,
    ORDER_REQUEST,
    ORDER_RESEND_FAILED,
    ORDER_RESEND_SUCCESS,
    ORDER_SUCCESS,
    ORDER_UPLOAD_FAIL,
    ORDER_UPLOAD_REQUEST,
    ORDER_INITIATE_UPLOAD_REQUEST,
    ORDER_UPDATE_SUCCESS,
    ORDER_UPDATE_FAILED,
} from '../constants/orders.constants'
import { cleanPayload } from '../helpers/statistic.helper'
import { OrderService } from '../services/orders.service'
import { AppThunk } from '../store'

export const OrderAction = {
    new:
        (data: any): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: ORDER_REQUEST,
            })
            let payload = OrderService.filterPayload(data.is_bundle, data)

            await OrderService.new(payload)
                .then((response: any) => {
                    let responseData = response?.data

                    if (payload.is_bundle) {
                        return dispatch({
                            type: ORDER_SUCCESS,
                            payload: responseData,
                        })
                    }

                    if (!(responseData?.data ?? null)) {
                        return response?.data
                    }

                    if ((payload.thumbnails ?? []).length > 0) {
                        return dispatch({
                            type: ORDER_UPLOAD_REQUEST,
                            payload: responseData.data,
                        })
                    }

                    return dispatch({
                        type: ORDER_SUCCESS,
                        payload: responseData.data,
                    })
                })
                .catch(function (error) {
                    dispatch({
                        type: ORDER_FAIL,
                        payload: error?.response?.data,
                    })
                })
        },
    index:
        (
            page: number = 1,
            search: string | null = null,
            sort: string | null = null,
            status: string | string[] | null = null
        ): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: ORDER_REQUEST,
            })

            await OrderService.index(page, search, sort, status)
                .then((response: any) => {
                    let responseData = response?.data
                    if (responseData.data) {
                        return dispatch({
                            type: ORDER_GET_SUCCESS,
                            payload: responseData,
                        })
                    }
                    return response?.data
                })
                .catch(function (error) {
                    dispatch({
                        type: ORDER_FAIL,
                        payload: error?.response?.data,
                    })
                })
        },
    upload:
        (thumbnails: Array<File>, order: any): AppThunk =>
        async (dispatch) => {
            await OrderService.uploadThumb(thumbnails, order.id)
                .then((response: any) => {
                    return dispatch({
                        type: ORDER_SUCCESS,
                        payload: order,
                    })
                })
                .catch(function (error) {
                    dispatch({
                        type: ORDER_UPLOAD_FAIL,
                        payload: order,
                    })
                })
        },
    cancel:
        (orderId: number): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: ORDER_REQUEST,
            })

            await OrderService.cancel(orderId)
                .then((response: any) => {
                    let responseData = response?.data

                    if (responseData.data) {
                        return dispatch({
                            type: ORDER_CANCEL_SUCCESS,
                            payload: responseData,
                        })
                    }
                    return response?.data
                })
                .catch(function (error) {
                    dispatch({
                        type: ORDER_CANCEL_FAILED,
                        payload: error?.response?.data,
                    })
                })
        },
    action:
        (orderId: number, payload: any): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: ORDER_INITIATE_UPLOAD_REQUEST,
            })
            payload = cleanPayload(payload)

            await OrderService.action(orderId, payload)
                .then((response: any) => {
                    let responseData = response?.data

                    if (!responseData?.data) {
                        return response?.data
                    }

                    return dispatch({
                        type: ORDER_ACTION_SUCCESS,
                        payload: responseData,
                    })
                })
                .catch(function (error) {
                    dispatch({
                        type: ORDER_ACTION_FAILED,
                        payload: error?.response?.data,
                    })
                })
        },
    resend:
        (orderId: number): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: ORDER_REQUEST,
            })

            await OrderService.resend(orderId)
                .then((response: any) => {
                    let responseData = response?.data

                    if (responseData.data) {
                        return dispatch({
                            type: ORDER_RESEND_SUCCESS,
                            payload: responseData,
                        })
                    }
                    return response?.data
                })
                .catch(function (error) {
                    dispatch({
                        type: ORDER_RESEND_FAILED,
                        payload: error?.response?.data,
                    })
                })
        },
    actionUpload:
        (issues: Array<File>, orderId: any): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: ORDER_ACTION_UPLOAD_REQUEST,
                payload: orderId,
            })
            await OrderService.uploadIssue(issues, orderId)
                .then((response: any) => {
                    return dispatch({
                        type: ORDER_ACTION_SUCCESS,
                        payload: orderId,
                    })
                })
                .catch(function (error) {
                    dispatch({
                        type: ORDER_ACTION_UPLOAD_FAIL,
                        payload: orderId,
                    })
                })
        },
    downloadIssue:
        (issue: any): AppThunk =>
        async (dispatch) => {
            await OrderService.downloadIssue(issue.id)
                .then((response: any) => {
                    if (response?.data) {
                        const blob = new Blob([response.data], {
                            type: 'application/pdf',
                        })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = issue.original_name
                        link.click()
                    }
                    return response?.data
                })
                .catch(function (error) {
                    dispatch({
                        type: ORDER_ISSUE_DOWNLOAD_FAIL,
                        payload: issue,
                    })
                })
        },
    update:
        (orderId: number, payload: any): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: ORDER_REQUEST,
            })
            payload = cleanPayload(payload)

            await OrderService.update(orderId, payload)
                .then((response: any) => {
                    let responseData = response?.data

                    if (!responseData?.data) {
                        return response?.data
                    }

                    return dispatch({
                        type: ORDER_UPDATE_SUCCESS,
                        payload: responseData,
                    })
                })
                .catch(function (error) {
                    dispatch({
                        type: ORDER_UPDATE_FAILED,
                        payload: error?.response?.data,
                    })
                })
        },
}
