import { Box } from '@mui/system'
import { useSelector } from 'react-redux'
import { ScreenState } from '../../reducers/screen.reducer'
import { RootState } from '../../store'

export default function CustomStatus(props: any) {
    let { options } = useSelector<RootState, ScreenState>(
        (state) => state.screen
    )
    const statuses = options?.statuses || {}

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                '& .status-icon': {
                    height: '8px',
                    width: '8px',
                    border: '3px solid #FF4848',
                    borderRadius: '50%',
                    mr: '5px',
                    mb: '4px',
                },
                '&.refunded': {
                    color: '#FF9548',
                    '& .status-icon': { borderColor: '#FF9548' },
                },
                '&.cancelled': {
                    color: '#FF4848',
                    '& .status-icon': { borderColor: '#FF4848' },
                },
                '&.completed': {
                    color: '#27A857',
                    '& .status-icon': { borderColor: '#27A857' },
                },
                '&.in_progress': {
                    color: '#48BDFF',
                    '& .status-icon': { borderColor: '#48BDFF' },
                },
                '&.pending': {
                    color: '#FF9548',
                    '& .status-icon': { borderColor: '#FF9548' },
                },
            }}
            className={props.status}
        >
            <div className="status-icon"></div> {statuses[props.status] ?? ''}
        </Box>
    )
}
