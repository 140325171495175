import { OPTIONS_FAIL, OPTIONS_REQUEST, OPTIONS_SUCCESS } from '../constants/screen.constants';
import { OptionsService } from '../services/screen.services';
import { AppThunk } from '../store';

export const getOptions = (): AppThunk => async (dispatch) => {
  dispatch({
    type: OPTIONS_REQUEST,
  });

  await OptionsService()
    .then((response) => {
      let responseData = response?.data;
      if (responseData.data) {
        return dispatch({
          type: OPTIONS_SUCCESS,
          payload: responseData.data,
        });
      }
      return response?.data;
    })
    .catch(function (error) {
      dispatch({
        type: OPTIONS_FAIL,
        payload: error,
      });
    });
};

export const failNotification =
  (message: string): AppThunk =>
    async (dispatch) => {
      dispatch({
        type: OPTIONS_FAIL,
        payload: message,
      });
    };
