import { Box, Container } from '@mui/material'
import { useState, useEffect } from 'react'
import store, { RootState } from '../store'
import { OrderAction } from '../actions/orders.action'
import { useSelector } from 'react-redux'
import { OrderState } from '../reducers/orders.reducer'
import { UserState } from '../reducers/user.reducer'
import CustomerOrders from '../components/dashboard/customer/orders.component'
import { TYPE_SUPER_ADMIN, TYPE_WORKER } from '../types/user.types'
import AdminOrders from '../components/dashboard/admin/orders.component'
import Notification from '../components/notification.components'
import { ORDER_RESET } from '../constants/orders.constants'

const OrdersScreen = (props: any) => {
    const user = useSelector<RootState, UserState>((state) => state.userLogin)
    const order = useSelector<RootState, OrderState>((state) => state.order)
    const [page, setPage] = useState(1)
    const [searchTerm, setSearchTerm] = useState('')
    const [sortBy, setSortBy] = useState('-created_at')
    const [filteredStatus, setFilteredStatus] = useState<string | string[]>('')
    const [isWorker, setIsWorker] = useState(false)
    const [isSuperAdmin, setIsSuperAdmin] = useState(false)
    const [isCustomer, setIsCustomer] = useState(false)
    const isLoading = props.isLoading || order.loading

    useEffect(() => {
        if (user.info) {
            let roles = user?.info?.roles ?? []
            setIsSuperAdmin(roles.includes(TYPE_SUPER_ADMIN))
            setIsWorker(roles.includes(TYPE_WORKER))
            setIsCustomer(roles.length === 0)
        }
    }, [user, isSuperAdmin, isWorker, isCustomer])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            store.dispatch(
                OrderAction.index(page, searchTerm, sortBy, filteredStatus)
            )
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [page, searchTerm, sortBy, filteredStatus])

    useEffect(() => {
        props.getScreenTitle(isCustomer ? 'Your Orders' : 'Orders')
    }, [isCustomer]) // eslint-disable-line

    const onPageChange = (page: number) => {
        setPage(page)
    }

    const onSort = (value: string) => {
        setSortBy(value)
    }

    const onChangeStatus = (value: string | string[]) => {
        setFilteredStatus(value)
    }

    const cancelOrder = (orderId: number) => {
        store.dispatch(OrderAction.cancel(orderId))
    }

    const resendOrder = (orderId: number) => {
        store.dispatch(OrderAction.resend(orderId))
    }

    return (
        <Box
            className="orders"
            sx={{ mb: isCustomer && !props.isDesktop ? '50px' : 'unset' }}
        >
            <Container className="container">
                {isCustomer && (
                    <CustomerOrders
                        isLoading={isLoading}
                        list={order.data?.list?.data ?? []}
                        meta={order.data?.list?.meta ?? { last_page: 0 }}
                        page={page}
                        onPageChange={onPageChange}
                        getRightNav={props.getRightNav}
                        showRightSideBar={props.showRightSideBar}
                        isDesktop={props.isDesktop}
                    />
                )}
                {(isSuperAdmin || isWorker) && (
                    <AdminOrders
                        isLoading={isLoading}
                        list={order.data?.list?.data ?? []}
                        meta={order.data?.list?.meta ?? { last_page: 0 }}
                        page={page}
                        onSearch={setSearchTerm}
                        onSort={onSort}
                        onChangeStatus={onChangeStatus}
                        onPageChange={onPageChange}
                        cancelOrder={cancelOrder}
                        resendOrder={resendOrder}
                        isDesktop={props.isDesktop}
                    />
                )}
                <Notification
                    message={order.data?.message ?? 'An error has occurred.'}
                    open={order.showNotification}
                    severity={order.hasError ? 'error' : 'success'}
                    onClose={() =>
                        store.dispatch({
                            type: ORDER_RESET,
                        })
                    }
                />
            </Container>
        </Box>
    )
}

export default OrdersScreen
