import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react'
import {
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import _ from 'lodash'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

import { DataPropsAny } from './table-wrapper.component'
import TextSkeleton from '../skeletons/text.skeleton'
import CustomButton from '../customComponents/custom-button.component'

const MobileTable = (props: DataPropsAny) => {
    const {
        body,
        onGetCollapsibleComponent = () => {},
        responsiveHeaders,
        onAddRecord,
        entityLabel,
        isCollapsible,
        setCollapsedRow,
        collapsedRow,
        columnTotal,
        headers,
        mobileActionIcon,
        excludeHeaders = [],
        isLoading,
        hasContextMenu,
        isContextMenuOpen,
        handleOpenContextMenu,
        contextMenuIcon,
        anchorEl,
        handleCloseContextMenu,
        contextMenu,
        activeContextRow,
        contextMenuButtonStyles,
        pageCount,
    } = props

    const scorllableElement: any = useRef()
    const filteredHeaders = headers.filter(
        (header: any) => !excludeHeaders.includes(header.key)
    )

    const [distanceBottom, setDistanceBottom] = useState(0)
    const [isRequesting, setIsRequesting] = useState(false)
    const [rows, setRows] = useState(body)

    useEffect(() => {
        if (pageCount === 1 && isLoading) {
            setRows([])
        } else {
            setRows(_.uniqBy([...rows, ...body], 'id'))
        }
    }, [body, isLoading]) // eslint-disable-line

    const hasMore =
        props.pageCount > props.currentPage && rows.length < props.total

    const loadMore = useCallback(() => {
        ;(async () => {
            await new Promise<void>((resolve) =>
                setTimeout(() => {
                    props.onPageChange(props.currentPage + 1)
                    setIsRequesting(false)
                    resolve()
                }, 2000)
            )
        })()
        setIsRequesting(true)
    }, [rows]) // eslint-disable-line

    const scrollListener = useCallback(() => {
        let bottom =
            scorllableElement.current.scrollHeight -
            scorllableElement.current.clientHeight
        if (!distanceBottom) {
            setDistanceBottom(Math.round((bottom / 100) * 20))
        }
        if (
            scorllableElement.current.scrollTop > bottom - distanceBottom &&
            hasMore &&
            !isRequesting
        ) {
            loadMore()
        }
    }, [hasMore, loadMore, isRequesting, distanceBottom])

    useLayoutEffect(() => {
        const tableRef = scorllableElement.current
        tableRef.addEventListener('scroll', scrollListener)
        return () => {
            tableRef.removeEventListener('scroll', scrollListener)
        }
    }, [scrollListener])

    let tableData = rows

    if (isLoading) {
        const loadingData = headers.reduce(
            (acc: any, curr: any) => ({ ...acc, [curr.key]: '' }),
            {}
        )
        tableData = Array(1)
            .fill(null)
            .map(() => loadingData)
    }

    return (
        <TableContainer
            ref={scorllableElement}
            sx={{
                height: rows.length !== 0 ? '100vh' : 'unset',
                overflow: 'auto',
            }}
        >
            <Table
                aria-labelledby="tableTitle"
                sx={{ borderSpacing: '0px 10px', borderCollapse: 'separate' }}
            >
                <TableBody sx={{ bgcolor: 'none' }}>
                    {tableData.map((data: DataPropsAny, index1: number) => {
                        const isContextMenuActive =
                            activeContextRow?.id === data.id
                        return (
                            <React.Fragment key={`fragment-${index1}`}>
                                <TableRow
                                    key={`mobile-body-row-${index1}`}
                                    className={`preview`}
                                >
                                    {responsiveHeaders &&
                                        responsiveHeaders.map(
                                            (
                                                header: DataPropsAny,
                                                index2: number
                                            ) => {
                                                const hasCustomValue =
                                                    header.customValue
                                                return (
                                                    <TableCell
                                                        sx={{
                                                            p: '0px 8px 8px 8px',
                                                        }}
                                                        key={`mobile-data-${index1}-${index2}`}
                                                        style={
                                                            header.customRowStyle
                                                        }
                                                    >
                                                        <Box
                                                            sx={{
                                                                p: '16px',
                                                                bgcolor: '#fff',
                                                                borderRadius:
                                                                    '20px',
                                                                boxShadow:
                                                                    '0px 0px 14px -3px rgb(0 0 0 / 10%)',
                                                            }}
                                                        >
                                                            <Box
                                                                width="100%"
                                                                borderBottom="1px solid #CBCBCB"
                                                                pb={
                                                                    isCollapsible
                                                                        ? '15px'
                                                                        : '20px'
                                                                }
                                                                display="flex"
                                                                justifyContent="space-between"
                                                                alignItems="flex-start"
                                                            >
                                                                <Box width="100%">
                                                                    {!hasCustomValue && (
                                                                        <Box>
                                                                            {
                                                                                header.label
                                                                            }
                                                                        </Box>
                                                                    )}
                                                                    <Box
                                                                        sx={{
                                                                            width: '100%',
                                                                            position:
                                                                                'relative',
                                                                            '& span':
                                                                                {
                                                                                    width: '100%',
                                                                                    overflow:
                                                                                        'hidden',
                                                                                    whiteSpace:
                                                                                        'pre',
                                                                                    textOverflow:
                                                                                        'ellipsis',
                                                                                    position:
                                                                                        'absolute',
                                                                                    left: 0,
                                                                                },
                                                                        }}
                                                                    >
                                                                        {isLoading ? (
                                                                            <TextSkeleton />
                                                                        ) : header.customValue ? (
                                                                            header.customValue(
                                                                                data
                                                                            )
                                                                        ) : (
                                                                            <span>
                                                                                {
                                                                                    data[
                                                                                        header
                                                                                            .key
                                                                                    ]
                                                                                }
                                                                            </span>
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                                {isCollapsible && (
                                                                    <Box>
                                                                        <IconButton
                                                                            key={`mobile-collapse-button-${index1}`}
                                                                            aria-label="expand row"
                                                                            size="small"
                                                                            sx={{
                                                                                pl: '10px',
                                                                            }}
                                                                            disabled={
                                                                                isLoading
                                                                            }
                                                                            onClick={() =>
                                                                                setCollapsedRow(
                                                                                    {
                                                                                        ...collapsedRow,
                                                                                        [data.id]:
                                                                                            !collapsedRow[
                                                                                                data
                                                                                                    .id
                                                                                            ],
                                                                                    }
                                                                                )
                                                                            }
                                                                        >
                                                                            {collapsedRow[
                                                                                data
                                                                                    .id
                                                                            ] ? (
                                                                                <RemoveIcon className="remove" />
                                                                            ) : (
                                                                                <AddIcon className="add" />
                                                                            )}
                                                                        </IconButton>
                                                                    </Box>
                                                                )}
                                                                {mobileActionIcon &&
                                                                    !isLoading && (
                                                                        <Box>
                                                                            {mobileActionIcon(
                                                                                data
                                                                            )}
                                                                        </Box>
                                                                    )}
                                                                {hasContextMenu && (
                                                                    <Box
                                                                        sx={{
                                                                            ...(contextMenuButtonStyles ||
                                                                                {}),
                                                                            mt: '4px',
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            key={`context-menu-button-${index1}`}
                                                                            id={`context-menu-button-${index1}`}
                                                                            aria-controls={
                                                                                isContextMenuOpen
                                                                                    ? `context-menu-${index1}`
                                                                                    : undefined
                                                                            }
                                                                            aria-haspopup="true"
                                                                            aria-expanded={
                                                                                isContextMenuOpen
                                                                                    ? 'true'
                                                                                    : undefined
                                                                            }
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                handleOpenContextMenu(
                                                                                    e,
                                                                                    data
                                                                                )
                                                                            }
                                                                        >
                                                                            {contextMenuIcon ? (
                                                                                contextMenuIcon
                                                                            ) : (
                                                                                <MoreVertRoundedIcon />
                                                                            )}
                                                                        </Button>
                                                                        {isContextMenuActive && (
                                                                            <Popover
                                                                                id={`context-menu-${index1}`}
                                                                                anchorEl={
                                                                                    anchorEl
                                                                                }
                                                                                open={
                                                                                    isContextMenuOpen
                                                                                }
                                                                                onClose={
                                                                                    handleCloseContextMenu
                                                                                }
                                                                                anchorOrigin={{
                                                                                    vertical:
                                                                                        'bottom',
                                                                                    horizontal:
                                                                                        'right',
                                                                                }}
                                                                                transformOrigin={{
                                                                                    vertical:
                                                                                        'top',
                                                                                    horizontal:
                                                                                        'right',
                                                                                }}
                                                                                sx={{
                                                                                    '& .MuiPopover-paper':
                                                                                        {
                                                                                            mt: '4px',
                                                                                            ml: '10px',
                                                                                            borderRadius:
                                                                                                '10px',
                                                                                        },
                                                                                    '& .MuiList-root':
                                                                                        {
                                                                                            paddingTop:
                                                                                                '5px',
                                                                                            paddingBottom:
                                                                                                '5px',
                                                                                            '& li': {
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                                padding:
                                                                                                    '5px 15px',
                                                                                                '&:hover':
                                                                                                    {
                                                                                                        backgroundColor:
                                                                                                            '#FFE2E2',
                                                                                                        color: '#FF4848',
                                                                                                        cursor: 'pointer',
                                                                                                        '& svg':
                                                                                                            {
                                                                                                                color: '#FF4848',
                                                                                                            },
                                                                                                    },
                                                                                                '& svg':
                                                                                                    {
                                                                                                        color: '#BABEC8',
                                                                                                        fontSize:
                                                                                                            '21px',
                                                                                                    },
                                                                                                '& .MuiTypography-root':
                                                                                                    {
                                                                                                        fontSize:
                                                                                                            '14px',
                                                                                                    },
                                                                                                '& .MuiListItemIcon-root':
                                                                                                    {
                                                                                                        minWidth:
                                                                                                            '30px',
                                                                                                    },
                                                                                            },
                                                                                        },
                                                                                }}
                                                                            >
                                                                                <List
                                                                                    key={`context-list-${index1}`}
                                                                                >
                                                                                    {contextMenu.map(
                                                                                        (
                                                                                            context: DataPropsAny,
                                                                                            index: number
                                                                                        ) => {
                                                                                            return (
                                                                                                ((context.value &&
                                                                                                    context.value !==
                                                                                                        data.type) ||
                                                                                                    !context.value) && (
                                                                                                    <ListItem
                                                                                                        key={`context-list-item-${index}`}
                                                                                                        disablePadding
                                                                                                        onClick={() => {
                                                                                                            handleCloseContextMenu()
                                                                                                            context.callBack(
                                                                                                                data
                                                                                                            )
                                                                                                        }}
                                                                                                    >
                                                                                                        <ListItemIcon>
                                                                                                            {
                                                                                                                context.icon
                                                                                                            }
                                                                                                        </ListItemIcon>
                                                                                                        <ListItemText>
                                                                                                            {
                                                                                                                context.label
                                                                                                            }
                                                                                                        </ListItemText>
                                                                                                    </ListItem>
                                                                                                )
                                                                                            )
                                                                                        }
                                                                                    )}
                                                                                </List>
                                                                            </Popover>
                                                                        )}
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                            <Box mt="15px">
                                                                {isCollapsible &&
                                                                    onGetCollapsibleComponent(
                                                                        data,
                                                                        collapsedRow[
                                                                            data
                                                                                .id
                                                                        ]
                                                                    )}
                                                                {!isCollapsible &&
                                                                    filteredHeaders
                                                                        .filter(
                                                                            (
                                                                                h: any
                                                                            ) =>
                                                                                !responsiveHeaders.find(
                                                                                    (
                                                                                        rh: any
                                                                                    ) =>
                                                                                        rh.key ===
                                                                                        h.key
                                                                                )
                                                                        )
                                                                        .map(
                                                                            (
                                                                                header: any,
                                                                                hIndex: number
                                                                            ) => {
                                                                                return (
                                                                                    <Box
                                                                                        key={`collpasible-data-${hIndex}`}
                                                                                        display="flex"
                                                                                        justifyContent="space-between"
                                                                                        pb="10px"
                                                                                    >
                                                                                        <div>
                                                                                            {
                                                                                                header.label
                                                                                            }
                                                                                        </div>
                                                                                        <div>
                                                                                            {isLoading ? (
                                                                                                <TextSkeleton width="100px" />
                                                                                            ) : header.customValue ? (
                                                                                                header.customValue(
                                                                                                    data
                                                                                                )
                                                                                            ) : (
                                                                                                data[
                                                                                                    header
                                                                                                        .key
                                                                                                ]
                                                                                            )}
                                                                                        </div>
                                                                                    </Box>
                                                                                )
                                                                            }
                                                                        )}
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                )
                                            }
                                        )}
                                </TableRow>
                            </React.Fragment>
                        )
                    })}
                    {rows.length === 0 && !isLoading && (
                        <TableRow className="preview">
                            <TableCell colSpan={columnTotal} align="center">
                                <Box textAlign="center" py={10}>
                                    No {_.startCase(entityLabel)} Yet! Get the
                                    marketing started:
                                    {onAddRecord && (
                                        <Box
                                            sx={{
                                                '& button': {
                                                    textTransform: 'unset',
                                                    color: '#fff',
                                                    backgroundColor: '#27A857',
                                                    borderRadius: '30px',
                                                    fontWeight: '700',
                                                    fontSize: '18px',
                                                    padding: '10px 20px',
                                                    marginTop: '20px',
                                                },
                                            }}
                                        >
                                            <CustomButton
                                                label={`Add ${_.startCase(
                                                    entityLabel
                                                )} Now`}
                                                className="upviews-button"
                                                onClick={() => onAddRecord()}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default MobileTable
