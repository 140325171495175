import { Grid } from '@mui/material'
import { TYPES_CONST } from '../env'
import Logo from '../assets/images/logo.svg'
import { Box } from '@mui/system'
import { IsDesktopScreen, IsSmallestScreen } from '../helpers/utils.helper'
import CustomButton from '../components/customComponents/custom-button.component'
import store, { RootState } from '../store'
import { RecoveryState } from '../reducers/recovery.reducer'
import { useSelector } from 'react-redux'
import { recovery } from '../actions/auth.action'
import Notification from '../components/notification.components'
import { RECOVER_RESET } from '../constants/user.constants'

const ForgotPasswordConfirmation = (props: any) => {
    const auth = useSelector<RootState, RecoveryState>(
        (state) => state.recovery
    )

    const isDesktop = IsDesktopScreen()
    const isSmallestScreen = IsSmallestScreen()

    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email')


    const resendLink = () => {
      if(email) {
        store.dispatch(recovery(email))
      }
    }

    
    return (
        <Grid container className="login-body" sx={{ overflow: 'auto' }}>
            <Grid item md={7} sx={{ width: '100%' }}>
                <Grid container className="login-nav">
                    <Grid item md={6} xs={12} sm={12}>
                        <a href={TYPES_CONST.MARKETING_URL}>
                            <img src={Logo} alt="Upviews" />
                        </a>
                    </Grid>
                    <Grid item md={6} className="log-action"></Grid>

                    <Grid
                        item
                        md={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                width: isDesktop ? '400px' : '100%',
                                margin: isSmallestScreen ? '5px' : 'unset',
                            }}
                            className="form-container"
                        >
                            <Box
                                mb={
                                    isSmallestScreen ? '60px!important' : '37px'
                                }
                                className="login-header"
                            >
                                <h4>We’ve sent an email!</h4>
                                <div className="sub-title">
                                    We sent an email containing the password
                                    recovery link to <br />
                                    <b>“{email ?? ''}”</b>
                                </div>
                            </Box>
                            <Box sx={{ textAlign: 'center' }}>
                                <a
                                    style={{
                                        backgroundColor: '#27A857',
                                        borderRadius: '40px',
                                        fontWeight: 500,
                                        fontSize: '18px',
                                        boxShadow: 'none',
                                        color: '#fff',
                                        textTransform: 'capitalize',
                                        padding: '15px 55px',
                                        width: isSmallestScreen
                                            ? '100%'
                                            : 'unset',
                                        textDecoration: 'none',
                                    }}
                                    href="/login"
                                >
                                    Sign In
                                </a>
                            </Box>
                            <Box sx={{ textAlign: 'center' }}>
                                <p
                                    style={{
                                        marginBottom: '20px',
                                        marginTop: '50px',
                                    }}
                                >
                                    Didn’t see any emails?
                                    <br />
                                    Try checking your spam folder or
                                </p>
                                
                                <CustomButton
                                    onClick={() => resendLink()}
                                    isLoading={auth.loading}
                                    label="Resend Link"
                                    type="submit"
                                    sx={{
                                        padding: '8px 55px!important',
                                        width: isSmallestScreen
                                            ? '100%'
                                            : 'unset',
                                    }}
                                    className="upviews-button-outline"
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={5} className="forgot-password-confirmation-person">
                {/* Background here */}
            </Grid>
            <Notification
                message={auth.message}
                open={auth.showNotification}
                severity={auth.hasError ? 'error' : 'success'}
                onClose={() =>
                    store.dispatch({
                        type: RECOVER_RESET,
                    })
                }
            />
        </Grid>
    )
}

export default ForgotPasswordConfirmation
