import {
    LOGIN_RECORD_FAIL,
    LOGIN_RECORD_GET_SUCCESS,
    LOGIN_RECORD_REQUEST,
} from '../constants/login-records.constants'

type DataRecord = {
    message?: string | null
    list: {
        data: Array<object>
        meta: object | null
    }
}

export interface RecordState {
    loading?: boolean
    showNotification: boolean
    hasError?: boolean
    data: DataRecord
    loginRecords: any
}

interface Action {
    type: string
    payload?: any
}

export const recordsReducer = (
    state: RecordState = {
        loading: false,
        showNotification: false,
        hasError: false,
        loginRecords: null,
        data: {
            message: null,
            list: {
                data: [],
                meta: null,
            },
        },
    },
    action: Action
) => {
    switch (action.type) {
        case LOGIN_RECORD_REQUEST:
            return {
                loading: true,
                showNotification: false,
                hasError: false,
                loginRecords: state.loginRecords,
                data: {
                    message: null,
                    list: state.data.list,
                },
            }
        case LOGIN_RECORD_GET_SUCCESS:
            return {
                loading: false,
                showNotification: false,
                hasError: false,
                loginRecords: action.payload,
                data: { list: action.payload },
            }
        case LOGIN_RECORD_FAIL:
            return {
                loading: false,
                showNotification: true,
                hasError: true,
                loginRecords: state.loginRecords,
                data: { ...state.data, message: action.payload?.message },
            }
        default:
            return state
    }
}
