import {
    IPINFO_FAIL,
    IPINFO_REQUEST,
    IPINFO_SUCCESS,
} from '../constants/ipinfo.constants'

type DataIpInfo = {
    message?: string | null
    details: any
}

export interface IpInfoState {
    loading?: boolean
    hasError?: boolean
    data: DataIpInfo
}

interface Action {
    type: string
    payload?: any
}

export const ipInfoReducer = (
    state: IpInfoState = {
        loading: false,
        hasError: false,
        data: {
            message: null,
            details: null,
        },
    },
    action: Action
) => {
    switch (action.type) {
        case IPINFO_REQUEST:
            return {
                loading: true,
                hasError: false,
                data: {
                    message: null,
                    details: state.data.details,
                },
            }
        case IPINFO_SUCCESS:
            return {
                loading: false,
                hasError: false,
                data: {
                    details: action.payload,
                },
            }
        case IPINFO_FAIL:
            return {
                loading: false,
                hasError: true,
                data: { ...state.data, message: action.payload?.message },
            }
        default:
            return state
    }
}
