import {
    TRANSACTION_APPROVED,
    TRANSACTION_CANCELLED,
    TRANSACTION_FAIL,
    TRANSACTION_GET_SUCCESS,
    TRANSACTION_LIST_FAIL,
    TRANSACTION_REQUEST,
    TRANSACTION_RESET,
    TRANSACTION_SUCCESS,
    TRANSACTION_UPDATE_FAIL,
} from '../constants/transaction.constants'

type DataTransaction = {
    message?: string | null
    list: {
        data: Array<object>
        meta: object | null
    }
}

export interface TransactionState {
    loading?: boolean
    showNotification: boolean
    showModal: boolean
    hasError?: boolean
    data: DataTransaction
    transaction: any
}

interface Action {
    type: string
    payload?: any
}

export const transactionReducer = (
    state: TransactionState = {
        loading: false,
        showNotification: false,
        showModal: false,
        hasError: false,
        data: {
            message: null,
            list: {
                data: [],
                meta: null,
            },
        },
        transaction: null,
    },
    action: Action
) => {
    switch (action.type) {
        case TRANSACTION_REQUEST:
            return {
                loading: true,
                showNotification: false,
                showModal: false,
                hasError: false,
                transaction: state.transaction,
                data: state.data,
            }
        case TRANSACTION_GET_SUCCESS:
            return {
                loading: false,
                showNotification: false,
                showModal: false,
                hasError: false,
                transaction: action.payload,
                data: { list: action.payload },
            }
        case TRANSACTION_SUCCESS:
            return {
                loading: false,
                showNotification: false,
                showModal: true,
                hasError: false,
                transaction: action.payload,
                data: state.data,
            }
        case TRANSACTION_LIST_FAIL:
            return {
                loading: false,
                showNotification: false,
                showModal: true,
                hasError: true,
                transaction: action.payload,
                data: state.data,
            }
        case TRANSACTION_FAIL:
            return {
                loading: false,
                showNotification: false,
                showModal: false,
                hasError: true,
                transaction: action.payload,
                data: state.data,
            }
        case TRANSACTION_RESET:
            return {
                loading: false,
                showNotification: false,
                showModal: false,
                hasError: false,
                transaction: state.transaction,
                data: state.data,
            }
        case TRANSACTION_APPROVED:
            return {
                loading: false,
                showNotification: true,
                showModal: false,
                hasError: false,
                transaction: action.payload,
                data: {
                    ...state.data,
                    list: {
                        ...state.data.list,
                        data: state.data.list.data.map((transaction: any) =>
                            transaction.id === (action.payload?.id ?? 0)
                                ? action.payload
                                : transaction
                        ),
                    },
                    message: 'Transaction successfully approved.',
                },
            }
        case TRANSACTION_CANCELLED:
            return {
                loading: false,
                showNotification: true,
                showModal: false,
                hasError: false,
                transaction: action.payload,
                data: {
                    ...state.data,
                    list: {
                        ...state.data.list,
                        data: state.data.list.data.map((transaction: any) =>
                            transaction.id === (action.payload?.id ?? 0)
                                ? action.payload
                                : transaction
                        ),
                    },
                    message: 'Transaction successfully cancelled.',
                },
            }
        case TRANSACTION_UPDATE_FAIL:
            return {
                loading: false,
                showNotification: true,
                showModal: false,
                hasError: true,
                transaction: action.payload,
                data: {
                    ...state.data,
                    message:
                        'Error encountered while updating the transaction.',
                },
            }
        default:
            return state
    }
}
