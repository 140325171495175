import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { BLOCKED_COUNTRIES } from "../constants/ipinfo.constants";
import { TYPES_CONST } from "../env";

export const IpInfoService = {
  apiInstance: () => {
    const config: AxiosRequestConfig = {
      baseURL: 'https://ipinfo.io/',
      timeout: 10000
    }

    const API_INSTANCE: AxiosInstance = axios.create(config);

    return API_INSTANCE
  },
  getIpInfo: async () => {
    return IpInfoService.apiInstance().get('json?token=' + TYPES_CONST.IPINFO_KEY);
  },
  getInfoByIpAddress: async (ipAddress: string) => {
    return IpInfoService.apiInstance().get(ipAddress+'?token=' + TYPES_CONST.IPINFO_KEY);
  },
  getSavedIpInfo: () => {
    return localStorage.getItem('ipInfo')
  },
  isBlocked: (ipInfo: any) => {
    return BLOCKED_COUNTRIES.includes(ipInfo.country)
  }
}