import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Container } from '@mui/material'
import { useSelector } from 'react-redux'

import store, { RootState } from '../store'
import SettingsUser from '../components/dashboard/admin/settings-user.component'
import SettingsApi from '../components/dashboard/admin/settings-api.component'
import { CustomerState } from '../reducers/customers.reducer'
import { CustomerAction } from '../actions/customer.action'
import Notification from '../components/notification.components'
import { CUSTOMER_RESET } from '../constants/customer.constants'
import { ScreenState } from '../reducers/screen.reducer'
import { ApiProviderState } from '../reducers/api-providers.reducer'
import { ApiProvidersAction } from '../actions/api-providers.action'
import { API_PROVIDER_RESET } from '../constants/api-providers.constants'

const SettingsScreen = (props: any) => {
    const { type } = useParams()
    const isUserSettings = type === 'user'
    const { options } = useSelector<RootState, ScreenState>(
        (state) => state.screen
    )
    const apiProvider = useSelector<RootState, ApiProviderState>(
        (state) => state.apiProviders
    )
    const customer = useSelector<RootState, CustomerState>(
        (state) => state.customer
    )
    const isLoading = props.isLoading || customer.loading

    const [page, setPage] = useState(1)
    const [searchTerm, setSearchTerm] = useState('')
    const [sortBy, setSortBy] = useState('created_at')
    const [apiPage, setApiPage] = useState(1)

    useEffect(() => {
        props.getScreenTitle(
            `Settings - ${isUserSettings ? 'User' : 'API'} Settings`
        )
    }, []) // eslint-disable-line

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            store.dispatch(CustomerAction.index(page, searchTerm, sortBy))
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [page, searchTerm, sortBy])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            store.dispatch(ApiProvidersAction.index(apiPage))
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [apiPage])

    const onApiPageChange = (page: number) => {
        setApiPage(page)
    }

    const onPageChange = (page: number) => {
        setPage(page)
    }

    const onSort = (value: string) => {
        setSortBy(value)
    }

    const banUserIp = (userId: number) => {
        store.dispatch(CustomerAction.banUserIp(userId))
    }

    const banUserAccount = (userId: number) => {
        store.dispatch(CustomerAction.banUserAccount(userId))
    }

    const pauseUserAccount = (userId: number) => {
        store.dispatch(CustomerAction.pauseUserAccount(userId))
    }

    const activateAccount = (userId: number) => {
        store.dispatch(CustomerAction.activateAccount(userId))
    }

    const editApi = (apiId: number) => {}

    return (
        <div>
            <Container className="container">
                {isUserSettings ? (
                    <SettingsUser
                        list={customer.data?.list?.data ?? []}
                        meta={customer.data?.list?.meta ?? { last_page: 0 }}
                        page={page}
                        onSearch={setSearchTerm}
                        onSort={onSort}
                        onPageChange={onPageChange}
                        banUserIp={banUserIp}
                        banUserAccount={banUserAccount}
                        pauseUserAccount={pauseUserAccount}
                        activateAccount={activateAccount}
                        options={options}
                        isLoading={isLoading}
                        isDesktop={props.isDesktop}
                    />
                ) : (
                    <SettingsApi
                        list={apiProvider.data?.list?.data ?? []}
                        meta={apiProvider.data?.list?.meta ?? { last_page: 0 }}
                        page={page}
                        isLoading={isLoading}
                        onPageChange={onApiPageChange}
                        editApi={editApi}
                        getRightNav={props.getRightNav}
                        showRightSideBar={props.showRightSideBar}
                        isDesktop={props.isDesktop}
                    />
                )}
            </Container>
            <Notification
                message={customer.data?.message ?? 'An error has occurred.'}
                open={customer.showNotification}
                severity={customer.hasError ? 'error' : 'success'}
                onClose={() =>
                    store.dispatch({
                        type: CUSTOMER_RESET,
                    })
                }
            />

            <Notification
                message={apiProvider.data?.message ?? 'An error has occurred.'}
                open={apiProvider.showNotification}
                severity={apiProvider.hasError ? 'error' : 'success'}
                onClose={() =>
                    store.dispatch({
                        type: API_PROVIDER_RESET,
                    })
                }
            />
        </div>
    )
}

export default SettingsScreen
