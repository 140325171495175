import { Paper, Rating } from '@mui/material'
import Carousel from 'react-material-ui-carousel'

import Testimony1 from '../../assets/images/testimonial1.png'
import Testimony2 from '../../assets/images/testimonial2.png'
import Testimony3 from '../../assets/images/testimonial3.png'
import Testimony4 from '../../assets/images/testimonial4.png'
import Testimony5 from '../../assets/images/testimonial5.png'

const randomRating = () => {
    const ratings = [4, 4.5, 5]
    return ratings[Math.floor(Math.random() * ratings.length)]
}

export default function NewCampaignCarousel(props: any) {
    const settings = props.vipSettings
    const id = settings.id

    let items = [
        {
            name: 'Orrick Talon',
            jobTitle: 'Online Fashion Model',
            rating: randomRating(),
            message:
                'This is the site I often use whenever my channel growth is in a slumber. Buying views and subs helps break that slumber and soon I start getting organic reach as well.',
            image: Testimony1,
        },
        {
            name: 'Pacey Wendy ',
            jobTitle: 'Online Fashion Model',
            rating: randomRating(),
            message:
                'UpViews is a good site to buy YouTube views and subs. Been using it on and off for the past few months. The results are already starting to show. Give it a try.',
            image: Testimony2,
        },
        {
            name: 'Marley Rowan',
            jobTitle: 'Online Fashion Model',
            rating: randomRating(),
            message:
                'This is the site I turn to if I need YouTube services. There are many sites out there that promise the same things. But the only difference is that they fail to deliver on those promises.',
            image: Testimony3,
        },
        {
            name: 'Arthur Brooks',
            jobTitle: 'Online Fashion Model',
            rating: randomRating(),
            message:
                'Inflating numbers is often frowned upon but it works for me so I use it. UpViews is not a scam site which is why it is one of the sites I frequently use for YouTube views.',
            image: Testimony4,
        },
        {
            name: 'Madison Wilson',
            jobTitle: 'Online Fashion Model',
            rating: randomRating(),
            message:
                "Never have been so satisfied with an SMM service provider. I have used it three times and there haven't been any delays or any drops. Good job UpViews!",
            image: Testimony5,
        },
    ]

    if (props.isVIP) {
        require(`../../assets/css/vip/${id}.css`)

        items = settings.bio.map((obj: any) => {
            return {
                ...obj,
                image: require(`../../assets/images/vip/${id}/${obj.image}`),
            }
        })
    }

    return (
        <Carousel
            sx={{
                marginBottom: '50px',
            }}
            indicatorContainerProps={{
                style: {
                    display: 'none',
                },
            }}
            interval={15000}
        >
            {items.length > 0 &&
                items.map((item: any, i: number) => (
                    <div key={`item-cont_${i}`}>
                        <Paper
                            className="details"
                            key={`item_${i}`}
                            sx={{
                                borderRadius: '40px',
                                boxShadow: 'none',
                                filter: 'drop-shadow(-8px 4px 44px rgba(0, 0, 0, 0.03))',
                                backgroundImage: `url(${item.image})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center top',
                                color: '#fff',
                            }}
                        >
                            <div className="gradient-bg">
                                <div className="content-rating">
                                    {!props.isVIP && (
                                        <>
                                            <h2>{item?.name ?? ''}</h2>
                                            <Rating
                                                sx={{ color: '#fff' }}
                                                name="read-only"
                                                value={item?.rating ?? 0}
                                                readOnly
                                                precision={0.5}
                                            />
                                            <p className="message">
                                                {item.message}
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>
                        </Paper>

                        {props.isVIP && (
                            <p
                                className="vip-info"
                                key={`item_p_${i}`}
                                dangerouslySetInnerHTML={{
                                    __html: item?.info ?? '',
                                }}
                            ></p>
                        )}
                    </div>
                ))}
        </Carousel>
    )
}
