import { Avatar, Box, Grid, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  open: boolean;
  onClose: any;
};

export default function ConfirmationModal(props: Props) {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
    fontFamily: "Nunito, sans-serif",
    p: 4,
    '& a': {
      bgcolor: '#27A857',
      borderRadius: '40px',
      fontWeight: 500,
      fontSize: '18px',
      boxShadow: 'none',
      padding: '10px 20px',
      color: '#fff',
      textTransform: 'capitalize',
      textDecoration: 'none',
      marginTop: '10px',
      fontFamily: "Nunito, sans-serif",
    },
    '& .confirm-image-container': {
      borderRadius: '0 0 12px 0',
      fontFamily: "Nunito, sans-serif",
    }
  };

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box sx={style} className="confirm-modal-container">
        <Grid container>
          <Grid item className="modal-close">
            <Avatar onClick={props.onClose}>
              <CloseIcon />
            </Avatar>
          </Grid>
          <Grid item md={7} className="confirm-text-container">
            <h2>Confirm Your Email</h2>
            <p><b>Thanks for signing up! </b></p>
            <p>
              Now simply visit the email inbox you provided and confirm your email address to begin promoting your videos!
            </p>
            <br/>
            <a href="https://mail.google.com/mail/u/0/#inbox" rel="noreferrer" target="_blank">Check My Inbox</a>
          </Grid>
          <Grid item md={5} className="confirm-image-container"></Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
