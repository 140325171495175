import {
    Divider,
    FormControl,
    ImageList,
    ImageListItem,
    InputAdornment,
    MenuItem,
    Select,
    styled,
    Tooltip,
} from '@mui/material'
import { Box } from '@mui/system'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import { SelectChangeEvent } from '@mui/material/Select'
import { ChangeEvent, useEffect, useState } from 'react'
import { format } from 'react-string-format'

import LaptopScreenDefault from '../../assets/images/laptop-screen.png'
import LaptopKeyboard from '../../assets/images/laptop-keyboard.png'
import PlayButton from '../../assets/images/play-button.png'
import Check from '../../assets/images/check.png'
import CustomLabeledFormControl from '../customComponents/custom-labeled-form-control'
import CustomButton from '../customComponents/custom-button.component'
import { KeyboardArrowDown } from '@mui/icons-material'
import {
    SERVICE_COMMENT,
    SERVICE_GROWTH,
    SERVICE_LIKE,
    SERVICE_SUBSCRIBE,
    SERVICE_TYPE_TARGETED,
    SERVICE_VIEW,
} from '../../constants/orders.constants'
import { enforceInt } from '../../helpers/utils.helper'
import {
    TOOLTIP_YOUTUBE_BUNDLE,
    TOOLTIP_YOUTUBE_LIKES_HQ,
    TOOLTIP_YOUTUBE_LIKES_PR,
    TOOLTIP_YOUTUBE_SUBS_HQ,
    TOOLTIP_YOUTUBE_SUBS_PM,
    TOOLTIP_YOUTUBE_VIEWS_HQ,
    TOOLTIP_YOUTUBE_VIEWS_PR,
    TOOLTIP_YOUTUBE_VIEWS_TARGETED,
} from '../../types/tooltip.types'
import {
    LIKE_HIGH_QUALITY,
    LIKE_PREMIUM,
    SUBSCRIBER_HIGH_QUALITY,
    SUBSCRIBER_PREMIUM,
    VIEW_TYPE_HIGH,
    VIEW_TYPE_PREMIUM,
    VIEW_TYPE_TARGETED,
} from '../../types/order_status.types'

type Options = {
    id: string
    name: string
}

type Props = {
    options: {
        categories: Options[]
        services: Options[] | null
        serviceTypes: Options[] | null
        externalCountries: any
        bundles: any
        allServiceTypes: any
    }
    formProps: object
    onChange: any
    errors: any
    loading?: boolean
    isDesktop: boolean
    totalPrice: number
    payload: any
    minMax: any
    validQuantity: any
    validYoutubeUrl: any
    bundleValidUrls: any
}

const Input = styled('input')({
    display: 'none',
})

const getServiceTypeName = (serviceTypes: any, serviceTypeSelected: any) => {
    const service = serviceTypes.filter(
        (serviceType: any) =>
            parseInt(serviceType.id) === parseInt(serviceTypeSelected)
    )
    return service[0]?.name ?? ''
}

const getTooltip = (serviceType: number) => {
    if (serviceType === VIEW_TYPE_TARGETED)
        return TOOLTIP_YOUTUBE_VIEWS_TARGETED

    if (serviceType === VIEW_TYPE_HIGH) return TOOLTIP_YOUTUBE_VIEWS_HQ

    if (serviceType === VIEW_TYPE_PREMIUM) return TOOLTIP_YOUTUBE_VIEWS_PR

    if (serviceType === LIKE_HIGH_QUALITY) return TOOLTIP_YOUTUBE_LIKES_HQ

    if (serviceType === LIKE_PREMIUM) return TOOLTIP_YOUTUBE_LIKES_PR

    if (serviceType === SUBSCRIBER_HIGH_QUALITY) return TOOLTIP_YOUTUBE_SUBS_HQ

    if (serviceType === SUBSCRIBER_PREMIUM) return TOOLTIP_YOUTUBE_SUBS_PM

    return TOOLTIP_YOUTUBE_BUNDLE
}

export default function OrderForm(props: Props) {
    const [imagePreview, setImagePreview] = useState<Array<string>>([])
    const [youtubeThumb, setYoutubeThumb] = useState<Array<string>>([])
    const [previewThumb, setPreviewThumb] = useState('')
    const isDesktop = props.isDesktop
    const [youtubeService, setYoutubeService] = useState(1)
    const [validTargetCount, setValidTargetCount] = useState(false)
    const [tooltip, setTooltip] = useState('')

    useEffect(() => {
        setYoutubeService(
            props.payload.service > 0 ? props.payload.service : SERVICE_VIEW
        )

        setShowTargetingOptions(
            parseInt(props.payload.service_type) === SERVICE_TYPE_TARGETED
        )

        if (!props.payload.youtube_url) {
            setYoutubeThumb([])
            setPreviewThumb('')
        }
    }, [props])

    useEffect(() => {
        const tooltipMessage = getTooltip(parseInt(props.payload.service_type))
        setTooltip(tooltipMessage)
    }, [props.payload.service, props.payload.service_type])

    const [openTooltip, setOpenTooltip] = useState(false)
    const handleTooltipClose = () => {
        setOpenTooltip(false)
    }
    const handleTooltipOpen = () => {
        setOpenTooltip(true)
    }

    const grabYoutubeId = (url: string): string => {
        var regExp =
            // eslint-disable-next-line no-useless-escape
            /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
        var match = url.match(regExp)
        return match && match[1].length === 11 ? match[1] : ''
    }

    const getThumbnails = (youtubeId: string): Array<string> => {
        return ['0.jpg', '1.jpg', '2.jpg', '3.jpg'].map((name: string) => {
            return format('https://img.youtube.com/vi/{0}/{1}', youtubeId, name)
        })
    }

    const [showTargetingOptions, setShowTargetingOptions] = useState(false)
    const handleOptionChange = (event: SelectChangeEvent) => {
        if (!event.target) {
            return
        }

        let serviceType = parseInt(event.target.value)

        props.onChange('service_type', serviceType)(event)
    }

    const handleServiceChange = (event: SelectChangeEvent) => {
        if (!event.target) {
            return
        }

        let service = parseInt(event.target.value)

        props.onChange('service', service)(event)
        setYoutubeService(service > 0 ? service : SERVICE_VIEW)
    }

    const handleQuantityChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        e.preventDefault()

        let targetCount = e.target.value ? parseInt(e.target.value) : ''

        let isValidQuantity = props.validQuantity(targetCount, props.minMax)

        setValidTargetCount(isValidQuantity)

        props.onChange('target_count', targetCount)(e)
    }

    const handleUrlChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        e.preventDefault()
        if (!e.target) {
            return
        }

        props.onChange('youtube_url', e.target.value)(e)

        if (!e.target.value) {
            setYoutubeThumb([])
            return
        }

        let youtubeId = grabYoutubeId(e.target.value)
        if (!youtubeId) {
            setYoutubeThumb([])
            return
        }
        let urls = getThumbnails(youtubeId)
        setYoutubeThumb(urls)
        setPreviewThumb(urls[0])
        props.onChange('thumbnail_urls', urls)(e)
    }

    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        if (!e.target) {
            return
        }

        let files = e.target.files ?? []

        if (files.length === 0) {
            return
        }

        props.onChange('thumbnails', files)(e)

        for (var i = 0; i < files.length; i++) {
            let reader = new FileReader()
            let file = files[i]
            reader.onloadend = () => {
                let preview = reader.result as string
                setImagePreview((current) => [...current, preview])
            }
            reader.readAsDataURL(file)
        }
    }

    return (
        <Box className="order-form">
            <Box
                component="form"
                noValidate
                autoComplete="off"
                {...props.formProps}
            >
                <div className="form-inputs-container">
                    <Box
                        className="tour-campaign-1"
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            mb: '20px',
                            '& > div': { width: '100%' },
                        }}
                    >
                        <div>
                            <Tooltip
                                arrow
                                title={tooltip}
                                placement={isDesktop ? 'right' : 'bottom'}
                                onClose={handleTooltipClose}
                                open={openTooltip}
                                disableTouchListener
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            color: '#414141',
                                            backgroundColor: '#FFEADE',
                                            width: '235px',
                                            '& .MuiTooltip-arrow': {
                                                color: '#FFEADE',
                                            },
                                            '& p': {
                                                fontSize: '11px',
                                            },
                                        },
                                    },
                                }}
                            >
                                <small
                                    onClick={handleTooltipOpen}
                                    className="tour-campaign-2"
                                >
                                    {isDesktop && 'About this service. '}
                                    <HelpOutlineOutlinedIcon
                                        sx={{
                                            fontSize: '14px',
                                            marginLeft: '5px',
                                        }}
                                    />
                                </small>
                            </Tooltip>
                            <CustomLabeledFormControl
                                label="Select Your Service"
                                customChildren={
                                    <FormControl
                                        fullWidth
                                        hiddenLabel
                                        variant="outlined"
                                        size="small"
                                    >
                                        <Select
                                            IconComponent={KeyboardArrowDown}
                                            name="service"
                                            defaultValue="1"
                                            onChange={(e) =>
                                                handleServiceChange(e)
                                            }
                                            error={props.errors.service}
                                            value={props.payload.service}
                                        >
                                            {props.options.services &&
                                                props.options.services.map(
                                                    (viewType: any) => (
                                                        <MenuItem
                                                            key={
                                                                `service` +
                                                                viewType.id
                                                            }
                                                            value={viewType.id}
                                                        >
                                                            {viewType.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>
                                    </FormControl>
                                }
                            />
                        </div>
                    </Box>

                    <Box
                        className="tour-campaign-3"
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            mb: '20px',
                            justifyContent: 'space-between',
                            '& > div': {
                                width:
                                    isDesktop &&
                                    youtubeService !== SERVICE_GROWTH
                                        ? '49%'
                                        : '100%',
                            },
                        }}
                    >
                        <Box>
                            <CustomLabeledFormControl
                                label="Service Type"
                                customChildren={
                                    <FormControl
                                        fullWidth
                                        hiddenLabel
                                        variant="outlined"
                                        size="small"
                                    >
                                        <Select
                                            IconComponent={KeyboardArrowDown}
                                            name="service_type"
                                            defaultValue="0"
                                            onChange={(e) =>
                                                handleOptionChange(e)
                                            }
                                            error={props.errors.service_type}
                                            value={props.payload.service_type}
                                        >
                                            <MenuItem
                                                key={`service_type_0`}
                                                value={0}
                                                disabled
                                            ></MenuItem>
                                            {props.options.serviceTypes &&
                                                props.options.serviceTypes.map(
                                                    (viewType: any) => (
                                                        <MenuItem
                                                            key={
                                                                `service_type_` +
                                                                viewType.id
                                                            }
                                                            value={viewType.id}
                                                        >
                                                            {viewType.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>
                                    </FormControl>
                                }
                            />
                        </Box>

                        {youtubeService !== SERVICE_GROWTH && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    '& > div': { width: '100%' },
                                }}
                            >
                                <CustomLabeledFormControl
                                    label="Quantity"
                                    size="small"
                                    onKeyDown={enforceInt}
                                    onChange={(e: any) =>
                                        handleQuantityChange(e)
                                    }
                                    error={
                                        props.errors.target_count ||
                                        !validTargetCount
                                    }
                                    name="target_count"
                                    placeholder={
                                        props?.minMax?.min
                                            ? `Mininum: ${props?.minMax?.min}`
                                            : '0.00'
                                    }
                                    value={props.payload.target_count}
                                    endAdornment={
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                color: '#27A857',
                                                fontSize: '12px',
                                            }}
                                        >
                                            <span
                                                className="p-label"
                                                style={{
                                                    marginRight: '5px',
                                                }}
                                            >
                                                Price:
                                            </span>
                                            <span
                                                className="p-value"
                                                style={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                ${props.totalPrice}
                                            </span>
                                        </InputAdornment>
                                    }
                                />
                                <p
                                    className="desc"
                                    style={{
                                        marginTop: '-12px',
                                        color: validTargetCount
                                            ? 'rgba(0, 0, 0, 0.5)'
                                            : 'red',
                                    }}
                                >
                                    Min: {props.minMax.min} | Max:{' '}
                                    {props.minMax.max} | Increment:{' '}
                                    {props.minMax.min}
                                </p>
                            </Box>
                        )}
                    </Box>

                    {youtubeService === SERVICE_GROWTH && (
                        <>
                            <Box
                                sx={{
                                    backgroundColor: '#FFFBFB',
                                    borderRadius: '5px',
                                    padding: '12px',
                                    marginBottom: '20px',
                                    '& p.title': {
                                        fontSize: '12px',
                                        margin: 0,
                                        marginBottom: '15px',
                                        opacity: '0.5',
                                    },
                                    '& img': {
                                        height: '15px',
                                        width: '15px',
                                        marginRight: '10px',
                                    },
                                    '& .services-container': {
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                    },
                                    '& div.services': {
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: '10px',
                                        width: '50%',
                                        '& p': {
                                            margin: '0',
                                            fontSize: '14px',
                                        },
                                    },
                                }}
                            >
                                <p className="title">
                                    {getServiceTypeName(
                                        props.options.serviceTypes,
                                        props.payload.service_type
                                    )}{' '}
                                    Inclusion:
                                </p>
                                <div className="services-container">
                                    {props?.options?.bundles &&
                                        props.options.bundles[
                                            props.payload.service_type
                                        ]?.bundle?.map(
                                            (bundle: any, i: number) => (
                                                <div
                                                    className="services"
                                                    key={`ind_` + i}
                                                >
                                                    <img src={Check} alt="" />{' '}
                                                    <p>
                                                        {bundle?.target_count.toLocaleString() ??
                                                            ''}{' '}
                                                        {props.options
                                                            .allServiceTypes[
                                                            bundle?.service_type ??
                                                                0
                                                        ] ?? ''}
                                                    </p>
                                                </div>
                                            )
                                        )}
                                </div>
                            </Box>

                            <CustomLabeledFormControl
                                label="Enter your Youtube URL for Likes"
                                fullWidth
                                size="small"
                                onChange={props.onChange('urls.likes')}
                                error={
                                    props.errors.urls.likes ||
                                    !props.bundleValidUrls[0]?.isValid
                                }
                                name="likes"
                                value={props.payload?.urls?.likes}
                                placeholder="https://www.youtube.com/watch-2/wexdsdag"
                            />

                            <CustomLabeledFormControl
                                label="Enter your Youtube URL for Views"
                                fullWidth
                                size="small"
                                onChange={props.onChange('urls.views')}
                                error={
                                    props.errors.urls.views ||
                                    !props.bundleValidUrls[1]?.isValid
                                }
                                name="views"
                                value={props.payload?.urls?.views}
                                placeholder="https://www.youtube.com/watch-2/wexdsdag"
                            />

                            <CustomLabeledFormControl
                                label="Enter your Youtube URL for Subscriber"
                                fullWidth
                                size="small"
                                onChange={props.onChange('urls.subscribers')}
                                error={
                                    props.errors.urls.subscribers ||
                                    !props.bundleValidUrls[2]?.isValid
                                }
                                name="subscribers"
                                value={props.payload?.urls?.subscribers}
                                placeholder="https://www.youtube.com/c/channel"
                            />
                        </>
                    )}

                    {youtubeService === SERVICE_SUBSCRIBE && (
                        <CustomLabeledFormControl
                            label="Enter your Youtube Channel URL"
                            fullWidth
                            size="small"
                            onChange={handleUrlChange}
                            error={
                                props.errors.youtube_url ||
                                !props.validYoutubeUrl
                            }
                            name="youtube_url"
                            value={props.payload.youtube_url}
                            placeholder="https://www.youtube.com/c/channel"
                        />
                    )}

                    {(youtubeService === SERVICE_VIEW ||
                        youtubeService === SERVICE_LIKE ||
                        youtubeService === SERVICE_COMMENT) && (
                        <>
                            {showTargetingOptions && (
                                <div>
                                    <h4>Targeting Views Settings</h4>
                                    <CustomLabeledFormControl
                                        label="Country"
                                        customChildren={
                                            <FormControl
                                                fullWidth
                                                hiddenLabel
                                                variant="outlined"
                                                size="small"
                                            >
                                                <Select
                                                    IconComponent={
                                                        KeyboardArrowDown
                                                    }
                                                    defaultValue={''}
                                                    name="country"
                                                    onChange={props.onChange(
                                                        'country'
                                                    )}
                                                >
                                                    {props.options
                                                        .externalCountries &&
                                                        props.options.externalCountries.map(
                                                            (country: any) => (
                                                                <MenuItem
                                                                    key={
                                                                        `country_` +
                                                                        country.id
                                                                    }
                                                                    value={
                                                                        country.id
                                                                    }
                                                                >
                                                                    {
                                                                        country.name
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                </Select>
                                            </FormControl>
                                        }
                                    />
                                    <CustomLabeledFormControl
                                        label="Categories"
                                        customChildren={
                                            <FormControl
                                                fullWidth
                                                hiddenLabel
                                                variant="outlined"
                                                size="small"
                                            >
                                                <Select
                                                    IconComponent={
                                                        KeyboardArrowDown
                                                    }
                                                    defaultValue={''}
                                                    name="category_id"
                                                    onChange={props.onChange(
                                                        'category_id'
                                                    )}
                                                >
                                                    {props.options.categories &&
                                                        props.options.categories.map(
                                                            (category: any) => (
                                                                <MenuItem
                                                                    key={
                                                                        `cat_` +
                                                                        category.id
                                                                    }
                                                                    value={
                                                                        category.id
                                                                    }
                                                                >
                                                                    {
                                                                        category.name
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                </Select>
                                            </FormControl>
                                        }
                                    />
                                </div>
                            )}

                            <CustomLabeledFormControl
                                label="Enter your Youtube URL"
                                fullWidth
                                size="small"
                                onChange={handleUrlChange}
                                error={
                                    props.errors.youtube_url ||
                                    !props.validYoutubeUrl
                                }
                                value={props.payload.youtube_url}
                                name="youtube_url"
                                placeholder="https://www.youtube.com/watch-2/wexdsdag"
                            />

                            <Box
                                className="ad-video"
                                sx={{
                                    p: { padding: '0 20px' },
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <p style={{ marginBottom: '20px' }}>
                                    <img src={PlayButton} alt="" />
                                    <span>
                                        Example of your skippable in-stream ad
                                        on YouTube
                                    </span>
                                </p>
                                <Box
                                    component="div"
                                    sx={{
                                        width: '100%',
                                        position: 'relative',
                                        '& .laptop': {
                                            zIndex: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: isDesktop ? '70%' : '90%',
                                            margin: '0 auto',
                                            img: {
                                                height: 'auto',
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                                '&.preview': {
                                                    borderRadius: '12px',
                                                    backgroundImage: `url(${previewThumb})`,
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat:
                                                        'no-repeat',
                                                    backgroundPosition:
                                                        'center top',
                                                    width: '72%',
                                                    margin: '0 auto',
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <div className="laptop">
                                        <img
                                            src={LaptopScreenDefault}
                                            alt=""
                                            className="preview"
                                        />
                                        <img src={LaptopKeyboard} alt="" />
                                    </div>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '20px',
                                    '& img': { cursor: 'pointer' },
                                }}
                            >
                                <label>Upload Custom Thumbnail</label>
                                <p className="desc">
                                    Select or upload a picture that shows what's
                                    in your video. A good thumbnail stands out
                                    and draws viewer's attention.
                                </p>

                                <ImageList
                                    className="image-list"
                                    cols={isDesktop ? 4 : 2}
                                >
                                    <ImageListItem>
                                        <label
                                            className={'upload'}
                                            htmlFor="contained-button-file"
                                            style={{ height: '180px' }}
                                        >
                                            <Input
                                                accept="image/*"
                                                id="contained-button-file"
                                                type="file"
                                                multiple
                                                onChange={(e) =>
                                                    handleImageChange(e)
                                                }
                                            />
                                            <AddPhotoAlternateIcon />
                                            <p> Upload thumbnail </p>
                                        </label>
                                    </ImageListItem>
                                    {imagePreview.map(
                                        (preview: string, i: number) => (
                                            <ImageListItem
                                                key={`upload_thumb_${i}`}
                                            >
                                                <img
                                                    src={preview}
                                                    alt=""
                                                    onClick={() => {
                                                        setPreviewThumb(preview)
                                                    }}
                                                />
                                            </ImageListItem>
                                        )
                                    )}
                                    {youtubeThumb.map(
                                        (thumb: string, i: number) => (
                                            <ImageListItem key={`thumb_${i}`}>
                                                <img
                                                    src={thumb}
                                                    alt=""
                                                    onClick={() => {
                                                        setPreviewThumb(thumb)
                                                    }}
                                                />
                                            </ImageListItem>
                                        )
                                    )}
                                </ImageList>
                            </Box>
                        </>
                    )}
                </div>

                {isDesktop && (
                    <div>
                        <Divider />
                        <CustomButton
                            isLoading={props.loading}
                            disabled={!props.validYoutubeUrl}
                            sx={{
                                float: 'right',
                                margin: '20px 20px 20px 0px',
                            }}
                            label="Start Campaign"
                            type="submit"
                            className="upviews-button"
                        />
                    </div>
                )}
            </Box>
        </Box>
    )
}
