import { ChangeEvent, SyntheticEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApiProvidersAction } from '../../../actions/api-providers.action'
import { ScreenState } from '../../../reducers/screen.reducer'
import store, { RootState } from '../../../store'
import ApiForm from './api-form.component'

type Options = {
    id: string
    name: string
}

const formatOptions = (options: Object): Options[] => {
    return (Object.entries(options ?? {}) as [string, string][]).map(
        ([key, value]: [string, string]): Options => {
            return {
                id: key,
                name: value,
            }
        }
    )
}

const validateForm = (payload: any) => {
    let dataFields = [
        {
            key: 'name',
            isValid: payload.name !== '',
        },
        {
            key: 'platform_id',
            isValid: payload.platform_id !== '',
        },
        {
            key: 'service_class',
            isValid: payload.service_class !== '',
        },
        {
            key: 'api_key',
            isValid: payload.api_key !== '',
        },
    ]

    return {
        data: dataFields,
        isValid: dataFields.every((data) => data.isValid),
    }
}

export default function AddApiNav(props: any) {
    const { options } = useSelector<RootState, ScreenState>(
        (state) => state.screen
    )

    const [payload, setPayload] = useState({
        name: '',
        platform_id: 1,
        service_class: 1,
        api_key: '',
        is_active: 1,
    })

    const defaultErrors = {
        name: false,
        platform_id: false,
        service_class: false,
        api_key: false,
    }

    const [errors, setErrors] = useState(defaultErrors)

    const onSubmit = async (event: SyntheticEvent) => {
        event.preventDefault()
        const validation = validateForm(payload)

        if (!validation.isValid) {
            setErrors(
                validation.data.reduce((prevField, field) => {
                    return { ...prevField, [field.key]: !field.isValid }
                }, defaultErrors)
            )
            return
        }
        store.dispatch(ApiProvidersAction.new(payload))
    }

    const onChange =
        (name: string, customValue?: any) =>
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = customValue ?? event.target.value

            setPayload((prevState) => ({
                ...prevState,
                [name]: value,
            }))
        }

    return (
        <ApiForm
            options={{
                serviceProviders: formatOptions(options?.api_service_providers),
                platforms: formatOptions(options?.platforms),
            }}
            payload={payload}
            btnText={'Save Api'}
            formProps={{ onSubmit: onSubmit }}
            onChange={onChange}
            errors={errors}
        />
    )
}
