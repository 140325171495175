import {
    TRANSACTION_REQUEST,
    TRANSACTION_FAIL,
    TRANSACTION_GET_SUCCESS,
    TRANSACTION_SUCCESS,
    TRANSACTION_UPDATE_FAIL,
    TRANSACTION_APPROVED,
    TRANSACTION_CANCELLED,
    TRANSACTION_LIST_FAIL,
} from '../constants/transaction.constants'
import { TransactionService } from '../services/transaction.service'
import { AppThunk } from '../store'
import {
    BANK_TRANSFER,
    CREDIT_DEBIT,
    CRYPTO,
} from '../types/transaction_options.types'

export const TransactionAction = {
    new:
        (payload: any): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: TRANSACTION_REQUEST,
            })

            await TransactionService.new(payload)
                .then((response: any) => {
                    let responseData = response.data
                    if (!(responseData?.data ?? null)) {
                        return response?.data
                    }

                    if (payload.option === CREDIT_DEBIT) {
                        window.location.href =
                            responseData?.data?.response?._links?.redirect
                                ?.href ?? '/'
                    }

                    if (payload.option === CRYPTO) {
                        window.location.href =
                            responseData?.data?.response?.hosted_url ?? '/'
                    }

                    if (payload.option === BANK_TRANSFER) {
                        dispatch({
                            type: TRANSACTION_SUCCESS,
                            payload: {
                                message:
                                    'Your balance will be updated and reflect the added funds, once we confirmed it on our end.',
                                status: 'Pending',
                            },
                        })
                    }

                    return
                })
                .catch(function (error) {
                    dispatch({
                        type: TRANSACTION_FAIL,
                        payload: error?.response?.data,
                    })
                })
        },
    index:
        (
            page: number = 1,
            search: string | null = null,
            sort: string | null = null,
            userId: string | null = null,
            status: string | string[] | null = null
        ): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: TRANSACTION_REQUEST,
            })

            await TransactionService.index(page, search, sort, userId, status)
                .then((response: any) => {
                    let responseData = response?.data

                    if (responseData.data) {
                        return dispatch({
                            type: TRANSACTION_GET_SUCCESS,
                            payload: responseData,
                        })
                    }
                    return response?.data
                })
                .catch(function (error) {
                    dispatch({
                        type: TRANSACTION_LIST_FAIL,
                        payload: error?.response?.data,
                    })
                })
        },
    approvePayment:
        (paymentId: number, payload: any): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: TRANSACTION_REQUEST,
            })

            await TransactionService.approve(paymentId, payload)
                .then((response: any) => {
                    let responseData = response?.data
                    if (responseData.data) {
                        return dispatch({
                            type: TRANSACTION_APPROVED,
                            payload: responseData,
                        })
                    }
                    return response?.data
                })
                .catch(function (error) {
                    dispatch({
                        type: TRANSACTION_UPDATE_FAIL,
                        payload: error?.response?.data,
                    })
                })
        },
    cancelPayment:
        (paymentId: number, payload: any): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: TRANSACTION_REQUEST,
            })

            await TransactionService.cancel(paymentId, payload)
                .then((response: any) => {
                    let responseData = response?.data
                    if (responseData.data) {
                        return dispatch({
                            type: TRANSACTION_CANCELLED,
                            payload: responseData,
                        })
                    }
                    return response?.data
                })
                .catch(function (error) {
                    dispatch({
                        type: TRANSACTION_UPDATE_FAIL,
                        payload: error?.response?.data,
                    })
                })
        },
}
