import { Divider, TextareaAutosize } from '@mui/material'
import { Box } from '@mui/system'
import ReCAPTCHA from 'react-google-recaptcha'
import { TYPES_CONST } from '../../../env'
import CustomButton from '../../customComponents/custom-button.component'
import CustomLabeledFormControl from '../../customComponents/custom-labeled-form-control'

export default function SupportForm(props: any) {
    const { isDesktop, isLoading } = props

    const onChange = (e: any) => {
        props.onChange('recaptcha', e)()
    }

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            {...props.formProps}
        >
            <div className="form-inputs-container">
                <Box
                    sx={{
                        marginTop: '20px',
                        width: isDesktop ? '50%' : '100%',
                    }}
                >
                    <CustomLabeledFormControl
                        label="Email Address"
                        size="small"
                        value={props.user?.info?.email ?? ''}
                    />
                </Box>

                <CustomLabeledFormControl
                    label="Subject"
                    fullWidth
                    size="small"
                    name="subject"
                    placeholder="Subject"
                    error={props.errors.subject}
                    onChange={props.onChange('subject')}
                />

                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        marginTop: '20px',
                        justifyContent: 'space-between',
                        '& > div': { width: isDesktop ? '49%' : '100%' },
                    }}
                >
                    <CustomLabeledFormControl
                        label="Video URL (optional)"
                        fullWidth
                        size="small"
                        name="url"
                        placeholder="Video URL"
                        error={props.errors.url}
                        onChange={props.onChange('url')}
                    />
                </Box>

                <Box
                    sx={{
                        marginTop: '20px',
                        width: '99%',
                    }}
                >
                    <label>Message</label>
                    <TextareaAutosize
                        aria-label="Message"
                        placeholder="Write Message..."
                        style={{ width: isDesktop ? '100%' : '96%' }}
                        minRows={15}
                        id="message"
                        name="message"
                        onChange={props.onChange('message')}
                    />
                </Box>
            </div>
            <Divider
                sx={{
                    mt: isDesktop ? '45px' : '20px',
                    mb: isDesktop ? '50px' : '20px',
                }}
            />
            <Box
                className="submit-container"
                display={isDesktop ? 'flex' : 'block!important'}
                justifyContent="space-between"
            >
                <Box className={`captcha${!isDesktop ? ' mobile' : ''}`}>
                    {TYPES_CONST.GOOGLE_RECAPTCHA_SITE_KEY && (
                        <ReCAPTCHA
                            sitekey={TYPES_CONST.GOOGLE_RECAPTCHA_SITE_KEY}
                            onChange={onChange}
                        />
                    )}

                    <div id="topHider"></div>
                    <div id="rightHider"></div>
                    <div id="bottomHider"></div>
                    <div id="leftHider"></div>
                </Box>
                <Box
                    className="btn-container"
                    sx={{
                        width: isDesktop ? 'unset' : '100%!important',
                        margin: isDesktop ? 'unset' : '15px 0px 10px 0px',
                    }}
                >
                    <CustomButton
                        isLoading={isLoading}
                        label="Submit Message"
                        type="submit"
                        sx={{
                            padding: '10px 20px!important',
                            width: isDesktop ? 'unset' : '100%',
                        }}
                        className="upviews-button"
                    />
                </Box>
            </Box>
        </Box>
    )
}
