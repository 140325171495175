import {
  API_PROVIDER_EDIT_FAIL,
  API_PROVIDER_EDIT_SUCCESS,
  API_PROVIDER_FAIL,
  API_PROVIDER_GET_FAIL,
  API_PROVIDER_GET_SUCCESS,
  API_PROVIDER_REQUEST,
  API_PROVIDER_SUCCESS,
} from "../constants/api-providers.constants";
import { ApiProvidersService } from "../services/api-providers.service";
import { AppThunk } from "../store";

export const ApiProvidersAction = {
  index:
    (page: number = 1): AppThunk =>
    async (dispatch) => {
      dispatch({
        type: API_PROVIDER_REQUEST,
      });

      await ApiProvidersService.index(page)
        .then((response: any) => {
          let responseData = response?.data;
          if (responseData?.data) {
            return dispatch({
              type: API_PROVIDER_GET_SUCCESS,
              payload: responseData,
            });
          }
          return response?.data;
        })
        .catch(function (error) {
          dispatch({
            type: API_PROVIDER_GET_FAIL,
            payload: error?.response?.data,
          });
        });
    },
  new:
    (payload: any): AppThunk =>
    async (dispatch) => {
      dispatch({
        type: API_PROVIDER_REQUEST,
      });

      await ApiProvidersService.new(payload)
        .then((response: any) => {
          let responseData = response?.data;

          if (responseData?.data) {
            return dispatch({
              type: API_PROVIDER_SUCCESS,
              payload: responseData.data,
            });
          }

          return response?.data;
        })
        .catch(function (error) {
          dispatch({
            type: API_PROVIDER_FAIL,
            payload: error?.response?.data,
          });
        });
    },
  edit:
    (payload: any, apiId: number): AppThunk =>
    async (dispatch) => {
      dispatch({
        type: API_PROVIDER_REQUEST,
      });

      await ApiProvidersService.edit(payload, apiId)
        .then((response: any) => {
          let responseData = response?.data;

          if (responseData?.data) {
            return dispatch({
              type: API_PROVIDER_EDIT_SUCCESS,
              payload: responseData.data,
            });
          }
          return response?.data;
        })
        .catch(function (error) {
          dispatch({
            type: API_PROVIDER_EDIT_FAIL,
            payload: error?.response?.data,
          });
        });
    },
};
