import { useState, useEffect } from 'react'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Box } from '@mui/material'

import {
    PAYMENT_STATUS,
    PAYMENT_TYPE,
    TRANSACTION_TYPE,
} from '../../../types/transaction_options.types'
import TableWrapper from '../../wrappers/table-wrapper.component'
import { formatDate } from '../../../helpers/utils.helper'
import {
    getBankName,
    getLast4,
    getReference,
} from '../../../services/deposit.service'
import TextSkeleton from '../../skeletons/text.skeleton'
import QuickFilters from '../../customComponents/quick-filters.component'
import DepositsPopup from './deposits-popup.component'
import store, { RootState } from '../../../store'
import { IpInfoState } from '../../../reducers/ipinfo.reducer'
import { IpInfoAction } from '../../../actions/ipinfo.action'
import { useSelector } from 'react-redux'

const Deposits = (props: any) => {
    const {
        meta,
        isLoading,
        onPageChange,
        isDesktop,
        list,
        onSort,
        approvePayment,
        cancelPayment,
        onSearch,
        onChangeStatus,
    } = props

    const ipInfo: any = useSelector<RootState, IpInfoState>(
        (state) => state.ipInfo
    )

    const { current_page, total, last_page = 0, from = 0, to = 0 } = meta || {}
    const [sortBy, setSortBy] = useState('')
    const [status, setStatus] = useState([])
    const [showPopup, setShowPopup] = useState(false)
    const [selectedTransaction, setSelectedTransaction] = useState({});

    useEffect(() => {
        onChangeStatus(status.map((e: any) => e.value))
    }, [status]) //eslint-disable-line

    const headers = [
        {
            key: 'created_at',
            label: 'Date & Time',
            customValue: (row: any) => formatDate(row.created_at, 'l LT'),
        },
        { key: 'code', label: 'Transaction ID' },
        {
            key: 'type',
            label: 'Type',
            customValue: (row: any) => TRANSACTION_TYPE[row?.type ?? 0],
        },
        {
            key: 'amount',
            label: 'Amount',
            customValue: (row: any) => (row?.amount ?? 0).toFixed(2),
        },

        {
            key: 'reference',
            label: 'Reference ID',
            customValue: getReference,
        },
        {
            key: 'bank_name',
            label: 'Bank Name',
            customValue: getBankName,
        },
        {
            key: 'account_number',
            label: 'Last 4 No. of Acc',
            customValue: getLast4,
        },
        {
            key: 'option',
            label: 'Payment Method',
            customValue: (row: any) => PAYMENT_TYPE[row?.option ?? 0],
        },
        {
            key: 'status',
            label: 'Status',
            customValue: (row: any) => {
                const { label, color } = PAYMENT_STATUS[row.status] || {}
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '14px',
                            '& .status-icon': {
                                height: '8px',
                                width: '8px',
                                border: '3px solid #FF4848',
                                borderRadius: '50%',
                                mr: '5px',
                                mb: '4px',
                            },
                            color,
                            '.status-icon': { borderColor: color },
                        }}
                    >
                        <div className="status-icon" /> {label}
                    </Box>
                )
            },
        },
    ]

    const onSortChange = (value: string) => {
        setSortBy(value)
        onSort(value)
    }

    const showIpInfo = (ipAddress: string, transaction: any) => {
      store.dispatch(IpInfoAction.getInfoByIpAddress(ipAddress))
      setSelectedTransaction(transaction)

      const delayDebounceFn = setTimeout(() => {
        setShowPopup(true)
      }, 500)

      return () => clearTimeout(delayDebounceFn)
    }

    const closePopup = () => {
        setShowPopup(false)
    }

    const contextMenu = [
        {
            callBack: (row: any) => approvePayment(row?.id),
            label: 'Approve Deposit',
            icon: <CheckBoxIcon />,
        },
        {
            callBack: (row: any) => cancelPayment(row?.id),
            label: 'Cancel Deposit',
            icon: <DisabledByDefaultIcon />,
        },
    ]

    const responsiveHeaders = [{ key: 'created_at', label: 'Created At' }]

    const onGetCollapsibleComponent = (
        row: any,
        isCollapsed: boolean = false
    ) => {
        const filteredHeaders = headers.filter((header: any) =>
            responsiveHeaders.find((r) => r.key !== header.key)
        )

        return (
            <Box sx={{ margin: 0 }} key={row.id}>
                <Box
                    display={isDesktop ? 'flex' : 'block!important'}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {isDesktop ? (
                        <>
                            <h3>Additional Information for this Transaction</h3>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    '& > div': {
                                        width: '20%',
                                    },
                                    '& .title': {
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        color: '#BFBFBF',
                                        margin: '0',
                                    },
                                    '& .ip_address': {
                                        color: '#27A857',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                    },
                                }}
                            >
                                <div>
                                    <p className="title">
                                        User Ip Address Used
                                    </p>
                                    <p
                                        className="ip_address"
                                        onClick={() =>
                                            showIpInfo(row?.user?.ip_address, row)
                                        }
                                    >
                                        {row?.user?.ip_address ?? ''}
                                    </p>
                                </div>
                                <div>
                                    <p className="title">Account User used</p>
                                    <p>{row?.user?.name ?? ''}</p>
                                </div>
                                <div>
                                    <p className="title">
                                        User Email Address used
                                    </p>
                                    <p>{row?.user?.email ?? ''}</p>
                                </div>
                                <div>
                                    <p className="title">External ID</p>
                                    <p>{row?.data?.response?.id ?? ''}</p>
                                </div>
                            </Box>
                        </>
                    ) : (
                        <Box
                            sx={{
                                '& span.info': {
                                    color: '#656565',
                                    mt: '100px',
                                },
                            }}
                        >
                            {filteredHeaders.map(
                                (header: any, hIndex: number) => {
                                    return (
                                        <Box
                                            key={`collapsible-mobile-${hIndex}`}
                                            display="flex"
                                            justifyContent="space-between"
                                            pb="10px"
                                            sx={{
                                                '& .label': {
                                                    color: '#BFBFBF',
                                                },
                                            }}
                                        >
                                            <div className="label">
                                                {header.label}
                                            </div>
                                            <div>
                                                {isLoading ? (
                                                    <TextSkeleton width="100px" />
                                                ) : header.customValue ? (
                                                    header.customValue(row)
                                                ) : (
                                                    row[header.key]
                                                )}
                                            </div>
                                        </Box>
                                    )
                                }
                            )}
                            {isCollapsed && (
                                <>
                                    <h3>
                                        Additional Information for this
                                        Transaction
                                    </h3>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            '& > div': {
                                                width: '20%',
                                            },
                                            '& .title': {
                                                fontWeight: '500',
                                                fontSize: '14px',
                                                color: '#BFBFBF',
                                                margin: '0',
                                            },
                                            '& .ip_address': {
                                              color: '#27A857',
                                              textDecoration: 'underline',
                                              cursor: 'pointer',
                                              fontWeight: '500',
                                              fontSize: '14px',
                                          },
                                        }}
                                    >
                                        <div>
                                            <p className="title">
                                                User Ip Address Used
                                            </p>
                                            <p
                                                className="ip_address"
                                                onClick={() =>
                                                    showIpInfo(row?.user?.ip_address, row)
                                                }
                                            >
                                                {row?.user?.ip_address ?? ''}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="title">
                                                Account User used
                                            </p>
                                            <p>{row?.user?.name ?? ''}</p>
                                        </div>
                                        <div>
                                            <p className="title">
                                                User Email Address used
                                            </p>
                                            <p>{row?.user?.email ?? ''}</p>
                                        </div>
                                        <div></div>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            '& > div': {
                                                width: '20%',
                                            },
                                            '& .title': {
                                                fontWeight: '500',
                                                fontSize: '14px',
                                                color: '#BFBFBF',
                                                margin: '0',
                                            },
                                        }}
                                    >
                                        <div>
                                            <p className="title">External ID</p>
                                            <p>
                                                {row?.data?.response?.id ?? ''}
                                            </p>
                                        </div>
                                    </Box>
                                </>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        )
    }

    const quickFilters = (
        <QuickFilters
            options={Object.entries(PAYMENT_STATUS ?? {}).map(
                ([key, value]: any) => ({ value: key, label: value.label })
            )}
            isDesktop={isDesktop}
            statusColors={PAYMENT_STATUS}
            onSearch={onSearch}
            onSortChange={onSortChange}
            sortBy={sortBy}
            setStatus={setStatus}
            hideDownload={true}
        />
    )

    return (
        <>
            <TableWrapper
                entityLabel="deposits"
                entity="deposit"
                headers={headers}
                responsiveHeaders={[
                    {
                        key: 'reference',
                        label: `Reference ID`,
                        customValue: (row: any) =>
                            (row.data?.response?.reference ?? '') +
                            ' ' +
                            formatDate(row.created_at, 'l LT'),
                    },
                ]}
                body={list}
                contextMenu={contextMenu}
                total={total}
                currentPage={current_page}
                pageCount={last_page}
                pageFrom={from}
                pageTo={to}
                isLoading={isLoading}
                isDesktop={isDesktop}
                onPageChange={onPageChange}
                sortBy={sortBy}
                onSortChange={onSortChange}
                onSearch={onSearch}
                onGetCollapsibleComponent={onGetCollapsibleComponent}
                hideFilters={true}
                additionalActions={quickFilters}
                additionalActionsMobile={quickFilters}
            />
            <DepositsPopup
                open={showPopup}
                onClose={closePopup}
                ipInfo={ipInfo?.data?.details ?? {}}
                selectedTransaction={selectedTransaction}
                approvePayment = {approvePayment}
            />
        </>
    )
}

export default Deposits
