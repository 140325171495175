import { Container } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { LoginRecordAction } from '../actions/login-records.action'
import LoginRecords from '../components/dashboard/admin/login-records.component'
import { RecordState } from '../reducers/records.reducer'
import { UserState } from '../reducers/user.reducer'
import store, { RootState } from '../store'
import { TYPE_SUPER_ADMIN } from '../types/user.types'

const LoginRecordsScreen = (props: any) => {
    const { id } = useParams()
    const user = useSelector<RootState, UserState>((state) => state.userLogin)
    const loginRecords = useSelector<RootState, RecordState>((state) => state.loginRecord)
    const [isSuperAdmin, setIsSuperAdmin] = useState(false)

    const isLoading = props.isLoading || loginRecords.loading

    const [page, setPage] = useState(1)
    const [searchTerm, setSearchTerm] = useState('')
    const [sortBy, setSortBy] = useState('created_at')

    useEffect(() => {
        if (user.info) {
            let roles = user?.info?.roles ?? []
            setIsSuperAdmin(roles.includes(TYPE_SUPER_ADMIN))
        }
    }, [user, isSuperAdmin])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            store.dispatch(LoginRecordAction.index(page, searchTerm, sortBy, id))
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [page, searchTerm, sortBy, id])

    useEffect(() => {
        props.getScreenTitle('Login Records')
    }, []) // eslint-disable-line

    const onPageChange = (page: number) => {
        setPage(page)
    }

    const onSort = (value: string) => {
        setSortBy(value)
    }

    return (
        <Box
            className="login-records"
            sx={{ mb: !props.isDesktop ? '50px' : 'unset' }}
        >
            <Container className="container">
                <LoginRecords
                    list={loginRecords.data?.list?.data ?? []}
                    meta={loginRecords.data?.list?.meta ?? { last_page: 0 }}
                    page={page}
                    onPageChange={onPageChange}
                    onSort = {onSort}
                    onSearch={setSearchTerm}
                    isLoading={isLoading}
                    isDesktop={props.isDesktop}
                />
            </Container>
        </Box>
    )
}

export default LoginRecordsScreen
