import { format } from 'react-string-format'
import API_INSTANCE, { objectToQueryParams } from './api'
import endpoints from './endpoints'

export const TransactionService = {
    new: async (fields: any) => {
        return API_INSTANCE.post(endpoints.NEW_TRANSACTION, fields)
    },
    index: async (
        page: number = 1,
        search: string | null = null,
        sort: string | null = null,
        userId: string | null = null,
        status: string | string[] | null = null
    ) => {
        let params = {
            per_page: 15,
            page: page,
            search: search,
            sort_by: sort,
            user_id: userId,
            status,
        }

        return API_INSTANCE.get(
            endpoints.GET_TRANSACTION + '?' + objectToQueryParams(params)
        )
    },
    approve: async (paymentId: number, fields: any) => {
        return API_INSTANCE.put(
            format(endpoints.UPDATE_TRANSACTION, paymentId),
            fields
        )
    },
    cancel: async (paymentId: number, fields: any) => {
        return API_INSTANCE.put(
            format(endpoints.UPDATE_TRANSACTION, paymentId),
            fields
        )
    },
}
