export const FILTERS_REQUEST = 'FILTERS_REQUEST';
export const FILTERS_GET_SUCCESS = 'FILTERS_GET_SUCCESS';
export const FILTERS_GET_FAIL = 'FILTERS_GET_FAIL';
export const FILTERS_SUCCESS = 'FILTERS_SUCCESS';
export const FILTERS_FAIL = 'FILTERS_FAIL';
export const FILTERS_RESET = 'FILTERS_RESET';
export const FILTERS_EDIT_SUCCESS = 'FILTERS_EDIT_SUCCESS';
export const FILTERS_EDIT_FAIL = 'FILTERS_EDIT_FAIL';
export const FILTERS_DELETE_SUCCESS = 'FILTERS_DELETE_SUCCESS';
export const FILTERS_DELETE_FAIL = 'FILTERS_DELETE_FAIL';
