import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import store, { RootState } from '../store'
import { OrderAction } from '../actions/orders.action'
import { StatisticsAction } from '../actions/account.action'
import { OrderState } from '../reducers/orders.reducer'
import { StatisticsState } from '../reducers/account.reducer'
import { failNotification } from '../actions/screen.action'
import { UserState } from '../reducers/user.reducer'
import { getCurrentUser, isUserAdmin } from '../services/auth.service'
import AdminDashboard from '../components/dashboard/admin/index.component'
import CustomerDashboard from '../components/dashboard/customer/index.component'
import { Container, Grid } from '@mui/material'
import { Navigate } from 'react-router'

const DashboardScreen = (props: any) => {
    const user = useSelector<RootState, UserState>((state) => state.userLogin)
    const statistics = useSelector<RootState, StatisticsState>(
        (state) => state.statistics
    )
    const order = useSelector<RootState, OrderState>((state) => state.order)
    const isLoading = props.isLoading || statistics.loading || order.loading
    const currentUser = getCurrentUser() || {}
    const [isAdmin, setIsAdmin] = useState(false)
    const [page, setPage] = useState(1)
    const [searchTerm, setSearchTerm] = useState('')
    const [sortBy, setSortBy] = useState('created_at')
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        if (statistics.hasError) {
            //set time out will set this dispatch to the last process
            setTimeout(() => {
                store.dispatch(failNotification('Fail Statistic'))
            })
        }

        if (user.info) {
            setIsAdmin(isUserAdmin(user.info ?? []))
        }
    }, [isLoaded, page, user, statistics])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            !isAdmin &&
                store.dispatch(OrderAction.index(page, searchTerm, sortBy))

            if (isLoaded === false) {
                setIsLoaded(true)
                store.dispatch(StatisticsAction.get())
            }
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [page, searchTerm, sortBy, isLoaded, isAdmin])

    useEffect(() => {
        props.getScreenTitle(
            `Hi ${currentUser.name}! Welcome, this is your Dashboard`
        )
    }, []) // eslint-disable-line

    const onPageChange = (page: number) => {
        setPage(page)
    }

    const onSort = (value: string) => {
        setSortBy(value)
    }

    const hasParams =
        localStorage.getItem('service') ||
        localStorage.getItem('service_type') ||
        localStorage.getItem('target_count')
    return hasParams && !isAdmin ? (
        <Navigate to="/orders/new" />
    ) : (
        <Container className="container">
            <Grid item>
                {isAdmin ? (
                    <AdminDashboard
                        isLoading={isLoading}
                        statistics={statistics.payload?.data ?? []}
                    />
                ) : (
                    <CustomerDashboard
                        isDesktop={props.isDesktop}
                        isLoading={isLoading}
                        onPageChange={onPageChange}
                        statistics={statistics.payload?.data ?? []}
                        list={order.data?.list?.data ?? []}
                        meta={order.data?.list?.meta ?? { last_page: 0 }}
                        page={page}
                        setSearchTerm={setSearchTerm}
                        onSort={onSort}
                    />
                )}
            </Grid>
        </Container>
    )
}

export default DashboardScreen
