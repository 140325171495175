import { TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import CustomButton from '../customComponents/custom-button.component'
import { RootState } from '../../store'
import { TransactionState } from '../../reducers/transaction.reducer'
import { enforceInt } from '../../helpers/utils.helper'
import CustomTermsOfUseLinkComponent from '../customComponents/custom-terms-of-use-link-component'

export default function CreditCardForm(props: any) {
    const transaction = useSelector<RootState, TransactionState>(
        (state) => state.transaction
    )

    const [amount, setAmount] = useState(10)

    const handleAmountChange = (e: any) => {
        e.preventDefault()
        if (!e.target.value) {
            setAmount(0)
            return
        }
        let value = parseInt(e.target.value)

        if (isNaN(value)) {
            value = amount
        }

        setAmount(value)
        props.onChange('amount', value)(e)
        props.onChange('data.amount', value)(e)
    }

    return (
        <div
            className={`credit-form-container${
                props.isDesktop ? ' desktop' : ' mobile'
            }`}
        >
            <Box
                component="form"
                noValidate
                autoComplete="off"
                {...props.formProps}
            >
                <h2>Credit / Debit Card</h2>

                <Box sx={{ mt: '25px' }}>
                    <label>Deposit Amount in US Dollars</label>
                    <TextField
                        fullWidth
                        size="small"
                        hiddenLabel
                        placeholder="0"
                        error={props?.errors?.amount ?? ''}
                        name="amount"
                        value={props.payload.amount ?? ''}
                        onChange={handleAmountChange}
                        onKeyDown={enforceInt}
                    />
                </Box>

                {props.isDesktop ? (
                    <div className="button-container">
                        <CustomButton
                            isLoading={transaction.loading}
                            type="submit"
                            label={`Deposit $${amount.toFixed(2)}`}
                            width="unset"
                            className="upviews-button"
                        />

                        <p className="policy">
                            By clicking Deposit you agree to our{' '}
                            <CustomTermsOfUseLinkComponent />, Privacy Policy
                            and Refund Policy.
                        </p>
                    </div>
                ) : (
                    <Box
                        sx={{
                            bgcolor: '#fff',
                            height: '200px',
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            borderTopLeftRadius: '40px',
                            borderTopRightRadius: '40px',
                            boxShadow: '0px 0px 5px 0px  rgb(0 0 0 / 10%)',
                            textAlign: 'center',
                            zIndex: '999',
                            padding: '10px 20px',
                            '& p': {
                                fontWeight: 500,
                                fontSize: '14px',
                            },
                        }}
                    >
                        <p>
                            By clicking Deposit you agree to our{' '}
                            <CustomTermsOfUseLinkComponent />, Privacy Policy
                            and Refund Policy.
                        </p>
                        <CustomButton
                            isLoading={transaction.loading}
                            type="submit"
                            label={`Deposit $${amount.toFixed(2)}`}
                            className="upviews-button"
                            sx={{ width: '100%' }}
                        />
                    </Box>
                )}
            </Box>
        </div>
    )
}
