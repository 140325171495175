import { Box, Grid, Modal } from '@mui/material';
import OutOfServiceImg from '../assets/images/out-of-service.png'
import { IsSmallestScreen } from '../helpers/utils.helper';

type Props = {
  open: boolean;
  onClose: any;
};

export default function OutOfServiceModal(props: Props) {
  const isSmallestScreen = IsSmallestScreen();
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallestScreen ? 'auto': '40%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
    fontFamily: "Nunito, sans-serif",
    p: 4,
    '& a': {
      bgcolor: '#27A857',
      borderRadius: '40px',
      fontWeight: 500,
      fontSize: '18px',
      boxShadow: 'none',
      padding: '10px 20px',
      color: '#fff',
      textTransform: 'capitalize',
      textDecoration: 'none',
      marginTop: '10px',
      fontFamily: "Nunito, sans-serif",
    },
    '& .service-image-container': {
      borderRadius: '12px 12px 0 0',
      fontFamily: "Nunito, sans-serif",
      background: 'linear-gradient(165.77deg, #fc4404 1.63%, #e54870 95.75%)',
      textAlign: 'center',
      'img': {
        marginTop: '-55px'
      }
    },
    '& .confirm-text-container': {
      textAlign: 'center',
    }
  };

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box sx={style} className="confirm-modal-container">
        <Grid container>
          
          <Grid item md={12} className="service-image-container">
            <img src={OutOfServiceImg} alt="Out of Service" />
          </Grid>
          <Grid item md={12} className="confirm-text-container">
            <h2>Sorry! Out of Service</h2>
            <p>We are sorry to inform you that the location you’ve selected is currently out of our service.</p>
            <br />
            <a href="https://upviews.com" rel="noreferrer">Go back to Homepage</a>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
