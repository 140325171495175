import { Box } from '@mui/system'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import ArticleIcon from '@mui/icons-material/Article'
import CloseIcon from '@mui/icons-material/Close'
import React, { ChangeEvent, useRef, useState } from 'react'
import {
    FormControl,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    styled,
} from '@mui/material'
import CustomButton from '../../customComponents/custom-button.component'

const Input = styled('input')({
    display: 'none',
})

export default function OrderActionsForm(props: any) {
    const [progress, setProgress] = useState(0)
    const [filesPreview, setFilesPreview] = useState<Array<string>>([])
    let inputRef: any = useRef()
    const defaultAction = 0

    const handlePdfUpload = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        if (!e.target) {
            return
        }
        let files = e.target.files ?? []
        let percentage = 0

        const timer = setInterval(() => {
            const diff = Math.random() * 20
            percentage = Math.min(percentage + diff, 100)
            setProgress(Math.min(percentage))
            if (percentage === 100) {
                clearInterval(timer)
            }
        }, 300)

        if (files.length === 0) {
            return
        }

        props.onChange({ issues: files, orderId: props.row.id })(e)

        for (var i = 0; i < files.length; i++) {
            let file: any = files[i]
            setFilesPreview([file.name])
        }
    }

    const handleActionChange = (event: SelectChangeEvent) => {
        if (!event.target) return

        props.onChange('action', event.target.value)(event)
    }

    const handleStatusChange = (event: SelectChangeEvent) => {
        if (!event.target) return
        props.onChange('status', event.target.value)(event)
    }

    const handleRemoveIssue = (event: any) => {
        props.onChange('issues', [])(event)
        setFilesPreview([])
        inputRef.current.value = null
    }
    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            {...props.formProps}
        >
            <Box className="select-container">
                <p className="title">Action</p>
                <FormControl fullWidth hiddenLabel size="small">
                    <Select
                        defaultValue={defaultAction}
                        name="action"
                        size="small"
                        onChange={(e: any) => handleActionChange(e)}
                    >
                        <MenuItem value={0} disabled>
                            Select
                        </MenuItem>

                        {props.options.actionTypes &&
                            props.options.actionTypes.map((actionType: any) => (
                                <MenuItem
                                    key={`action_type_` + actionType.id}
                                    value={actionType.id}
                                >
                                    {actionType.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Box>
            <Box className="select-container">
                <p className="title">Status</p>
                <FormControl fullWidth hiddenLabel size="small">
                    <Select
                        defaultValue={props.row.status}
                        name="status"
                        size="small"
                        onChange={handleStatusChange}
                    >
                        <MenuItem value={0} disabled>
                            Select
                        </MenuItem>

                        {props.options.statuses &&
                            props.options.statuses.map((status: any) => (
                                <MenuItem
                                    key={`action_type_` + status.id}
                                    value={status.id}
                                >
                                    {status.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Box>

            <Box>
                <p className="title">Submit A Report</p>
                <div className="upload-container">
                    <label
                        className="upload"
                        htmlFor={`contained-button-file-${props.row.id}`}
                    >
                        <Input
                            ref={inputRef}
                            accept="application/pdf"
                            id={`contained-button-file-${props.row.id}`}
                            type="file"
                            onChange={(e) => handlePdfUpload(e)}
                        />
                        <UploadFileIcon />
                        <p> Drop a file here to upload</p>
                    </label>
                </div>

                <div className="upload-loading">
                    {filesPreview.length > 0 &&
                        filesPreview.map((preview: string, i: number) => (
                            <Box
                                className="upload-list"
                                key={`li_1_${i}`}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <ArticleIcon sx={{ mr: '20px' }} />
                                <Box className="loading" width="100%">
                                    <Box
                                        className="attachment"
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        {preview}
                                        <div
                                            className="close"
                                            onClick={handleRemoveIssue}
                                        >
                                            <CloseIcon />
                                        </div>
                                    </Box>
                                    {progress < 100 && (
                                        <LinearProgress
                                            color="success"
                                            variant="determinate"
                                            value={progress}
                                        />
                                    )}
                                </Box>
                            </Box>
                        ))}
                </div>
            </Box>
            <CustomButton
                type="submit"
                label="Save Changes"
                className="upviews-button"
                sx={{ width: '100%' }}
                isLoading={props.isLoading}
            />
        </Box>
    )
}
