import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import store, { RootState } from '../store'
import Logo from '../assets/images/logo.svg'
import '../assets/css/login.css'
import { Box, Grid } from '@mui/material'
import Notification from '../components/notification.components'
import { RECOVER_RESET } from '../constants/user.constants'
import { IsDesktopScreen, IsSmallestScreen } from '../helpers/utils.helper'
import RecoveryForm from '../components/recovery-form.component'
import { recovery } from '../actions/auth.action'
import { RecoveryState } from '../reducers/recovery.reducer'
import { TYPES_CONST } from '../env'

const RecoveryScreen = () => {
    const auth = useSelector<RootState, RecoveryState>(
        (state) => state.recovery
    )
    const isDesktop = IsDesktopScreen()
    const isSmallestScreen = IsSmallestScreen()

    const [credential, setCredential] = useState({
        email: '',
    })

    useEffect(() => {
        if (auth.recoveryRedirection) {
            window.location.href = '/account-recovery-confirm?email='+auth.email
        }
    }, [auth]) // eslint-disable-line

    const [errors, setErrors] = useState({
        email: false,
    })

    const validate = () => {
        let payload = [
            {
                key: 'email',
                isValid: credential.email !== '',
            },
        ]

        return {
            data: payload,
            isValid: payload.every((data) => data.isValid),
        }
    }

    const onSubmit = async (event: SyntheticEvent) => {
        event.preventDefault()

        const validation = validate()

        if (!validation.isValid) {
            setErrors(
                validation.data.reduce(
                    (prevField, field) => {
                        return { ...prevField, [field.key]: !field.isValid }
                    },
                    { email: false, password: false }
                )
            )
            return
        }

        store.dispatch(recovery(credential.email))
    }

    const onChange =
        (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target
            setCredential((prevState) => ({
                ...prevState,
                [name]: value,
            }))
        }

    return (
        <Grid container className="login-body" sx={{ overflow: 'auto' }}>
            <Grid item md={7} sx={{ width: '100%' }}>
                <Grid container className="login-nav">
                    <Grid item md={6} xs={12} sm={12}>
                        <a href={TYPES_CONST.MARKETING_URL}>
                            <img src={Logo} alt="Upviews" />
                        </a>
                    </Grid>
                    <Grid item md={6} className="log-action">
                        <p>
                            Don't have an account?{' '}
                            <a href="/register">Sign Up!</a>
                        </p>
                    </Grid>

                    <Grid
                        item
                        md={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                width: isDesktop ? '400px' : '100%',
                                margin: isSmallestScreen ? '5px' : 'unset',
                            }}
                            className="form-container"
                        >
                            <Box
                                mb={
                                    isSmallestScreen ? '60px!important' : '37px'
                                }
                                className="login-header"
                            >
                                <h4>Forgot Password?</h4>
                                <div className="sub-title">
                                    Don’t worry! It happens. Please enter your
                                    email address associated with your account.
                                </div>
                            </Box>
                            <RecoveryForm
                                className="login-form"
                                formProps={{ onSubmit: onSubmit }}
                                onChange={onChange}
                                errors={errors}
                                loading={auth.loading}
                            />
                        </Box>

                        <Box
                            sx={{ position: 'fixed', bottom: '33px' }}
                            className="login-action-bottom"
                        >
                            <p>
                                Don't have an account?{' '}
                                <a href="/register">Sign Up!</a>
                            </p>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={5} className="recover-password-person">
                {/* Background here */}
            </Grid>
            <Notification
                message={auth.message}
                open={auth.showNotification }
                severity={auth.hasError ? 'error' : 'success'}
                onClose={() =>
                    store.dispatch({
                        type: RECOVER_RESET,
                    })
                }
            />
        </Grid>
    )
}

export default RecoveryScreen
