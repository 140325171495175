import { combineReducers, Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { vipReducer } from './reducers/vip.reducer'
import { userLoginReducer } from './reducers/user.reducer'
import { configureStore } from '@reduxjs/toolkit'
import { registerReducer } from './reducers/register.reducer'
import { screenReducer } from './reducers/screen.reducer'
import { ordersReducer } from './reducers/orders.reducer'
import { statisticsReducer } from './reducers/account.reducer'
import { transactionReducer } from './reducers/transaction.reducer'
import { walletReducer } from './reducers/wallet.reducer'
import { customersReducer } from './reducers/customers.reducer'
import { apiProvidersReducer } from './reducers/api-providers.reducer'
import { supportReducer } from './reducers/support.reducer'
import { filtersReducer } from './reducers/filters.reducer'
import { recoveryReducer } from './reducers/recovery.reducer'
import { resetReducer } from './reducers/reset.reducer'
import { passwordReducer } from './reducers/password.reducer'
import { profileReducer } from './reducers/profile.reducer'
import { blogReducer } from './reducers/blog.reducer'
import { instagramReducer } from './reducers/instagram.reducer'
import { recordsReducer } from './reducers/records.reducer'
import { ipInfoReducer } from './reducers/ipinfo.reducer'

const reducers = combineReducers({
    vip: vipReducer,
    userLogin: userLoginReducer,
    register: registerReducer,
    screen: screenReducer,
    order: ordersReducer,
    statistics: statisticsReducer,
    transaction: transactionReducer,
    wallet: walletReducer,
    customer: customersReducer,
    apiProviders: apiProvidersReducer,
    support: supportReducer,
    filters: filtersReducer,
    recovery: recoveryReducer,
    reset: resetReducer,
    password: passwordReducer,
    profile: profileReducer,
    blog: blogReducer,
    instagram: instagramReducer,
    loginRecord: recordsReducer,
    ipInfo: ipInfoReducer
})

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production',
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk = ThunkAction<void, RootState, unknown, Action>

export default store
