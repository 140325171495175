import axios from 'axios'
import {
    STATISTICS_FAIL,
    STATISTICS_REQUEST,
    STATISTICS_SUCCESS,
    WALLET_FAIL,
    WALLET_REQUEST,
    WALLET_SUCCESS,
} from '../constants/account.constants'
import {
    NEW_PROFILE_FAIL,
    NEW_PROFILE_REQUEST,
    NEW_PROFILE_SUCCESS,
    PASSWORD_FAIL,
    PASSWORD_REQUEST,
    PASSWORD_SUCCESS,
    PROFILE_UPDATE_FAIL,
    PROFILE_UPDATE_REQUEST,
    PROFILE_UPDATE_SUCCESS,
} from '../constants/user.constants'
import {
    StatisticsService,
    UserService,
    WalletService,
} from '../services/account.service'
import { AppThunk } from '../store'

export const StatisticsAction = {
    get: (): AppThunk => async (dispatch) => {
        dispatch({
            type: STATISTICS_REQUEST,
        })

        await StatisticsService.index()
            .then((response: any) => {
                let responseData = response?.data
                if (responseData.data) {
                    return dispatch({
                        type: STATISTICS_SUCCESS,
                        payload: responseData,
                    })
                }
                return response?.data
            })
            .catch(function (error) {
                dispatch({
                    type: STATISTICS_FAIL,
                    payload: error?.response?.data,
                })
            })
    },
}

export const WalletAction = {
    get: (): AppThunk => async (dispatch) => {
        dispatch({
            type: WALLET_REQUEST,
        })

        await WalletService.get()
            .then((response: any) => {
                let responseData = response?.data
                if (responseData.data) {
                    return dispatch({
                        type: WALLET_SUCCESS,
                        payload: responseData.data,
                    })
                }
                return response?.data
            })
            .catch(function (error) {
                dispatch({
                    type: WALLET_FAIL,
                    payload: error?.response?.data,
                })
            })
    },
}

export const ProfileAction = {
    new:
        (data: any): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: NEW_PROFILE_REQUEST,
            })

            let payload = UserService.filterPayload(data.is_customer, data)

            await UserService.new(payload)
                .then((response: any) => {
                    let responseData = response?.data

                    if (responseData.data) {
                        return dispatch({
                            type: NEW_PROFILE_SUCCESS,
                            payload: responseData.data,
                        })
                    }
                    return response?.data
                })
                .catch(function (error) {
                    dispatch({
                        type: NEW_PROFILE_FAIL,
                        payload: error?.response?.data,
                    })
                })
        },
    changePassword:
        (userId: any, payload: any): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: PASSWORD_REQUEST,
            })

            await UserService.password(userId, payload)
                .then((response: any) => {
                    let responseData = response?.data
                    if (responseData.data) {
                        return dispatch({
                            type: PASSWORD_SUCCESS,
                            payload: responseData.data,
                        })
                    }
                    return response?.data
                })
                .catch(function (error) {
                    dispatch({
                        type: PASSWORD_FAIL,
                        payload: error?.response?.data,
                    })
                })
        },
    update:
        (userId: any, data: any): AppThunk =>
        async (dispatch) => {
            dispatch({
                type: PROFILE_UPDATE_REQUEST,
            })

            let payload = UserService.filterUpdateUserPayload(data)

            await UserService.batchUpdate(userId, payload)
                .then(
                    axios.spread((...responses) => {
                        const updateResponse =
                            responses.length === 1 ? responses[0] : responses[1]

                        let responseData = updateResponse?.data

                        if (responseData.data) {
                            dispatch({
                                type: PROFILE_UPDATE_SUCCESS,
                                payload: responseData.data,
                            })
                        }

                        return updateResponse
                    })
                )
                .catch(function (error) {
                    dispatch({
                        type: PROFILE_UPDATE_FAIL,
                        payload: error?.response?.data,
                    })
                })
        },
    uploadPhoto:
        (userId: any, photo: any): AppThunk =>
        async (dispatch) => {
            await UserService.uploadPhoto(userId, photo).then(
                (response: any) => {
                    return response
                }
            )
        },
}
