import {
    PASSWORD_FAIL,
    PASSWORD_REQUEST,
    PASSWORD_RESET,
    PASSWORD_SUCCESS,
} from '../constants/user.constants'

export interface PasswordState {
    loading?: boolean
    message?: string
    showNotification: boolean
    hasError: boolean
}

interface Action {
    type: string
    payload?: any
}

export const passwordReducer = (
    state: PasswordState = {
        loading: false,
        showNotification: false,
        hasError: false,
    },
    action: Action
) => {
    switch (action.type) {
        case PASSWORD_REQUEST:
            return { loading: true }
        case PASSWORD_SUCCESS:
            return {
                loading: false,
                showNotification: true,
                hasError: false,
                message: 'Password successfully changed.',
            }
        case PASSWORD_FAIL:
            return {
                loading: false,
                showNotification: true,
                hasError: true,
                message:
                    action.payload?.message ??
                    'Error occurred when changing password.',
            }
        case PASSWORD_RESET:
            return {
                loading: false,
                showNotification: false,
                hasError: false,
                message: '',
            }
        default:
            return state
    }
}
