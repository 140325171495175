/**
 * Login Event Names
 */
export const INFO_VIP_REQUEST = 'INFO_VIP_REQUEST'
export const INFO_VIP_SUCCESS = 'INFO_VIP_SUCCESS'
export const INFO_VIP_FAIL = 'INFO_VIP_FAIL'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const RESET = 'LOGIN_RESET'
export const VIP_RESET = 'VIP_RESET'

export const PP_REQUEST = 'PP_REQUEST'
export const PP_SUCCESS = 'PP_SUCCESS'
export const PP_FAIL = 'PP_FAIL'
/**
 * Register Event Names
 */
export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const REGISTER_RESET = 'REGISTER_RESET'
/**
 * User Event Names
 */
export const INFO_REQUEST = 'INFO_REQUEST'
export const INFO_SUCCESS = 'INFO_SUCCESS'
export const INFO_FAIL = 'INFO_FAIL'

export const PASSWORD_REQUEST = 'PASSWORD_REQUEST'
export const PASSWORD_SUCCESS = 'PASSWORD_SUCCESS'
export const PASSWORD_FAIL = 'PASSWORD_FAIL'
export const PASSWORD_RESET = 'PASSWORD_RESET'

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST'
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS'
export const PROFILE_UPDATE_FAIL = 'PROFILE_UPDATE_FAIL'
export const PROFILE_UPDATE_RESET = 'PROFILE_UPDATE_RESET'

export const NEW_PROFILE_REQUEST = 'NEW_PROFILE_REQUEST'
export const NEW_PROFILE_FAIL = 'NEW_PROFILE_FAIL'
export const NEW_PROFILE_SUCCESS = 'NEW_PROFILE_SUCCESS'

/**
 * Recover Event Names
 */
export const RECOVER_REQUEST = 'RECOVER_REQUEST'
export const RECOVER_SUCCESS = 'RECOVER_SUCCESS'
export const RECOVER_FAIL = 'RECOVER_FAIL'
export const RECOVER_RESET = 'RECOVER_RESET'
/**
 * Reset Event Names
 */
export const RESET_REQUEST = 'RESET_REQUEST'
export const RESET_SUCCESS = 'RESET_SUCCESS'
export const RESET_FAIL = 'RESET_FAIL'
export const RESET_RESET = 'RESET_RESET'
