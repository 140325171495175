import {
    CUSTOMER_ACTIVATE_ACCOUNT_FAIL,
    CUSTOMER_ACTIVATE_ACCOUNT_SUCCESS,
    CUSTOMER_BAN_ACCOUNT_FAIL,
    CUSTOMER_BAN_ACCOUNT_SUCCESS,
    CUSTOMER_BAN_IP_FAIL,
    CUSTOMER_BAN_IP_SUCCESS,
    CUSTOMER_GET_FAIL,
    CUSTOMER_GET_SUCCESS,
    CUSTOMER_PAUSE_ACCOUNT_FAIL,
    CUSTOMER_PAUSE_ACCOUNT_SUCCESS,
    CUSTOMER_REQUEST,
    CUSTOMER_RESET,
} from '../constants/customer.constants'

type DataCustomers = {
    message?: string | null
    list: {
        data: Array<object>
        meta: object | null
    }
}

export interface CustomerState {
    loading?: boolean
    showNotification: boolean
    hasError?: boolean
    data: DataCustomers
    customer: any
    isUploading?: boolean
}

interface Action {
    type: string
    payload?: any
}

const updateUser = (data: Array<any>, payload: any) => {
    return data.map((customer: any) =>
        customer.id === payload?.data?.id ?? 0
            ? {
                  ...customer,
                  status: payload?.data?.status ?? '',
              }
            : customer
    )
}

export const customersReducer = (
    state: CustomerState = {
        loading: false,
        isUploading: false,
        showNotification: false,
        hasError: false,
        customer: null,
        data: {
            message: null,
            list: {
                data: [],
                meta: null,
            },
        },
    },
    action: Action
) => {
    switch (action.type) {
        case CUSTOMER_REQUEST:
            return {
                loading: true,
                isUploading: false,
                showNotification: false,
                hasError: false,
                customer: state.customer,
                data: {
                    message: null,
                    list: state.data.list,
                },
            }
        case CUSTOMER_GET_SUCCESS:
            return {
                loading: false,
                isUploading: false,
                showNotification: false,
                hasError: false,
                customer: action.payload,
                data: { list: action.payload },
            }
        case CUSTOMER_GET_FAIL:
            return {
                loading: false,
                isUploading: false,
                showNotification: true,
                hasError: true,
                customer: state.customer,
                data: { ...state.data, message: action.payload?.message },
            }
        case CUSTOMER_BAN_IP_SUCCESS:
            return {
                loading: false,
                isUploading: false,
                showNotification: true,
                hasError: false,
                customer: state.customer,
                data: {
                    ...state.data,
                    list: {
                        ...state.data.list,
                        data: updateUser(
                            state?.data?.list?.data ?? [],
                            action.payload
                        ),
                    },
                    message: 'IP Address successfully Banned.',
                },
            }
        case CUSTOMER_BAN_IP_FAIL:
            return {
                loading: false,
                isUploading: false,
                showNotification: true,
                hasError: true,
                customer: state.customer,
                data: { ...state.data, message: action.payload?.message },
            }
        case CUSTOMER_RESET:
            return {
                isUploading: false,
                loading: false,
                data: state.data,
                showNotification: false,
                customer: state.customer,
                hasError: false,
            }
        case CUSTOMER_BAN_ACCOUNT_SUCCESS:
            return {
                loading: false,
                isUploading: false,
                showNotification: true,
                hasError: false,
                customer: state.customer,
                data: {
                    ...state.data,
                    list: {
                        ...state.data.list,
                        data: updateUser(
                            state?.data?.list?.data ?? [],
                            action.payload
                        ),
                    },
                    message: 'Account Successfully Banned.',
                },
            }
        case CUSTOMER_BAN_ACCOUNT_FAIL:
            return {
                loading: false,
                isUploading: false,
                showNotification: true,
                hasError: true,
                customer: state.customer,
                data: { ...state.data, message: action.payload?.message },
            }
        case CUSTOMER_PAUSE_ACCOUNT_SUCCESS:
            return {
                loading: false,
                isUploading: false,
                showNotification: true,
                hasError: false,
                customer: state.customer,
                data: {
                    ...state.data,
                    list: {
                        ...state.data.list,
                        data: updateUser(
                            state?.data?.list?.data ?? [],
                            action.payload
                        ),
                    },
                    message: 'Account Successfully Paused.',
                },
            }
        case CUSTOMER_PAUSE_ACCOUNT_FAIL:
            return {
                loading: false,
                isUploading: false,
                showNotification: true,
                hasError: true,
                customer: state.customer,
                data: { ...state.data, message: action.payload?.message },
            }
        case CUSTOMER_ACTIVATE_ACCOUNT_SUCCESS:
            return {
                loading: false,
                isUploading: false,
                showNotification: true,
                hasError: false,
                customer: state.customer,
                data: {
                    ...state.data,
                    list: {
                        ...state.data.list,
                        data: updateUser(
                            state?.data?.list?.data ?? [],
                            action.payload
                        ),
                    },
                    message: 'Account is now Active.',
                },
            }
        case CUSTOMER_ACTIVATE_ACCOUNT_FAIL:
            return {
                loading: false,
                isUploading: false,
                showNotification: true,
                hasError: true,
                customer: state.customer,
                data: { ...state.data, message: action.payload?.message },
            }
        default:
            return state
    }
}
