import { Button, Grid, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system'
import { IsSmallestScreen } from '../../../helpers/utils.helper'
import { getBankName } from '../../../services/deposit.service';
import { MANUAL_REVIEW, PAYMENT_STATUS } from '../../../types/transaction_options.types';

export default function DepositsPopup(props: any) {
    const isSmallestScreen = IsSmallestScreen()
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isSmallestScreen ? '80%' : '25%',
        bgcolor: '#fff',
        boxShadow: 24,
        borderRadius: '12px',
        fontFamily: 'Nunito, sans-serif',
        padding: '30px',
        '& button': {
            textAlign: 'center',
            bgcolor: '#27A857',
            borderRadius: '40px',
            fontWeight: 500,
            fontSize: '16px',
            boxShadow: 'none',
            padding: '10px 15px',
            color: '#fff',
            textTransform: 'capitalize',
            textDecoration: 'none',
            marginTop: '10px',
            fontFamily: 'Nunito, sans-serif',
            '&:hover': {
              bgcolor: '#27A857',
            }
        },
        '& .confirm-text-container': {
            textAlign: 'center',
        },
    }

    const formatStatus = (status: string) => {
      const { label, color } = PAYMENT_STATUS[status] || {}
      return (
          <Box
              sx={{
                  fontSize: '14px',
                  color,
              }}
          >
            {label}
          </Box>
      )
    }

    const manualApproval = (id: number) => {
      props.approvePayment(id)
      props.onClose()
    }
   
    return (
        <Modal open={props.open} onClose={props.onClose}>
            <Box sx={style} className="confirm-modal-container">
                <Typography
                    sx={{
                        textAlign: 'left',
                        fontSize: '14px',
                        fontWeight: '700',
                        padding: '30px',
                        '& svg': { 
                          position: 'absolute',
                          top: '-10px',
                          right: '-10px',
                          color: '#fff',
                          fontSize: '30px',
                          backgroundColor: '#F8451B',
                          borderRadius: '25px',
                          cursor: 'pointer',
                        },
                    }}
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                >
                    User IP Address Details
                    <CloseIcon onClick={props.onClose}/>
                </Typography>
                <Grid container  sx={{
                  padding: '0 30px',
                  paddingBottom: '30px',
                  fontSize: '14px',
                  '& .value': {
                    textAlign: 'right',
                  },
                  '& .title': {
                    textAlign: 'left',
                    color: '#BFBFBF'
                  }
                }}>
                  <Grid container>
                      <Grid item xs={6} className="title">User IP address used</Grid>
                      <Grid item xs={6} className="value"> {props?.ipInfo?.ip ?? ''}</Grid>
                  </Grid>
                  
                  <Grid container>
                    <Grid item xs={6} className="title">Country Detected</Grid>
                    <Grid item xs={6} className="value"> {props?.ipInfo?.country ?? ''}</Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={6} className="title">Bank Used</Grid>
                    <Grid item xs={6} className="value"> {getBankName(props?.selectedTransaction)} </Grid>
                  </Grid>
                  
                  <Grid container>
                      <Grid item xs={6} className="title">Transaction Status</Grid>
                      <Grid item xs={6} className="value"> {formatStatus(props?.selectedTransaction?.status)} </Grid>
                  </Grid>
                  
                </Grid>

                {props?.selectedTransaction?.status === MANUAL_REVIEW &&
                  <Grid container>
                      <Grid item xs={12} className="confirm-text-container">
                          <Button onClick={() => {manualApproval(props?.selectedTransaction?.id)}}>Manual Approval</Button>
                      </Grid>
                  </Grid>
                }
            </Box>
        </Modal>
    )
}
