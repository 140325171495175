import { Box } from '@mui/system';
import * as React from 'react';
import Statistics from '../statistics.component';
import DashboardOrders from './dashboard-orders.component';

export default function CustomerDashboard(props: any) {
  return (
    <React.Fragment>
      <Box pl={{ xs: '8px', md: 'unset' }}>
        <Statistics statistics={props.statistics} isLoading={props.isLoading} />
      </Box>
      <div className="spacer" />
      <DashboardOrders
        isDesktop={props.isDesktop}
        list={props.list}
        meta={props.meta}
        isLoading={props.isLoading}
        onPageChange={props.onPageChange}
        onSearch={props.setSearchTerm}
        onSort={props.onSort}
      />
    </React.Fragment>
  );
}
