import { UserState } from '../reducers/user.reducer'
import { useSelector } from 'react-redux'
import store, { RootState } from '../store'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Divider,
    Grid,
    Link,
    Typography,
} from '@mui/material'
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import CustomLabeledFormControl from '../components/customComponents/custom-labeled-form-control'
import ConversationImage from '../assets/images/start-conversation.png'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import SendIcon from '@mui/icons-material/Send'
import SupportForm from '../components/dashboard/customer/support-form.component'
import Notification from '../components/notification.components'
import { SupportState } from '../reducers/support.reducer'
import { SupportAction } from '../actions/support.action'
import { SUPPORT_RESET } from '../constants/support.constants'
import { TYPES_CONST } from '../env'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import _ from 'lodash'
import { BlogAction } from '../actions/blog.action'
import { BlogState } from '../reducers/blog.reducer'
import CustomButton from '../components/customComponents/custom-button.component'
import { IsSmallestScreen } from '../helpers/utils.helper'
import { getCurrentUser } from '../services/auth.service'

const validateForm = (payload: any) => {
    let dataFields = [
        {
            key: 'subject',
            isValid: payload.subject !== '',
        },
        {
            key: 'message',
            isValid: payload.message !== '',
        },
        {
            key: 'recaptcha',
            isValid: payload.recaptcha !== '',
        },
    ]

    return {
        data: dataFields,
        isValid: dataFields.every((data) => data.isValid),
    }
}

const SupportScreen = (props: any) => {
    const { getScreenTitle, isDesktop } = props
    const user = useSelector<RootState, UserState>((state) => state.userLogin)
    const support = useSelector<RootState, SupportState>(
        (state) => state.support
    )
    const isSmallestScreen = IsSmallestScreen()

    const isLoading = support.loading

    const blog = useSelector<RootState, BlogState>((state) => state.blog)

    const cacheUser = getCurrentUser()
    const isVIP = cacheUser?.is_vip ?? false;

    const [payload, setPayload] = useState({
        url: '',
        subject: '',
        message: '',
        recaptcha: '',
    })

    const defaultErrors = {
        subject: false,
        message: false,
        recaptcha: false,
    }

    const [errors, setErrors] = useState(defaultErrors)

    const [keyword, setKeyword] = useState('')
    const [faqKeyword, setFaqKeyword] = useState('')
    const [isFaqSearch, setFaqSearch] = useState(false)
    const [faqSearchResult, setfaqSearchResult] = useState<any>()

    useEffect(() => {
        getScreenTitle('Support - We’re here to Help')
        const script = document.createElement('script')
        script.src = '//code.tidio.co/4solwclar67zru8jdqcvw3zonwbegzho.js'
        script.async = true
        document.body.appendChild(script)
    }, []) // eslint-disable-line

    useEffect(() => {
        store.dispatch(BlogAction.get())
    }, [])

    const onSubmit = async (event: SyntheticEvent) => {
        event.preventDefault()
        const validation = validateForm(payload)

        if (!validation.isValid) {
            setErrors(
                validation.data.reduce((prevField, field) => {
                    return { ...prevField, [field.key]: !field.isValid }
                }, defaultErrors)
            )
            return
        }
        store.dispatch(SupportAction.new(payload))
    }

    const keywordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value)
    }

    const onBlogSearch = (e: any) => {
        e.preventDefault()
        window.open(TYPES_CONST.BLOG_URL + '?s=' + keyword, '_blank')
    }

    const onChange =
        (name: string, customValue?: any) =>
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = customValue ?? event.target.value

            setPayload((prevState) => ({
                ...prevState,
                [name]: value,
            }))
        }

    const [expanded, setExpanded] = useState<string | false>(false)
    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false)
        }

    const faq = [
        {
            title: 'How much will it cost me?',
            details: [
                'UpViews delivers the most competitively priced packages available on the internet today with the value we provide. Our prices are unbeatable, ranging from $10 to $500.',
            ],
        },
        {
            title: 'Is it safe to use the UpViews platform?',
            details: [
                ` Yes, the platform is absolutely safe and legal to use.`,
                `Your account information is secure on the platform and is
                not shared with any other third party. Your marketing
                investment in UpViews products translates into real likes,
                comments, and views by real subscribers. Hence, it is legal
                to use and prevents any scope for being banned by YouTube's
                algorithms.`,
            ],
        },
        {
            title: 'How do I get started?',
            details: [
                `The first step is to sign up and create your exclusive
                account on the UpViews website. Make sure you accurately
                fill in all your details.`,
                `Next, add funds to your UpViews wallet via the list of our
                secure payments. This step ensures a frictionless experience
                on the platform.`,
                `Once your wallet is topped up, review our list of
                competitively-priced packages, which deliver likes,
                comments, views, and subscribers based on the specific
                requirement of your YouTube channel.`,
                `Choose the right package based on your business needs and
                budget, and initiate the payment from your wallet. Once this
                is done, UpViews will start delivering your service within
                24 hours.`,
            ],
        },
        {
            title: 'Do I need marketing experience?',
            details: [
                `The great news is that you need absolutely no marketing experience to optimize the impact of UpViews services. It is the role of the UpViews team to market your channel to our network of carefully curated, authentic subscribers.`,
                `Our intervention makes it possible for you to stay focused on the business of creating great content.`,
            ],
        },
        {
            title: 'Can my video be unlisted? ',
            details: [
                `Yes, your video may remain unlisted as long as it is viewable.`,
            ],
        },
    ]

    const faq2 = [
        {
            title: `What makes UpViews' service superior to others?`,
            details: [
                `The internet is flooded with platforms that promise to increase the likes, reviews, comments, and subscribers of your YouTube channel. However, what you end up with is engagement from bot-based accounts and fake subscribers.`,
                `While it seems like your channel is growing, the investment in the making has no long-term impact because your channel is not getting amplified to real users. On the other hand, UpViews has invested in curating a wide network of authentic YouTube subscribers with a genuine interest in consuming original, authentic, high-value content across multiple domains.`,
                `By partnering with UpViews, your YouTube channel will get discovered by new audiences with an interest in your content. We steer clear of generating fake traffic by bots and fake accounts, thus offering high value for your marketing investment.`,
                `Our strategic interventions free up your bandwidth to focus on creating great content, which is why we focus on marketing channels.`,
            ],
        },
        {
            title: 'Are the subscribers delivered by UpViews real people?',
            details: [
                `Yes, absolutely. YouTube has millions of real subscribers who are continuously looking for the kind of authentic, useful content delivered by your channel.`,
                `Our team collaborates with a spectrum of real subscribers who have been hand-picked by our team to be a part of UpViews' fast-growing subscriber base of engaged YouTube users.`,
                `You can rest assured that all the traffic generated by UpViews for your channel comprises real users.`,
            ],
        },
        {
            title: 'How is it possible to fake YouTube subscribers?',
            details: [
                `Today, a large number of platforms are involved in helping YouTube businesses generate fake views, likes, comments, and subscribers. Many unsuspecting, hard-working content creators are lured by such unscrupulous companies in the hopes of generating more traffic to their channels.`,
                `They end up spending a lot of money but receive little value for their investment. This is because while the number of views, likes, comments, and subscribers may increase, the quality and authenticity of engagement is poor and unsustainable.`,
                `UpViews believes that even if your content is excellent, you need help amplifying it to wider audiences. That is where our expertise and resource base comes in. We deliver authentic subscribers, making the engagement a win-win that delivers long-term impact.`,
            ],
        },
        {
            title: 'What type of payments do you accept?',
            details: [
                `We have multiple secure payment options to make the payment from your wallet. UpViews accepts payments via credit cards and debit cards. Apart from this, we also accept payments via crypto and PayPal.`,
            ],
        },
        {
            title: 'Do you offer refunds?',
            details: [
                `Yes, UpViews does have a money-back guarantee policy in place, and hence, we will initiate a refund provided there is a valid reason for requesting one. However, if you are unsatisfied with our services, we request you contact our support team and share your experiences.`,
                `Once our team reviews the issue, we will troubleshoot and devise a strategic solution to the problem. Our support team is available 24/7 to answer any questions you might have. Our commitment is to work with you as a partner to deliver on your business goals.`,
            ],
        },
    ]

    const onFaqSearch = (e: any) => {
        e.preventDefault()

        if (faqKeyword) {
            setFaqSearch(true)
            const faq1result = _.filter(faq, function (obj: any) {
                return (
                    obj.title.includes(faqKeyword) ||
                    obj.details.join().includes(faqKeyword)
                )
            })
            const faq2result = _.filter(faq2, function (obj: any) {
                return (
                    obj.title.includes(faqKeyword) ||
                    obj.details.join().includes(faqKeyword)
                )
            })

            let mergedFaq = _.concat(faq1result, faq2result)
            setfaqSearchResult(mergedFaq)
        }
    }
    const keywordFaqChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFaqSearch(false)
        setFaqKeyword(e.target.value)
    }

    const formatDetails = (details: Array<string>) => {
        return details.length === 1
            ? details[0]
            : details.map((txt: string) => (
                  <>
                      {txt} <br />
                      <br />
                  </>
              ))
    }

    return (
        <>
            <Container className="container">
                <Grid container spacing={2}>
                    <Grid item md={8} width="100%">
                        <Box
                            className="support-form"
                            sx={{
                                padding: isDesktop
                                    ? '35px 55px 50px 55px'
                                    : '5px 15px',
                                borderRadius: isDesktop ? '40px' : '20px',
                            }}
                        >
                            <SupportForm
                                payload={payload}
                                formProps={{ onSubmit: onSubmit }}
                                onChange={onChange}
                                errors={errors}
                                isDesktop={isDesktop}
                                isLoading={isLoading}
                                user={user}
                                support={support}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={4} width="100%">
                        <Box className="ads-container" sx={{ pb: '20px' }}>
                            <Box
                                className={isVIP ? 'greetings-vip greetings' : 'greetings'}
                                sx={{
                                    borderRadius: isDesktop
                                        ? '40px 40px 0 0'
                                        : '20px 20px 0 0',
                                }}
                            >
                                <h1>Hi {user.info?.name}</h1>
                                <p>
                                    Let us know if we can help you with anything
                                    at all.
                                </p>
                            </Box>
                            <div className="start-conversation">
                                <h4>Start a conversation</h4>
                                <div className="reply-time">
                                    <div className="conversation-container">
                                        <img src={ConversationImage} alt="" />
                                        <div>
                                            <p>Our usual reply time</p>
                                            <p>
                                                <b>
                                                    <AccessTimeIcon /> A few
                                                    minutes
                                                </b>
                                            </p>
                                        </div>
                                    </div>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className="send-message-button"
                                        onClick={() =>
                                            (window as any).tidioChatApi?.open()
                                        }
                                    >
                                        <SendIcon /> Send us a message
                                    </Button>
                                </div>
                                <Divider
                                    sx={{
                                        '.MuiDivider-root': {
                                            mt: isDesktop ? '45px' : '20px',
                                            mb: isDesktop ? '50px' : '20px',
                                        },
                                    }}
                                />
                                <p className="see-all">See all conversation</p>
                            </div>
                            <Box
                                className="search-container"
                                component="form"
                                onSubmit={onBlogSearch}
                            >
                                <CustomLabeledFormControl
                                    label="Find your answer now"
                                    size="small"
                                    name="keyword"
                                    placeholder="Search our articles"
                                    onChange={(e: any) => keywordChange(e)}
                                />
                            </Box>

                            <div className="banner-container">
                                {blog?.data?.list && (
                                    <Link
                                        target="_blank"
                                        href={blog.data?.list[0]?.link ?? ''}
                                    >
                                        <img
                                            src={
                                                blog.data?.list[0]?.thumbnail ??
                                                ''
                                            }
                                            alt=""
                                        />
                                    </Link>
                                )}
                            </div>
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        '& .MuiPaper-root': {
                            marginBottom: '20px',
                            borderRadius: '18px !important',
                            padding: '10px',
                            boxShadow: '0px 5px 16px rgba(8, 15, 52, 0.06)',
                            '&:before': {
                                background: 'none !important',
                            },
                            '& .MuiAccordionSummary-content p': {
                                fontWeight: '600',
                                fontSize: '18px',
                                color: '#170F49',
                            },
                            '& .MuiAccordionSummary-expandIconWrapper': {
                                backgroundColor: '#fff',
                                borderRadius: '30px',
                                padding: '7px',
                                boxShadow: '0px 5px 16px rgba(8, 15, 52, 0.06)',
                                svg: {
                                    color: '#FF3E3E',
                                    fontSize: '24px',
                                },
                            },
                            '& .MuiAccordionDetails-root p': {
                                fontWeight: '400',
                                fontSize: '14px',
                                color: '#6F6C90',
                            },
                            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded':
                                {
                                    transform: 'rotate(-90deg)',
                                    backgroundColor: '#FF3E3E',
                                    svg: {
                                        color: '#fff',
                                        fontSize: '24px',
                                    },
                                },
                        },
                    }}
                >
                    <Grid
                        item
                        md={12}
                        width="100%"
                        sx={{
                            '& h2': {
                                fontWeight: '700',
                                fontSize: '24px',
                                color: '#303030',
                                marginTop: '20px',
                            },
                            '& p': {
                                fontWeight: '400',
                                fontSize: '18px',
                                color: '#222222',
                            },
                        }}
                    >
                        <h2>Frequently Asked Questions</h2>
                        <Grid
                            item
                            md={6}
                            width="100%"
                            sx={{ '& .MuiBox-root': { marginBottom: '0' } }}
                        >
                            <Box
                                className="search-faq"
                                component="form"
                                onSubmit={onFaqSearch}
                                sx={{
                                    display: isSmallestScreen
                                        ? 'block'
                                        : 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    '& .MuiBox-root': {
                                        flexGrow: 1,
                                        marginRight: '10px',
                                    },
                                    '& button': {
                                        background: '#27A857',
                                        borderRadius: '40px',
                                        color: '#FFFFFF',
                                        fontWeight: '500',
                                        fontSize: '16px',
                                        border: 'none',
                                        padding: '13px 50px',
                                    },
                                }}
                            >
                                <CustomLabeledFormControl
                                    label=""
                                    size="small"
                                    name="keyword"
                                    placeholder="Enter your question here..."
                                    onChange={(e: any) => keywordFaqChange(e)}
                                />
                                <CustomButton
                                    label="Find Answer"
                                    type="submit"
                                    className="upviews-button"
                                    sx={{
                                        padding: '5px 20px!important',
                                        width: isSmallestScreen
                                            ? '98%'
                                            : 'unset',
                                        mt: isSmallestScreen ? '10px' : 'unset',
                                    }}
                                />
                            </Box>
                        </Grid>

                        <p>
                            Want to learn more about how our platform works?
                            Find out how easy it is to go viral on YouTube!
                        </p>
                    </Grid>

                    {!isFaqSearch && (
                        <>
                            <Grid item md={6} width="100%">
                                {faq.length > 0 &&
                                    faq.map((item: any, i: number) => (
                                        <Accordion
                                            key={`faq_a_${i}`}
                                            expanded={
                                                expanded === 'panel_a_' + i
                                            }
                                            onChange={handleChange(
                                                'panel_a_' + i
                                            )}
                                        >
                                            <AccordionSummary
                                                expandIcon={
                                                    <KeyboardArrowRightIcon />
                                                }
                                                aria-controls={
                                                    'panel_a_' +
                                                    i +
                                                    'bh-content'
                                                }
                                                id={
                                                    'panel_a_' + i + 'bh-header'
                                                }
                                                sx={{
                                                    '& .MuiAccordionSummary-content':
                                                        {
                                                            overflow:
                                                                'hidden!important',
                                                        },
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        flexShrink: 0,
                                                        width: '100%',
                                                    }}
                                                >
                                                    {item.title}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    {formatDetails(
                                                        item.details
                                                    )}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                            </Grid>
                            <Grid item md={6} width="100%">
                                {faq2.length > 0 &&
                                    faq2.map((item: any, i: number) => (
                                        <Accordion
                                            key={`faq_b_${i}`}
                                            expanded={
                                                expanded === 'panel_b_' + i
                                            }
                                            onChange={handleChange(
                                                'panel_b_' + i
                                            )}
                                        >
                                            <AccordionSummary
                                                expandIcon={
                                                    <KeyboardArrowRightIcon />
                                                }
                                                aria-controls={
                                                    'panel_b_' +
                                                    i +
                                                    'bh-content'
                                                }
                                                id={
                                                    'panel_b_' + i + 'bh-header'
                                                }
                                                sx={{
                                                    '& .MuiAccordionSummary-content':
                                                        {
                                                            overflow:
                                                                'hidden!important',
                                                        },
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        flexShrink: 0,
                                                        width: '100%',
                                                    }}
                                                >
                                                    {item.title}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    {formatDetails(
                                                        item.details
                                                    )}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                            </Grid>
                        </>
                    )}

                    {isFaqSearch && (
                        <>
                            <Grid item md={6} width="100%">
                                {faqSearchResult.length > 0 &&
                                    faqSearchResult.map(
                                        (item: any, i: number) => (
                                            <Accordion
                                                key={`faq_c_${i}`}
                                                expanded={
                                                    expanded === 'panel_c_' + i
                                                }
                                                onChange={handleChange(
                                                    'panel_c_' + i
                                                )}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        <KeyboardArrowRightIcon />
                                                    }
                                                    aria-controls={
                                                        'panel_c_' +
                                                        i +
                                                        'bh-content'
                                                    }
                                                    id={
                                                        'panel_c_' +
                                                        i +
                                                        'bh-header'
                                                    }
                                                >
                                                    <Typography
                                                        sx={{ flexShrink: 0 }}
                                                    >
                                                        {item.title}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        {formatDetails(
                                                            item.details
                                                        )}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    )}
                            </Grid>
                        </>
                    )}
                </Grid>
            </Container>
            <Notification
                message={support.data?.message ?? 'An error has occurred.'}
                open={support.showNotification}
                severity={support.hasError ? 'error' : 'success'}
                onClose={() =>
                    store.dispatch({
                        type: SUPPORT_RESET,
                    })
                }
            />
        </>
    )
}

export default SupportScreen
