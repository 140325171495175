import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import '@fontsource/inter'
import { TourProvider } from '@reactour/tour'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <Provider store={store}>
        <TourProvider
            steps={[]}
            disableInteraction
            disableKeyboardNavigation
            position={'right'}
            showBadge={false}
            showCloseButton={false}
            showDots={false}
            showPrevNextButtons={false}
            styles={{
                popover: (base: any) => ({
                    ...base,
                    '--reactour-accent': '#ef5a3d',
                    maxWidth: '100px!important',
                    borderRadius: 10,
                    padding: 'unset',
                    backgroundColor: '#EFFFF5',
                }),
                maskWrapper: (base: any) => ({
                    ...base,
                    color: 'transparent',
                }),
                highlightedArea: (base: any) => {
                    return {
                        ...base,
                        y: base.y + 3,
                        height: base.height - 6,
                        display: 'block',
                        fill: 'rgb(39 168 87 / 20%)',
                        outline: '2px dashed #27A857',
                        padding: 'unset',
                    }
                },
                badge: (base: any) => ({
                    ...base,
                    left: 'auto',
                    right: '-0.8125em',
                }),
                controls: (base: any) => ({ ...base, marginTop: '15px' }),
            }}
            padding={{
                popover: 20,
            }}
            onClickMask={() => {}}
            highlightedMaskClassName="whatever"
        >
            <App />
        </TourProvider>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
