import {
    RESET_FAIL,
    RESET_REQUEST,
    RESET_RESET,
    RESET_SUCCESS,
} from '../constants/user.constants'

export interface ResetState {
    loading?: boolean
    message?: string
    showNotification: boolean
    hasError: boolean
}

interface Action {
    type: string
    payload?: any
}

export const resetReducer = (
    state: ResetState = {
        showNotification: false,
        loading: false,
        hasError: false,
    },
    action: Action
) => {
    switch (action.type) {
        case RESET_REQUEST:
            return { loading: true, hasError: false }
        case RESET_SUCCESS:
            return {
                loading: false,
                hasError: false,
                message:
                    'Reset password successful!! Please login using your new password.',
                showNotification: true,
            }
        case RESET_FAIL:
            return {
                loading: false,
                message: action.payload?.message ?? 'Unable to reset password.',
                showNotification: true,
                hasError: true,
            }
        case RESET_RESET:
            return {
                loading: false,
                showNotification: false,
                message: '',
                hasError: false,
            }
        default:
            return state
    }
}
