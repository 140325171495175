export const getReference = (row: any) => {
    let data = row.data?.response ?? row.transaction?.response

    //credit
    if (data?.reference) {
        return data?.reference
    }

    //bank transfer
    if (data?.reference_id) {
        return data?.reference_id
    }

    //coinbase
    if (data?.code) {
        return data?.code
    }
    return ''
}

export const getBankName = (row: any) => {
    let data = row.data?.response ?? row.transaction?.response
    //credit
    if (data?.source?.issuer) {
        return data?.source?.issuer
    }

    //coinbase
    if (data?.payments?.length > 0) {
        return data?.payments[(data?.payments.length ?? 1) - 1]?.network
    }

    // bank transfer
    if (data?.bank_account_name) {
        return data?.bank_account_name
    }

    return ''
}

export const getLast4 = (row: any) => {
    let data = row.data?.response ?? row.transaction?.response

    //credit
    if (data?.source?.last_4) {
        return data?.source?.last_4
    }

    //coinbase
    if (data?.payments?.length > 0) {
        return data?.payments[
            (data?.payments.length ?? 1) - 1
        ]?.block?.hash.slice(-4)
    }

    // bank transfer
    if (data?.bank_account_id) {
        return data?.bank_account_id
    }

    return ''
}
