export default interface IUser {
    id?: any | null
    username?: string | null
    email?: string
    password?: string
    roles?: Array<string>
}

export const TYPE_SUPER_ADMIN = 'super admin'
export const TYPE_WORKER = 'worker'
export const TYPE_CUSTOMER = 'customer'

export const USER_TYPES = [
  {
      key: 'customer',
      name: 'Customer',
  },
  {
      key: 'super admin',
      name: 'Super Admin',
  },
  {
      key: 'worker',
      name: 'Worker',
  },
]

export const GENDERS = [
    {
        key: 1,
        name: 'Female',
    },
    {
        key: 2,
        name: 'Male',
    },
    {
        key: 3,
        name: 'Unspecified',
    },
]
