import moment from 'moment'

export const currentDate = moment()

export function collectDataSet(
    data: any,
    filterBy: string = 'Monthly'
): Array<number> {
    if (filterBy === 'Daily') {
        let count = 0;
        //get only last daily 7 records
        const startSlice = data.length < 7 ? 0 : data.length-7;
        data = data.slice(startSlice, data.length);

        let groupByWeek = data.reduce(function (carry: any, current: any) {
            carry[count] = parseFloat(current.total)
            count ++
            return carry
        }, Object.create(null))
        return Object.values(groupByWeek)
    }
    if (filterBy === 'Weekly') {
        let groupByWeek = data.reduce(function (carry: any, current: any) {
            let date = moment(current.date)
            let week = date.week()
            carry[week] = carry[week] || 0
            //only add current year's week
            carry[week] +=
                date.year() === currentDate.year() ? parseFloat(current.total) : 0
            return carry
        }, Object.create(null))
        return Object.values(groupByWeek)
    }
    if (filterBy === 'Yearly') {
        let groupByYear = data.reduce(function (carry: any, current: any) {
            let year = moment(current.date).year()
            carry[year] = carry[year] || 0
            carry[year] += parseFloat(current.total)
            return carry
        }, Object.create(null))
        return Object.values(groupByYear)
    }

    let groupByMonth = data.reduce(function (carry: any, current: any) {
        let date = moment(current.date)
        let month = date.month()
        carry[month] = carry[month] || 0
        //only add current years month
        carry[month] += date.year() === currentDate.year() ? parseFloat(current.total) : 0
        return carry
    }, Object.create(null))
    return Object.values(groupByMonth)
}

export function collectLabels(maxDataSet: any) {
    return maxDataSet.map((data: any) => '')
}

/**
 * Remove Empty fields from Payload
 * @param payload
 * @returns any
 */
export function cleanPayload(payload: any) {
    Object.entries(payload).forEach((data) => {
        if (!data[1]) {
            delete payload[data[0]]
        }
    })

    return payload
}
