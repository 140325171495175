import { Box, Card, CardContent, CardHeader, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { ScreenState } from '../../reducers/screen.reducer'
import { RootState } from '../../store'
import ViewCampaign from './view-campaign.component'
import TextSkeleton from '../skeletons/text.skeleton'
import Bundle from '../../assets/images/bundle.png'

const formatDate = (date: any) => {
    let formattedDate = new Date(date)

    let m = formattedDate.toLocaleString('default', { month: 'long' })
    let d = formattedDate.getDate()
    let y = formattedDate.getFullYear()

    return m + ' ' + d + ', ' + y
}

export default function CampaignCard(props: any) {
    const { options } = useSelector<RootState, ScreenState>(
        (state) => state.screen
    )
    const isLoading = props.isLoading
    const statuses = options?.statuses ?? []
    const status = isLoading ? 'pending' : props.record?.status || ''

    const handleShowCampaignDetails = () => {
        props.getRightNav({
            title: 'Campaign Details',
            content: (
                <ViewCampaign
                    key={props.record.id}
                    options={options}
                    record={props.record ?? []}
                />
            ),
        })
        props.showRightSideBar(true)
    }

    return (
        <Grid item md={3} sm={6} xs={12}>
            <Card
                sx={{
                    bgcolor: '#fff',
                    boxShadow: '0px 40px 60px rgba(128, 144, 155, 0.25)',
                    borderRadius: '16px',
                    padding: '7px',

                    '.top': {
                        borderRadius: '9px',
                        '&.active': { background: '#FF3E3E' },
                        '&.pending': { background: '#FF9548' },
                        '&.in_progress': { background: '#48BDFF' },
                        '&.on-hold': { background: '#A9A9A9' },
                        '&.cancelled': { background: '#FF4848' },
                        '&.completed': { background: '#27A857' },

                        '&.refunded': { background: '#FF9548' },
                        '.MuiCardHeader-content': {
                            span: {
                              '&.MuiCardHeader-title': {
                                fontWeight: 700,
                                fontSize: '14px',
                                color: '#fff',
                              },
                              '&.MuiCardHeader-subheader': {
                                  fontSize: '10px',
                                  color: '#fff',
                                  paddingTop: '5px',
                                  span: {
                                    opacity:' 0.7',
                                  }
                              },
                            },
                        },
                        '.MuiCardHeader-action': {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'end',
                            img: {
                              height: '20px',
                              width: '20px',

                            },
                            span: {
                                fontQeight: 500,
                                fontSize: '12px',
                                color: '#fff',
                                paddingTop: '5px',
                            },
                        },
                    },

                    '.bottom': {
                        '.MuiCardHeader-content': {
                            span: {
                                fontWeight: 700,
                                fontSize: '14px',
                                color: '#000',
                            },
                        },

                        '.MuiCardHeader-action': {
                            cursor: 'pointer',

                            span: {
                                fontWeight: 400,
                                fontSize: '14px',
                                textDecoration: 'underline',
                                color: '#27A857',
                            },
                        },
                        '.MuiCardHeader-subheader': {
                          fontSize: '10px',
                          span: {

                          }
                        }
                    },

                    '.MuiCardContent-root': {
                        p: {
                            margin: 0,
                            color: '#303030',
                            fontWeight: 500,
                            fontSize: '12px',

                            '&.label': {
                                fontWeight: 400,
                                fontSize: '12px',
                                color: '#C9C9C9',

                                span: {
                                    color: '#303030',
                                    fontWeight: 700,
                                },
                            },
                        },
                    },
                }}
            >
                {isLoading ? (
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        padding="0px 16px"
                    >
                        <TextSkeleton width="40%" />
                        <TextSkeleton width="20%" />
                    </Box>
                ) : (
                    <CardHeader
                        className={'top ' + status}
                        title={options?.services[props.record?.service] ?? ''}
                        action={
                          <>
                            {
                              props.record?.bundle_id !== null ?
                              <img src={Bundle} alt="bundle"/>
                              :
                              <p style={{margin: 0, height: '20px'}}></p>
                            }
                             
                            <span >{statuses[props.record.status]}</span>
                          </>
                        }
                        subheader={ 
                          <>
                            { props.record?.bundle_id === null ? 'Order' : 'Bundle' } ID:
                            <span>{ props.record.bundle_id === null ? ( props.record?.code ?? '') : (props.record?.bundle_id ?? '') } </span>
                          </>
                        }
                    />
                )}
                <CardContent>
                    {isLoading ? (
                        <TextSkeleton />
                    ) : (
                        <p className="label">
                            Date Started{' '}
                            <span>
                                {formatDate(props.record?.created_at ?? '')}
                            </span>
                        </p>
                    )}
                    {isLoading ? (
                        <TextSkeleton />
                    ) : (
                        <p className="label">
                            {options?.service_types[
                                props.record?.service_type
                            ] ?? ''}
                        </p>
                    )}
                </CardContent>
                {isLoading ? (
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        padding="0px 16px"
                    >
                        <TextSkeleton width="40%" />
                        <TextSkeleton width="30%" />
                    </Box>
                ) : (
                    <CardHeader
                        onClick={handleShowCampaignDetails}
                        className="bottom"
                        title={'$' + props.record?.amount}
                        action={
                            <span className="see-details">See Details</span>
                        }
                    />
                )}
            </Card>
        </Grid>
    )
}
